import React from "react";
import { Card, CardBody, CardImg, CardTitle, CardSubtitle } from "reactstrap";

const docFileTypes = [".pdf"];
const imgFileTypes = ["png", "jpeg"];
const videoFileTypes = [
  "x-flv",
  "mov",
  "mpeg",
  "mpegps",
  "mpg",
  "mp4",
  "webm",
  "wmv",
  "3gpp",
];
const audioFileTypes = [
  "aac",
  "flac",
  "mp3",
  "m4a",
  "mpeg",
  "mpga",
  "mp4",
  "opus",
  "pcm",
  "wav",
  "webm",
];

interface Props {
  name: string;
  fileExtension: string;
  url?: string;
  blob: any;
}

export const AttachmentCard: React.FC<Props> = ({
  name,
  fileExtension,
  url,
  blob,
}) => {
  let cardContent;

  if (imgFileTypes.includes(fileExtension)) {
    cardContent = (
      <>
        <CardImg
          top
          src={url ? url : URL.createObjectURL(blob)}
          alt={name}
          style={{ maxHeight: "200px", objectFit: "cover" }}
        />
        <CardTitle tag='h5' className='mt-3'>
          {name}
        </CardTitle>
      </>
    );
  } else if (audioFileTypes.includes(fileExtension)) {
    cardContent = (
      <audio controls className='w-100'>
        <source src={url ? url : URL.createObjectURL(blob)} type={blob.type} />
        Your browser does not support the audio element.
      </audio>
    );
  } else if (videoFileTypes.includes(fileExtension)) {
    cardContent = (
      <video controls className='w-100' style={{ maxHeight: "200px" }}>
        <source src={url ? url : URL.createObjectURL(blob)} type={blob.type} />
        Your browser does not support the video tag.
      </video>
    );
  } else {
    let iconClass = "fas fa-file";
    if (fileExtension === "pdf") {
      iconClass = "fas fa-file-pdf";
    } else if (fileExtension === "csv") {
      iconClass = "fas fa-file-csv";
    } else if (fileExtension === "json") {
      iconClass = "fas fa-file-code";
    }

    cardContent = (
      <div className='text-center'>
        <i className={`${iconClass} fa-3x`} />
        <CardTitle tag='h5' className='mt-3'>
          {name}
        </CardTitle>
      </div>
    );
  }

  return (
    <Card className='attachment-card mb-3'>
      <CardBody className={fileExtension}>{cardContent}</CardBody>
    </Card>
  );
};

export default AttachmentCard;
