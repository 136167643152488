import {
  Avatar,
  Divider,
  Stack,
  Typography,
  MenuItem,
  Backdrop,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import { Response } from "models";
import React, { useEffect, useState } from "react";
import { useChatStore } from "store/chat/chat.store";
import { useConsumerStore } from "store/consumers/consumer.store";

interface Props {
  onChange: (value: string) => void;
}

const AgentOption: React.FC<{
  consumer: Response | null;
  onClick: () => void;
}> = ({ consumer, onClick }) => {
  return (
    <Stack
      onClick={onClick}
      direction='row'
      spacing={1}
      alignItems='center'
      className='my-2 cursor-pointer'
      sx={{
        width: "100%",
        border: `1px solid #f0f0f0`,
        p: 1,
        borderRadius: 4,
        transition: "background-color 0.2s ease-in-out",
        ":hover": {
          backgroundColor: "#f0f0f0",
        },
      }}
    >
      <Avatar
        src={require("assets/img/bot-avatar-2.png")}
        sx={{ border: "1px solid #f0f0f0", borderRadius: "50%" }}
      ></Avatar>
      <Divider orientation='vertical' flexItem />
      <Stack direction='column' spacing={0} alignItems='start'>
        <Typography fontWeight={600} variant='subtitle2' color='text.secondary'>
          {consumer?.botHumanName}
        </Typography>
        <Typography
          textOverflow='hidden'
          overflow='hidden'
          color='lightslategray'
          className='text-truncate'
          fontSize={11}
        >
          Created: {consumer?.created}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const ConsumerOption: React.FC<Props> = ({ onChange }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [getAllConsumers, consumers] = useConsumerStore((state) => [
    state.getAllConsumers,
    state.consumers,
  ]);

  const [filteredConsumers, setFilteredConsumers] =
    useState<Response[]>(consumers);

  const consumerSelected = useChatStore((state) => state.consumerSelected);

  const setFilters = (filter: string) => {
    if (!filter) {
      setFilteredConsumers(consumers);
      return;
    }
    const lowercasedTerm = filter.toLowerCase();
    const filtered = consumers.filter((consumer: Response) => {
      return consumer.botHumanName.toLowerCase().includes(lowercasedTerm);
    });

    setFilteredConsumers(filtered);
  };

  useEffect(() => {
    getAllConsumers();
  }, []);

  useEffect(() => {
    if (consumers && consumers.length > 0) {
      setFilteredConsumers(consumers);
    }
  }, [consumers]);

  return (
    <>
      {consumerSelected && (
        <AgentOption onClick={() => handleOpen()} consumer={consumerSelected} />
      )}

      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={open}
      >
        <Paper
          sx={(theme) => ({
            p: 2,
            maxHeight: "40vh",
            minHeight: "40vh",
            overflowY: "auto",
            borderRadius: 10,
            zIndex: theme.zIndex.drawer + 2,
          })}
        >
          <Stack
            direction='row'
            spacing={1}
            justifyContent='space-between'
            alignItems='center'
            sx={{ mb: 1 }}
          >
            <Typography variant='body1' align='center'>
              Choose an agent here
            </Typography>
            <TextField
              size='small'
              variant='filled'
              label='Search'
              placeholder='Type your agent name here.'
              sx={{
                width: "300px",
                "& .MuiInputBase-input": { fontSize: "13px", height: "20px" },
              }}
              onInput={(event: any) => setFilters(event.target.value)}
            />
          </Stack>
          <Divider variant='fullWidth' />
          <Stack
            direction='column'
            spacing={1}
            alignItems='center'
            sx={{ overflowY: "auto", maxHeight: "25vh", minHeight: "25vh" }}
          >
            {filteredConsumers.map((consumer) => {
              return (
                <MenuItem
                  sx={{
                    cursor: "pointer",
                    m: 0,
                    p: 0,
                    width: "100%",
                    height: "auto",
                    ":hover": { bgcolor: "transparent" },
                  }}
                  key={consumer.consumerId}
                  onClick={() => {
                    onChange(JSON.stringify(consumer));
                    handleClose();
                  }}
                >
                  <AgentOption
                    onClick={() => onChange(JSON.stringify(consumer))}
                    consumer={consumer}
                  />
                </MenuItem>
              );
            })}
          </Stack>
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            justifyContent='end'
            className='mt-3'
            onClick={() => handleClose()}
          >
            <Button color='error'>Close</Button>
          </Stack>
        </Paper>
      </Backdrop>
    </>
  );
};
