import { useState } from "react";
import { Modal } from "reactstrap";
import { usePromptsStore } from "store/prompts/prompts.store";
import { CreatePrompt } from "views/prompts/create/CreatePrompt";

interface Props {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreatePromptModal: React.FC<Props> = ({ openModal, setOpenModal }) => {
  const [getAllPrompts] = usePromptsStore((state) => [state.getAll]);

  return (
    <>
      <Modal isOpen={openModal} className="createPromptModal" style={{"maxWidth": "1300px"}}>
        <div className='d-flex justify-content-end m-2 text-lg'>
          <i
            style={{ cursor: "pointer" }}
            className='fa fa-close mr-2'
            onClick={() => setOpenModal(false)}
          ></i>
        </div>

        <CreatePrompt
          promptCreated={async () => {
            await getAllPrompts();
            setOpenModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default CreatePromptModal;
