import { ConsumerResquestParams, Response, initalConsumerState } from "models";
import {
  getConsumerById,
  create as createConsumer,
  updateConsumer,
  getAllConsumers,
} from "services/consumers";
import { ConsumerAdapter } from "../../views/consumers/adapters/consumer.adapter";

import { create } from "zustand";

interface State {
  consumer: ConsumerResquestParams;
  consumers: Response[];
  getConsumerById: (consumerId: string) => Promise<void>;
  getAllConsumers: () => void;
  setConsumer: (consumer: ConsumerResquestParams) => void;
  createConsumer: (consumer: ConsumerResquestParams) => Promise<any>;
  updateConsumer: (
    consumerId: string,
    consumer: ConsumerResquestParams
  ) => Promise<any>;
}

export const useConsumerStore = create<State>((set) => {
  return {
    consumers: [],
    consumer: initalConsumerState,
    setConsumer: (consumer: ConsumerResquestParams) => {
      set({ consumer });
    },
    getAllConsumers: async () => {
      try {
        const { data } = await getAllConsumers();
        set({ consumers: ConsumerAdapter(data) });
      } catch (error) {
        return error;
      }
    },
    getConsumerById: async (consumerId: string) => {
      const { data } = await getConsumerById(consumerId);
      if (data) {
        set({
          consumer: {
            ...data,
            masterPromptId: data.masterPromptId || undefined,
          },
        });
      }
    },
    createConsumer: async (consumer: ConsumerResquestParams) => {
      const response = await createConsumer(consumer);
      if (response.data) {
        set({ consumer: response.data });
      }
      return response;
    },
    updateConsumer: async (
      consumerId: string,
      consumer: ConsumerResquestParams
    ) => {
      const newConsumer = {
        ...consumer,
        modelParams: consumer.modelParams?.model
          ? consumer.modelParams
          : undefined,
        indexName: consumer.indexName || undefined,
      };

      const response = await updateConsumer(consumerId, newConsumer);
      return response;
    },
  };
});
