import { apiService } from "api";
import { ModelParams } from "models/NewModelParams.model";

export interface MasterParams {
  consumerId: string;
  indexName: string;
  modelParams: ModelParams;
  question: string;
  session: string;
  documents?: any | null;
  placeholders?: any | null;
  messageCount?: number | null;
}

export const postToMaster = async (data: MasterParams) => {
  return await apiService.post("/master", data);
};
