import { ConsumerResquestParams } from "models";
import { SnackbarManager } from "utils";

export const ensureCreateConsumer = (consumer: ConsumerResquestParams) => {
  if (!consumer.botHumanName)
    throw SnackbarManager.error("Bot name is required!");
  if (!consumer.welcomeMessage)
    throw SnackbarManager.warning("Welcome message not provided!");
  if (consumer.prompts.length === 0)
    throw SnackbarManager.warning("No prompts provided!");
  if (!consumer.defaultPromptId)
    throw SnackbarManager.warning("Select a default prompt id!");
};
