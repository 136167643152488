import {
  Chip,
  Collapse,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material";
import AxmosInput from "components/Form/AxmosInput";
import { AxmosTooltip } from "components/Form/AxmosTooltip";
import { PreDefinedModelParams } from "components/PreDefinedModelParams";
import { Integration, PromptUpdateAndCreate } from "models";
import { PropsWithChildren, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Label,
  Row,
} from "reactstrap";
import { getAllModels } from "services/models";
import { useIndexStore } from "store/embeddings/index-documents.store";
import { useDrawerStore } from "store/integrations/integration-drawer.store";
import { usePromptsStore } from "store/prompts/prompts.store";
import { SnackbarManager } from "utils";
import { PromptEditor } from "./PromptEditor";
import { AdvancedModelParams } from "./components/AdvancedModelParams";
import { AddIndexModal } from "./components/index-drawer/AddIndexModal";
import AddIntegrationsModal from "./components/integrations-drawer/AddIntegrationsModal";
import { PromptWizardModal } from "./components/wizard/PromptWizardModal";
import { useFileHandler } from "./hooks";

interface Props {
  onSubmit: (prompt: PromptUpdateAndCreate) => void;
}

const models = getAllModels();

export const PromptForm: React.FC<PropsWithChildren<Props>> = ({
  children,
  onSubmit,
}) => {
  const [openWizard, setOpenWizard] = useState<boolean>(false);
  const [isOpenIndexDrawer, setIsOpenIndexDrawer] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showAdvancedModelParams, setShowAdvandedModelParams] =
    useState<boolean>(false);

  const [prompt, setPrompt, setPlaceholderList, placeholderList] =
    usePromptsStore((state) => [
      state.prompt,
      state.setPrompt,
      state.setPlaceholdersList,
      state.placeholdersList,
    ]);

  const [getNotRemovedDocuments] = useIndexStore((state) => [
    state.getNotRemovedDocuments,
  ]);

  const { removeFile } = useFileHandler();

  const [setExpanded] = useDrawerStore((state) => [state.setExpanded]);

  const params = useParams();

  const updateDrawerIntegration = (
    integrationId: Integration["integrationId"]
  ) => {
    const panel = `panel-${integrationId}`;
    setExpanded(panel);
    setOpenModal(true);
  };

  const deleteDocument = (name: string) => removeFile(name);

  const deleteIntegration = (integration: Integration) => {
    setPrompt({
      ...prompt,
      integrations: [
        ...prompt.integrations.filter(
          (pi) => pi.integrationId !== integration.integrationId
        ),
      ],
    });
    SnackbarManager.success("Integration deleted successfully.");
  };

  const addPlaceholder = () => {
    setPlaceholderList(
      placeholderList.concat({
        key: "",
        value: "",
      })
    );
  };

  const setPlaceholderKey = (index: number, value: string, ph: any) => {
    setPlaceholderList([
      ...placeholderList.slice(0, index),
      { ...ph, key: value },
      ...placeholderList.slice(index + 1),
    ]);
  };

  const setPlaceholderValue = (index: number, value: string, ph: any) => {
    setPlaceholderList([
      ...placeholderList.slice(0, index),
      { ...ph, value: value },
      ...placeholderList.slice(index + 1),
    ]);
  };

  const removePlaceholder = (index: number) => {
    setPlaceholderList(placeholderList.filter((_, i) => i !== index));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAdvandedModelParams(event.target.checked);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(prompt);
  };

  useEffect(() => {
    if (params.id) setShowAdvandedModelParams(true);
  }, []);

  return (
    <>
      <PromptWizardModal
        setOpenWizardModal={(value: boolean) => setOpenWizard(value)}
        openWizard={openWizard}
        onChange={(value: string) =>
          setPrompt({
            ...prompt,
            content: { ...prompt.content, prompt: value },
          })
        }
      />
      <AddIndexModal
        isOpen={isOpenIndexDrawer}
        setIsOpen={(value: boolean) => setIsOpenIndexDrawer(value)}
      />
      <AddIntegrationsModal
        closeModal={() => setOpenModal(false)}
        isOpen={openModal}
        openModal={() => setOpenModal(true)}
        promptIntegrations={prompt.integrations}
      />

      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} xs={12}>
            <Card style={{ height: "83vh" }}>
              <CardHeader className='bg-default text-white'>
                <div className='d-flex justify-content-between align-items-center  '>
                  <span className='text-light text-sm mb-1'>Prompt Editor</span>
                  <Button
                    className='btn btn-primary btn-sm'
                    type='button'
                    id='newSession'
                    onClick={() => setOpenWizard(true)}
                  >
                    {" "}
                    Prompt Wizard <i className='fa fa-user-secret ml-2'></i>
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <PromptEditor
                  value={prompt.content.prompt}
                  setValue={(value: string) =>
                    setPrompt({
                      ...prompt,
                      content: { ...prompt.content, prompt: value },
                    })
                  }
                />
              </CardBody>
            </Card>
          </Col>

          <Col md={6} xs={12}>
            <Card style={{ height: "83vh" }}>
              <CardHeader>
                <div className='d-flex justify-content-between align-items-center'>
                  <span className='text-dark text-sm mb-1'>
                    Prompt Configuration <i className='fa fa-cog ml-2'></i>{" "}
                  </span>

                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "12px" },
                    }}
                    control={
                      <Switch
                        size='small'
                        onChange={handleChange}
                        checked={showAdvancedModelParams}
                      />
                    }
                    label={`${
                      showAdvancedModelParams ? "Disable" : "Enable"
                    } advanced mode`}
                  />
                </div>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col md={6}>
                    <AxmosInput
                      tooltipMessage='The prompt name'
                      required={true}
                      value={prompt.name}
                      id='name'
                      label='Name'
                      placeholder='Type your prompt name here'
                      name='name'
                      type='text'
                      onInput={(value) => setPrompt({ ...prompt, name: value })}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <AxmosInput
                      tooltipMessage='The prompt description'
                      required={true}
                      value={prompt.description}
                      id='description'
                      label='Description'
                      placeholder='Type your prompt description'
                      name='description'
                      rows='3'
                      type='textarea'
                      onInput={(value) =>
                        setPrompt({ ...prompt, description: value })
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <AxmosInput
                      tooltipMessage='The prompt act as'
                      required={true}
                      value={prompt.content.actAs}
                      id='actAs'
                      label='Act as'
                      rows='3'
                      placeholder='Type act as here.'
                      name='actAs'
                      type='text'
                      onInput={(value) =>
                        setPrompt({
                          ...prompt,
                          content: { ...prompt.content, actAs: value },
                        })
                      }
                    />
                  </Col>

                  <Col>
                    <AxmosInput
                      tooltipMessage='The prompt type'
                      required={true}
                      value={prompt.content.type}
                      id='type'
                      label='Type'
                      placeholder='Type something here.'
                      name='type'
                      type='text'
                      onInput={(value) =>
                        setPrompt({
                          ...prompt,
                          content: { ...prompt.content, type: value },
                        })
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <AxmosInput
                      tooltipMessage='The prompt model'
                      value={prompt.modelParams.model}
                      id='model'
                      label='Model'
                      name='model'
                      type='select'
                      style={{ cursor: "pointer" }}
                      required={true}
                      onChange={(value) =>
                        setPrompt({
                          ...prompt,
                          modelParams: {
                            ...prompt.modelParams,
                            model: value,
                          },
                        })
                      }
                    >
                      {models.map((model) => (
                        <option key={model.name} value={model.value}>
                          {model.name}
                        </option>
                      ))}
                    </AxmosInput>
                  </Col>

                  <Col>
                    <PreDefinedModelParams
                      disabled={showAdvancedModelParams}
                      onChange={(value) =>
                        setPrompt({
                          ...prompt,
                          modelParams: {
                            ...value,
                            model: prompt.modelParams.model,
                          },
                        })
                      }
                    />
                  </Col>
                </Row>

                {showAdvancedModelParams && <AdvancedModelParams />}

                <Row>
                  <Col>
                    <div
                      className='d-flex justify-content-between align-items-start mb-2'
                      style={{ gap: "1px" }}
                    >
                      <Label for='index' className='text-sm m-0'>
                        Documents
                      </Label>
                      <span
                        onClick={() => setIsOpenIndexDrawer(true)}
                        className='text-xs text-primary  cursor-pointer'
                      >
                        <i className='fa fa-plus text-xs'></i> Add documents
                        here.
                      </span>
                    </div>

                    <div
                      className='border rounded form-control d-flex flex-wrap mb-2'
                      style={{ gap: "3px", height: "auto" }}
                    >
                      {getNotRemovedDocuments() &&
                      getNotRemovedDocuments().length > 0 ? (
                        getNotRemovedDocuments().map((document, index) => {
                          return (
                            <Chip
                              variant='filled'
                              size='small'
                              key={index}
                              label={document.name}
                              onDelete={() => deleteDocument(document.name)}
                            />
                          );
                        })
                      ) : (
                        <span style={{ color: "#b5bdc4" }}>
                          No documents added.
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className='d-flex justify-content-between align-items-start mb-2'
                      style={{ gap: "1px" }}
                    >
                      <Label for='index' className='text-sm m-0'>
                        Integrations
                      </Label>
                      <span
                        onClick={() => setOpenModal(true)}
                        className='text-xs text-primary cursor-pointer'
                      >
                        <i className='fa fa-plus text-xs'></i> Add more
                        integrations here.
                      </span>
                    </div>

                    <div
                      className='border rounded form-control d-flex flex-wrap mb-2'
                      style={{ gap: "3px", height: "auto" }}
                    >
                      {prompt.integrations.length > 0 ? (
                        prompt.integrations.map((integration, index) => {
                          return (
                            <div key={integration.integrationId}>
                              <Tooltip
                                title='Update Integration'
                                placement='bottom'
                              >
                                <Chip
                                  variant='filled'
                                  size='small'
                                  label={integration.route}
                                  onClick={() =>
                                    updateDrawerIntegration(
                                      integration.integrationId
                                    )
                                  }
                                  onDelete={() =>
                                    deleteIntegration(integration)
                                  }
                                />
                              </Tooltip>
                            </div>
                          );
                        })
                      ) : (
                        <span style={{ color: "#b5bdc4" }}>
                          No integrations added.
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>

                <div
                  style={{ marginTop: "20px" }}
                  className='d-flex align-items-center justify-content-between'
                >
                  <div className='d-flex align-items-center'>
                    <Label className='text-sm mr-1'>Placeholders</Label>
                    <AxmosTooltip
                      style={{ color: "#ffa300a3" }}
                      message='Placeholders are special symbols or words in a prompt that are meant to be replaced with actual data or information when the prompt is used. 
                      They act as placeholders for specific pieces of content and are typically written in a format like {username}.'
                      className='text-sm mt--1'
                    />
                  </div>
                  <span
                    onClick={() => addPlaceholder()}
                    className='text-xs text-primary  cursor-pointer'
                  >
                    <i className='fa fa-plus text-xs'></i> Add placeholders
                    here.
                  </span>
                </div>

                <TransitionGroup>
                  {placeholderList.map((ph, index) => {
                    return (
                      <Collapse>
                        <div
                          className='d-flex w-100 align-items-center'
                          style={{ gap: "3px" }}
                          key={index}
                        >
                          <AxmosInput
                            label='Key'
                            placeholder='Type your key here.'
                            id={`${index}-placeholderKey`}
                            name='placeholderKey'
                            type='text'
                            value={ph.key}
                            onInput={(value) =>
                              setPlaceholderKey(index, value, ph)
                            }
                          />

                          <AxmosInput
                            style={{ minWidth: "300px" }}
                            label='Default value'
                            placeholder='Type your default value here.'
                            id={`${index}-placeholderValue`}
                            name='placeholderValue'
                            type='text'
                            value={ph.value}
                            onInput={(value) =>
                              setPlaceholderValue(index, value, ph)
                            }
                          />

                          <Button
                            className='m-0'
                            type='button'
                            size='sm'
                            color='danger'
                            onClick={() => removePlaceholder(index)}
                          >
                            <i className='fas fa-trash'></i>
                          </Button>
                        </div>
                      </Collapse>
                    );
                  })}
                </TransitionGroup>
              </CardBody>
              <CardFooter>{children}</CardFooter>
            </Card>
          </Col>
        </Row>
      </form>
    </>
  );
};
