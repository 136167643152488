import "plugins/CSS";
import "i18n/i18n.js";

import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import Routes from "routes";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material";
import { theme } from "plugins/theme";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <SnackbarProvider>
    <ThemeProvider theme={theme}>
      <RouterProvider router={Routes}></RouterProvider>
    </ThemeProvider>
  </SnackbarProvider>
);
