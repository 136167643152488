import { deleteDocumentsByIndex } from "services/embeddings/delete-document-index";
import { getIndexDocuments } from "services/embeddings/get-index-documents";
import { create } from "zustand";

export enum DocumentStatusEnum {
  ok = "ok",
  removed = "removed",
}

type DocumentType = {
  name: string;
  file?: File;
  isNew?: boolean;
  status: DocumentStatusEnum | string;
};

interface State {
  documents: DocumentType[];
  indexName: string;
  getIndexDocuments_: (indexName: string) => Promise<any>;
  setDocuments: (documents: DocumentType[]) => void;
  setIndexName: (indexName: string) => void;
  deleteDocuments: (indexName: string, files: string[]) => Promise<any>;
  changeDocumentStatus: (name: string, status: DocumentStatusEnum) => void;
  getNotRemovedDocuments: () => DocumentType[];
}

export const useIndexStore = create<State>((set, get) => {
  return {
    indexName: "",
    documents: [],
    setDocuments: (documents: DocumentType[]) => set({ documents }),
    setIndexName: (indexName: string) => set({ indexName }),
    deleteDocuments: async (indexName: string, files: string[]) => {
      try {
        const response = await deleteDocumentsByIndex(indexName, files);
        console.log(response);
        return response;
      } catch (error) {
        console.log(error);
        throw new Error();
      }
    },

    getNotRemovedDocuments: () =>
      get().documents.filter((doc) => doc.status === DocumentStatusEnum.ok),

    changeDocumentStatus: (name: string, status: DocumentStatusEnum) => {
      const documents = get().documents;
      const index = documents.findIndex((doc) => doc.name === name);
      //Check if the file was found before changing its status
      if(index === -1){
        return; //We exit the function if we do not find the document
      }
      documents[index].status = status;
      set({ documents });
    },

    getIndexDocuments_: async (indexName: string) => {
      try {
        const { data } = await getIndexDocuments(indexName);
        set({
          documents: data.documents.map((doc: string) => ({
            name: doc,
            file: undefined,
            isNew: false,
            status: DocumentStatusEnum.ok,
          })),
          indexName: data.indexName,
        });
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  };
});
