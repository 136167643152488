import { create } from "zustand";

interface SettingsState {
    textChunkSize: number;
    chunkOverlap: number;
    delimiter: string;
    findTextJson: string | null; // Allow null values ​​for findTextJson
    setTextChunkSize: (textChunkSize: number) => void;
    setChunkOverlap: (chunkOverlap: number) => void;
    setDelimiter: (delimiter: string) => void;
    setFindTextJson: (findTextJson: string | null) => void;
}

const useSettingsStore = create<SettingsState>((set) => ({
    textChunkSize: 400, // Values for defect
    chunkOverlap: 40,
    delimiter: ';',
    findTextJson: null,
    setTextChunkSize: (textChunkSize) => set({ textChunkSize }),
    setChunkOverlap: (chunkOverlap) => set({ chunkOverlap }),
    setDelimiter: (delimiter) => set({ delimiter }),
    setFindTextJson: (findTextJson) => set({ findTextJson }),
}));

export default useSettingsStore;