import { apiService } from "api";

export const deleteDocumentsByIndex = async (
  indexName: string,
  files: string[]
) => {
  return await apiService.post("embedding/DeleteFilesFromIndex", {
    indexName,
    files,
  });
};
