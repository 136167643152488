import {
  Button,
  Collapse,
  Grid,
  Paper,
  Slider,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { Transition } from "react-transition-group";
import AxmosInput from "components/Form/AxmosInput";
import { SafetySettings } from "components/SafetySettings/SafetySettings";
import { TypeWithKey } from "models";
import { ModelParams } from "models/NewModelParams.model";
import { useEffect, useState } from "react";
import { getAllModels } from "services/models";
import { PlaceholdersSettings } from "./PlaceholderSettings";
import { Colors } from "consts/Colors.const";

interface Props {
  initialModelParams: ModelParams;
  onChange?: (value: ModelParams, historyLimitSize: number | null) => void;
}

export const ChatBoxModelParams: React.FC<Props> = ({
  initialModelParams,
  onChange,
}) => {
  const [modelParams, setModelParams] =
    useState<ModelParams>(initialModelParams);

  const [showSafetySettings, setShowSafetySettings] = useState(false);

  const modelList: TypeWithKey<string | undefined>[] = getAllModels();

  const [historyLimitSize, setHistoryLimitSize] = useState<number | null>(null);
  const [disableHistoryLimitSize, setDisableHistoryLimitSize] = useState(true);

  useEffect(() => {
    if (onChange) onChange(modelParams, historyLimitSize);
  }, [modelParams, historyLimitSize]);

  useEffect(() => {
    setModelParams(initialModelParams);
  }, [initialModelParams]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableHistoryLimitSize(event.target.checked);
    if (event.target.checked) {
      setHistoryLimitSize(null);
    }
  };

  return (
    <Paper
      elevation={1}
      style={{
        minWidth: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        fontFamily: "Google Sans, sans-serif",
      }}
    >
      <Stack
        sx={{
          minWidth: "100%",
          p: 1,
          bgcolor: `${Colors.primary}`,
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        }}
        direction='row'
        alignItems='center'
      >
        <Typography color='white' variant='subtitle1' align='left'>
          Settings:
        </Typography>
      </Stack>
      <div
        className='py-2 px-2'
        style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}
      >
        <Grid container alignItems='center'>
          <Grid item xs>
            <AxmosInput
              label='Model'
              type='select'
              value={modelParams?.model || ""}
              onChange={(model) => setModelParams({ ...modelParams, model })}
              className='cursor-pointer'
              style={{ height: "42px" }}
            >
              {modelList.map((model) => (
                <option value={model.value}>{model.name}</option>
              ))}
            </AxmosInput>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems='center'>
          <Grid item xs>
            <AxmosInput
              value={modelParams?.temperature}
              onChange={(value) =>
                setModelParams({
                  ...modelParams,
                  temperature: +value,
                })
              }
              min={0}
              max={1}
              step={0.1}
              type='range'
              name='temperature'
              id='temperature'
              label='Temperature'
              placeholder='Temperature'
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems='center'>
          <Grid item xs>
            <AxmosInput
              value={modelParams?.topP}
              onChange={(value) =>
                setModelParams({
                  ...modelParams,
                  topP: +value,
                })
              }
              min={0}
              max={1}
              step={0.1}
              type='range'
              name='topp'
              id='topp'
              label='Top P'
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems='center'>
          <Grid item xs>
            <AxmosInput
              label='Top K'
              type='number'
              value={modelParams?.topK}
              onChange={(topK) => setModelParams({ ...modelParams, topK })}
              style={{ height: "42px" }}
            ></AxmosInput>
          </Grid>

          <Grid item xs>
            <AxmosInput
              label='Max output tokens'
              type='number'
              value={modelParams?.maxOutputTokens || 0}
              onChange={(maxOutputTokens) =>
                setModelParams({ ...modelParams, maxOutputTokens })
              }
              style={{ height: "42px" }}
            ></AxmosInput>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems='center'>
          <Grid item xs>
            <AxmosInput
              label="History Limit Size"
              type="number"
              value={historyLimitSize || ""}
              onChange={(value) => 
                setHistoryLimitSize(parseInt(value, 10))}
              placeholder="Enter history limit size"
              disabled={disableHistoryLimitSize}
            ></AxmosInput>
          </Grid>
          </Grid>

        <Grid container spacing={0} alignItems='center' sx={{ marginTop: -2.5 }}>
        <FormControlLabel
            control={
              <Checkbox
              checked={disableHistoryLimitSize}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="Unlimited History Size"
          />
          </Grid>

        <AxmosInput
          label='Output format'
          type='select'
          value={modelParams?.response_mime_type || ""}
          onChange={(response_mime_type) =>
            setModelParams({
              ...modelParams,
              response_mime_type: response_mime_type as string,
            })
          }
          style={{ height: "42px" }}
          className='cursor-pointer'
        >
          <option value='json'>JSON</option>
          <option value='text'>Text</option>
        </AxmosInput>

        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='space-between'
          className='mt-3'
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => setShowSafetySettings(!showSafetySettings)}
            size='small'
            className='my-2'
          >
            Safety settings
          </Button>

          <PlaceholdersSettings />
        </Stack>

        {showSafetySettings && (
          <SafetySettings
            title=''
            defaultValues={initialModelParams?.safetySettings}
            onChange={(e) =>
              setModelParams({ ...modelParams, safetySettings: e })
            }
          />
        )}
      </div>
    </Paper>
  );
};
