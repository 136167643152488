import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import FileViewer from "./FileViewer";
import { Stack } from "@mui/material";

interface ChatMessageProps {
  messages: IMessage[];
  botName?: string;
}

interface IMessage {
  text: string;
  attachment?: Array<Attachment> | null;
  sender: Sender;
  time: string;
}

interface Attachment {
  name: string;
  fileExtension: string;
  url?: string | null;
  blob?: any | null;
  mimeType: string;
}

type Sender = "user" | "bot";

const ChatMessage: React.FC<ChatMessageProps> = ({ messages, botName }) => {
  return (
    <div className='chat-container'>
      {messages.map((message, index) => (
        <div key={index} className='flex flex-row' style={{ gap: 2 }}>
          <img
            alt='...'
            style={{
              marginBottom: "-2em",
              marginLeft: "-1em",
              objectFit: "contain",
              backgroundColor: "white",
            }}
            className='avatar avatar-md media-comment-avatar rounded-circle'
            src={
              message.sender == "user"
                ? require("assets/img/user-avatar.png")
                : require("assets/img/bot-avatar-2.png")
            }
          ></img>
          <small className='ml-5 text-gray-500 opacity-2 text-xs'>
            {message.time}
          </small>

          <div
            style={{
              marginBottom: "0.5rem",
              color: "#3b3b3b",
              backgroundColor: "#f6f9fc",
              borderRadius: "0.4375rem",
              borderTopLeftRadius: "0",
              padding: "1rem 1.25rem 1rem 2.5rem",
            }}
            className='message'
          >
            <div
              className='rounded-lg px-1 text-sm text-truncate'
              style={{ width: "auto", maxWidth: "100px" }}
            >
              <strong title={message.sender} className='text-capitalize'>
                {message.sender === "user" ? "You" : botName}:
              </strong>
            </div>{" "}
            <ReactMarkdown
              className='ml-3 text-sm'
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
            >
              {message.text}
            </ReactMarkdown>
            <Stack direction='row' flexWrap={"wrap"} gap={1}>
              {message.attachment?.map((a: any, index) => {
                return (
                  <FileViewer
                    file={a}
                    showClose={false}
                    key={index}
                    onDelete={() => {}}
                  />
                );
              })}
            </Stack>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatMessage;
