
import PropTypes from "prop-types";

import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";

function TimelineHeader({ name, parentName, subtitle = "" }) {
  return (
    <>
      <div className='header header-dark pb-3 bg-primary content__title content__title--calendar'>
        <Container fluid>
          <div className='header-body'>
            <Row className='align-items-center py-3'>
              <Col lg='6' xs='7'>
                <h6 className='fullcalendar-title h2 text-white d-inline-block mb-0'>
                  {name}
                </h6>
                <Breadcrumb
                  className='mt-2 mt-md-0 d-md-inline-block ml-lg-4'
                  listClassName='breadcrumb-links breadcrumb-dark'
                >
                  <BreadcrumbItem>
                    <Link to={'/admin/dashboard'}>
                      <i className='fas fa-home' />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href='#' onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current='page' className='active'>
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
                
              </Col>
              
            </Row>
            <Row className="pl-3">
              <p className="m-0 text-white font-weight-bold text-xs opacity-8">{subtitle}</p>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  subtitle: PropTypes.string,
};

export default TimelineHeader;
