import { MethodEnum } from "enums";
import { ModelParams } from "models/NewModelParams.model";

export interface PromptsResponse {
  data: Prompt[];
  message: string;
}

export interface Prompt {
  promptId: string;
  name: string;
  description: string;
  content: Content;
  updated?: string;
  indexName?: string;
  created?: string;
  placeholders: any;
  modelParams?: ModelParams;
}

export interface PromptUpdateAndCreate {
  promptId?: string;
  name: string;
  description: string;
  content: Content;
  placeholders: Record<string, any>;
  created: string;
  modelParams: ModelParams;
  integrations: Integration[];
  indexName?: string;
}

export interface Content {
  type?: string;
  actAs: string;
  modelType: string;
  prompt: string;
  defaultLanguage?: string;
  defaultFormat?: string;
}

export interface Integration {
  baseUrl: string;
  method: string;
  route: string;
  purpose?: string;
  headers?: Record<string, any>;
  json?: Record<string, any>;
  params?: Record<string, any>;
  cacheable?: any;
  result: string;
  authType?: string;
  tokenLocation?: string;
  authUrl?: string;
  token?: string;
  integrationId: string;
  created?: string;
  updated?: any;
  isNew?: boolean;
  authBody?: Record<string, any>;
  isOnPrompt?: boolean;
}

export interface Params {
  subjectId: string;
}

export const initialIntegrationState: Integration = {
  baseUrl: "",
  method: MethodEnum.get,
  route: "",
  headers: {},
  json: {},
  purpose: undefined,
  params: undefined,
  cacheable: false,
  result: "",
  authType: undefined,
  tokenLocation: "",
  authUrl: undefined,
  token: undefined,
  integrationId: "",
  created: "",
  updated: "",
};
