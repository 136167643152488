import { Button } from "reactstrap";
import CreateOrUpdateForm from "../CreareOrUpdateForm";
import { usePromptsStore } from "store/prompts/prompts.store";
import { useCallback, useEffect, useState } from "react";
import { SnackbarManager } from "utils";
import { useParams } from "react-router-dom";
import { useIntegrationsStore } from "store/integrations/integrations.store";
import { useEmbeddingStore } from "store/embeddings/embedding.store";
import {
  DocumentStatusEnum,
  useIndexStore,
} from "store/embeddings/index-documents.store";
import { useFilesStore } from "store/embeddings/files.store";
import useSettingsStore from "store/embeddings/settings.store";

const UpdatePrompt: React.FC = () => {
  const { id } = useParams();

  const _updatePrompt = usePromptsStore((state) => state.updatePrompt);

  const [prompt, getPromptById, setPrompt] = usePromptsStore((state) => [
    state.prompt,
    state.getPromptById,
    state.setPrompt,
  ]);

  const { textChunkSize, chunkOverlap, delimiter, findTextJson } = useSettingsStore();

  const [createIntegration, getNewIntegrations, integrations] =
    useIntegrationsStore((state) => [
      state.createIntegration,
      state.getNewIntegrations,
      state.integrations,
    ]);

  const [
    getIndexDocuments_,
    setIndexName,
    indexDocuments,
    indexName,
    deleteDocuments,
  ] = useIndexStore((state) => [
    state.getIndexDocuments_,
    state.setIndexName,
    state.documents,
    state.indexName,
    state.deleteDocuments,
  ]);

  const [uploadFiles, setPurpose] = useEmbeddingStore((state) => [
    state.uploadFiles,
    state.setPurpose,
  ]);
  const [files, setFiles] = useFilesStore((state) => [
    state.files,
    state.setFiles,
  ]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getNewDocuments = () =>
    indexDocuments.filter(
      (doc) => doc.isNew && doc.status === DocumentStatusEnum.ok
    );

  const getRemovedDocuments = () =>
    indexDocuments
      .filter((doc) => doc.status === DocumentStatusEnum.removed)
      .map((doc) => doc.name);

  const handleUpdate = async () => {
    setIsLoading(true);
    setPrompt({ ...prompt });
    const newIntegrations = getNewIntegrations();
    const newDocuments = getNewDocuments();
    const removedDocuments = getRemovedDocuments();

    console.log({ prompt, indexName });

    try {
      if (removedDocuments.length >= 1) {
        await deleteDocuments(indexName, removedDocuments);
      }

      await uploadFiles({ indexName, files: newDocuments },
        indexName, 
        { textChunkSize, chunkOverlap, delimiter, findTextJson });

      if (newIntegrations.length > 0) {
        const promises = newIntegrations.map((integration) => {
          return createIntegration(integration);
        });
        await Promise.all(promises);
      }
      await _updatePrompt(prompt);
      await getPromptById(id as string);
      SnackbarManager.success("Prompt updated successfully");
    } catch (error) {
      SnackbarManager.error(error as string);
    } finally {
      setIsLoading(false);
    }
  };

  const updateIntegrations = useCallback(() => {
    if (prompt && integrations.length > 0) {
      const newIntegrations = integrations.filter((integration) =>
        prompt.integrations.some(
          (pi) => pi.integrationId === integration.integrationId
        )
      );
      if (
        JSON.stringify(newIntegrations) !== JSON.stringify(prompt.integrations)
      ) {
        setPrompt({ ...prompt, integrations: newIntegrations });
      }
    }
  }, [prompt, integrations, setPrompt]);

  useEffect(() => {
    updateIntegrations();
  }, [updateIntegrations]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPromptById(id as string);
      if (response.indexName) {
        setIndexName(response.indexName);
        const iDocuments = await getIndexDocuments_(response.indexName);
        setPurpose(iDocuments ? iDocuments.purpose : "");
        setFiles(
          iDocuments
            ? iDocuments.documents.map((doc: string) => ({
                name: doc,
                file: undefined,
                isNew: false,
                status: DocumentStatusEnum.ok,
              }))
            : []
        );
      }
    };
    fetchData();
  }, [getPromptById, getIndexDocuments_, id]);

  return (
    <>
      <CreateOrUpdateForm onSubmit={handleUpdate}>
        <Button
          className='mt-5'
          color='info'
          type='submit'
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <i className='fa fa-spinner fa-spin mr-2'></i>
              Updating...
            </>
          ) : (
            "Update Prompt"
          )}
        </Button>
      </CreateOrUpdateForm>
    </>
  );
};

export default UpdatePrompt;
