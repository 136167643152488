import { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { markdownToHtml, htmlToMarkdown } from "./Parser";
import * as Emoji from "quill-emoji";

import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import "./MDEditor.css";

Quill.register("modules/emoji", Emoji);

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "blockquote"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  ["emoji"],
];

interface MDEditorProps {
  value: string;
  onChange: (value: { html: string; markdown: string }) => void;
  unMount: () => void;
  placeholder?: string;
  className?: string;
}

const MDEditor: React.FC<MDEditorProps> = ({
  value,
  onChange,
  unMount,
  placeholder,
  className,
}) => {
  const [editorValue, setEditorValue] = useState<string | null>(value);

  const reactQuillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  useEffect(() => {
    return () => {
      if (!!unMount) {
        unMount();
        setEditorValue("");
      }
    };
  }, []);

  const closeEmojiPalette = () => {
    const $emojiPalette = document.querySelector(
      "#emoji-palette"
    ) as HTMLElement;
    if ($emojiPalette) {
      $emojiPalette.style.display = "none";
    }
  };

  const handleChange = (content: string) => {
    setEditorValue(content);
    onChange({
      html: content,
      markdown: htmlToMarkdown(content),
    });
  };

  return (
    <>
      <ReactQuill
        ref={reactQuillRef}
        theme='snow'
        className={className}
        placeholder={placeholder}
        modules={{
          toolbar: {
            container: TOOLBAR_OPTIONS,
          },
          "emoji-toolbar": true,
          "emoji-textarea": false,
          "emoji-shortname": true,
        }}
        onBlur={(e) => closeEmojiPalette()}
        value={editorValue as string}
        onChange={handleChange}
      />
    </>
  );
};

export default MDEditor;
