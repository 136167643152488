import { useEffect, useState } from "react";
import AxmosInput from "./Form/AxmosInput";
import { preDefinedModelParamsList } from "models/prompts";
import { ModelParams } from "models/NewModelParams.model";

interface Props {
  onChange?: (value: ModelParams) => void;
  disabled: boolean;
}

export const PreDefinedModelParams: React.FC<Props> = ({
  onChange,
  disabled,
}) => {
  const [modelParamsSelected, setModelParamsSelected] = useState<string>("");

  useEffect(() => {
    if (onChange) {
      onChange(JSON.parse(modelParamsSelected || "{}"));
    }
  }, [modelParamsSelected]);

  return (
    <>
      <AxmosInput
        id='preDefinedModelParams'
        name='preDefinedModelParams'
        type='select'
        label='Pre-defined model params'
        tooltipMessage='The prompt pre-defined model params'
        placeholder='Select a pre defined model params'
        className='cursor-pointer'
        onChange={(value) => setModelParamsSelected(value)}
        disabled={disabled}
        required={true}
      >
        {preDefinedModelParamsList.map((model, index) => (
          <option key={index} value={JSON.stringify(model.value)}>
            {model.label}
          </option>
        ))}
      </AxmosInput>
    </>
  );
};
