import { apiService } from "api";
import { AxiosResponse } from "axios";
import { ConsumerResquestParams } from "models";
import { ensureUpdateConsumer } from "./guards/ensure-update-consumer";

export const updateConsumer = async (
  consumerId: string,
  consumer: ConsumerResquestParams
) => {
  ensureUpdateConsumer(consumerId, consumer);
  return await apiService.put<AxiosResponse>(
    `/consumers/${consumerId}`,
    consumer
  );
};
