import { RouteObject } from "react-router-dom";
import { ChatV2 } from "views/chats/ChatV2";
import Chat_v1 from "views/chats/Chat_v1";

const routes: RouteObject[] = [
  { path: "chats/chat-v1", element: <Chat_v1 /> },
  { path: "chats/chat-v2", element: <ChatV2 /> },
];

export default routes;
