import { Button, Drawer, useMediaQuery, useTheme } from "@mui/material";
import AxmosInput from "components/Form/AxmosInput";
import "./AddIndexModal.css";

import { ListGroup, ListGroupItem } from "reactstrap";
import { SnackbarUtilitiesConfigurator } from "utils";
import { useFileHandler } from "views/prompts/hooks";
import { useIndexStore } from "store/embeddings/index-documents.store";
import { useFilesStore } from "store/embeddings/files.store";
import { useEmbeddingStore } from "store/embeddings/embedding.store";
import { useState } from "react"; // We added useState
import useSettingsStore from "store/embeddings/settings.store";
import { FilesTypesConst } from "consts/FilesTyps";

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const AddIndexModal: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const [indexName, setIndexName] = useIndexStore((state) => [
    state.indexName,
    state.setIndexName,
  ]);

  const getNotRemovedFiles = useFilesStore((state) => state.getNotRemovedFiles);

  const { removeFile, handleCloneInputClick, onFileChange, handleSave } =
    useFileHandler();

  const [purpose, setPurpose] = useEmbeddingStore((state) => [
    state.purpose,
    state.setPurpose,
  ]);

  // We added status for file type and new fields
  const [fileType, setFileType] = useState<string | null>(null);

  const { 
    textChunkSize, setTextChunkSize,
    chunkOverlap, setChunkOverlap,
    delimiter, setDelimiter,
    findTextJson, setFindTextJson
  } = useSettingsStore();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFileChange(e); // We call the existing function
    const file = e.target.files?.[0];
    if (file) {
      const extension = file.name.split(".").pop()?.toLowerCase();
      setFileType(extension || null); // We determine the file type
    }
  };

  return (
    <>
      <Drawer
        id='drawer'
        classes={{ paper: "drawer-paper", root: "drawer-root" }}
        sx={{
          "& .MuiPaper-root": {
            width: isMobile ? "100vw !important" : "auto",
          },
        }}
        anchor='right'
        open={props.isOpen}
        onClose={(event, reason: "escapeKeyDown") => {
          if (reason === "escapeKeyDown") {
            props.setIsOpen(false);
          }
        }}
      >
        <SnackbarUtilitiesConfigurator />
        <div className='p-2 w-100'>
          <div className='d-flex justify-content-end align-items-center mb-4 mr-2 mt-2'>
            <i
              onClick={() => props.setIsOpen(false)}
              title='Close sidebar'
              className='fa fa-close'
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <AxmosInput
            label='Documents store'
            id='documentsStore'
            type='text'
            placeholder='Type your documents store name here.'
            name='documentsStore'
            required={true}
            value={indexName}
            onInput={(value) => setIndexName(value)}
          />

          <AxmosInput
            label='Purpose'
            id='purpose'
            type='textarea'
            rows='2'
            placeholder='Type your purpose here.'
            name='purpose'
            value={purpose}
            onInput={(value) => setPurpose(value)}
          ></AxmosInput>

          <input
            onChange={(e) => handleFileChange(e)} // We modified the file change handler
            id='file-input'
            type='file'
            multiple
            className='d-none'
            accept={`.${FilesTypesConst.pdf}, .${FilesTypesConst.csv}, .${FilesTypesConst.json}, .${FilesTypesConst.jsonl}`}
          />

          {/* New conditional fields */}
          {fileType === FilesTypesConst.pdf && (
            <>
              <AxmosInput
                label='Text Chunk Size (for PDF)'
                id='textChunkSize'
                type='number'
                placeholder='Enter text chunk size'
                name='textChunkSize'
                required={true}
                value={textChunkSize}
                onInput={(value) => setTextChunkSize(parseInt(value, 10))}
              />
              <AxmosInput
                label='Chunk Overlap (for PDF)'
                id='chunkOverlap'
                type='number'
                placeholder='Enter chunk overlap'
                name='chunkOverlap'
                required={true}
                value={chunkOverlap}
                onInput={(value) => setChunkOverlap(parseInt(value, 10))}
              />
            </>
          )}

          {fileType === FilesTypesConst.csv && (
            <AxmosInput
              label='Delimiter (for CSV)'
              id='delimiter'
              type='text'
              placeholder='Enter delimiter'
              name='delimiter'
              required={true}
              value={delimiter}
              onInput={(value) => setDelimiter(value)}
            />
          )}

          {(fileType === FilesTypesConst.json || fileType === FilesTypesConst.jsonl) && (
            <AxmosInput
              label='JSON attribute for chunks (optional)'
              id='findTextJson'
              type='text'
              placeholder='Enter JSON attribute to be used for chunks'
              name='findTextJson'
              value={findTextJson}
              onInput={(value) => setFindTextJson(value)}
            />
          )}

          <div
            style={{ backgroundColor: "#f5f5f5" }}
            className="border rounded p-2 mt-2 form-contol text-xs"
          >
            <div
              onClick={() => handleCloneInputClick()}
              className={`${
                getNotRemovedFiles().length > 0 && "mb-2"
              } rounded input-file d-flex align-items-center justify-content-between p-2`}
            >
              <span>
                Upload your documents{" "}
                <span className="text-light text-xs">
                  ( click here to upload )
                </span>
              </span>{" "}
              <i className="fa fa-upload"></i>
            </div>
            <ListGroup>
              {getNotRemovedFiles().map((file, index) => (
                <ListGroupItem
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                >
                  <span className="text-truncate" title={file.name}>
                    <i className="fa fa-file mr-2"></i> {file.name}
                  </span>
                  <Button
                    color="error"
                    variant="text"
                    onClick={() => removeFile(file.name)}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              size="small"
              className="mt-3 mr-2 "
              variant="contained"
              color="inherit"
              onClick={() => props.setIsOpen(false)}
            >
              Close
            </Button>
            <Button
              onClick={handleSave}
              size="small"
              className="mt-3 "
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};
