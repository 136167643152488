import { useState } from "react";
import { useEmbeddingStore } from "store/embeddings/embedding.store";
import { useFilesStore } from "store/embeddings/files.store";
import {
  DocumentStatusEnum,
  useIndexStore,
} from "store/embeddings/index-documents.store";
import { usePromptsStore } from "store/prompts/prompts.store";
import Swal from "sweetalert2";
import { SnackbarManager } from "utils";
import { FilesTypesConst } from "consts/FilesTyps";

interface FileProps {
  name: string;
  file?: File;
  isNew?: boolean;
}

const allowFileTypes = [`.${FilesTypesConst.pdf}`, `.${FilesTypesConst.csv}`, `.${FilesTypesConst.json}`, `.${FilesTypesConst.jsonl}`];

export const useFileHandler = () => {
  const [indexName, setIndexName] = useState<string>();

  const [files, setFiles, changeFileStatus] = useFilesStore((state) => [
    state.files,
    state.setFiles,
    state.changeFileStatus,
  ]);

  const [purpose, setPurpose] = useEmbeddingStore((state) => [
    state.purpose,
    state.setPurpose,
  ]);

  const [setIndexDocuments, indexDocuments, changeDocumentStatus, index] =
    useIndexStore((state) => [
      state.setDocuments,
      state.documents,
      state.changeDocumentStatus,
      state.indexName,
    ]);

  const [setPrompt, prompt] = usePromptsStore((state) => [
    state.setPrompt,
    state.prompt,
  ]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newFiles = Array.from(e.target.files || []).map((file) => ({
      name: file.name,
      file,
      isNew: true,
      status: DocumentStatusEnum.ok,
    }));

    const isFileUploaded = newFiles.every((file) =>
      files.some(
        (f) => f.name === file.name && f.status === DocumentStatusEnum.ok
      )
    );

    const isAllowed = newFiles.every((file) =>
      allowFileTypes!.some((type) => file.name.toLowerCase().endsWith(type)) //Validate file extension with a regular expression for greater accuracy
    );

    if (!isAllowed) {
      SnackbarManager.warning("Only pdf, csv, json, and jsonl files are allowed");
      return;
    }
    if (isFileUploaded) {
      SnackbarManager.warning(
        "File already uploaded, please verify your files."
      );
      return;
    }
    setFiles([...files, ...newFiles]);
  };

  const removeFile = (name: string, index?: number) => {
    Swal.fire({
      title: "Are you sure to delete this file?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container: "swal-container",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        changeDocumentStatus(name, DocumentStatusEnum.removed);
        changeFileStatus(name, DocumentStatusEnum.removed);

        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const handleCloneInputClick = (DOMId: string = "file-input") => {
    const $fileInput = document.getElementById(DOMId) as HTMLInputElement;

    new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    $fileInput.click();
  };

  const handleSave = () => {
    if (!index) return SnackbarManager.warning("Please type the index name.");
    if (!purpose)
      return SnackbarManager.warning("Please type the index purpose.");
    if (files.length === 0)
      return SnackbarManager.warning("Please upload files.");
    setFiles([...files]);
    setIndexDocuments(files);
    setPurpose(purpose as string);
    setIndexName(index);
    setPrompt({ ...prompt, indexName: index });
    SnackbarManager.success("Your index is saved successfully.");
  };

  return {
    onFileChange,
    files,
    removeFile,
    setFiles,
    handleCloneInputClick,
    indexName,
    setIndexName,
    handleSave,
  };
};
