import { remark } from "remark";
import remarkHtml from "remark-html";

import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";

export function markdownToHtml(markdownText) {
  const file = remark().use(remarkHtml).processSync(markdownText);
  return file.toString();
}



function escapeHtml(html) {
  return html.replace(/</g, "&lt;").replace(/>/g, "&gt;");
}

export async function convertMarkdownToHtml(markdown){
    try {
      const newMd = escapeHtml(markdown)
      const result = await remark().use(remarkHtml).process(newMd);
      return result.toString()
    } catch (error) {
        console.error("Error converting markdown to HTML:", error);
    }
}

export function htmlToMarkdown(htmlText) {
  const file = remark()
    .use(rehypeParse, { emitParseErrors: true, duplicateAttribute: false })
    .use(rehypeRemark)
    .use(remarkStringify)
    .processSync(htmlText);

  return file.toString();
}
