import CreateOrUpdateForm from "../CreareOrUpdateForm";
import {
  promptInitialState,
  usePromptsStore,
} from "store/prompts/prompts.store";
import { useEffect, useState } from "react";
import { SnackbarManager, SnackbarUtilitiesConfigurator } from "utils";
import { PromptUpdateAndCreate } from "models";
import { useEmbeddingStore } from "store/embeddings/embedding.store";
import { Button } from "reactstrap";
import { useIntegrationsStore } from "store/integrations/integrations.store";
import { useIndexStore } from "store/embeddings/index-documents.store";
import { useFilesStore } from "store/embeddings/files.store";
import useSettingsStore from "store/embeddings/settings.store";

interface Props {
  promptCreated?: () => void;
}

export const CreatePrompt: React.FC<Props> = ({ promptCreated }) => {
  const [setPrompt, createPrompt, placeholderList, setPlaceholderList] =
    usePromptsStore((state) => [
      state.setPrompt,
      state.createPrompt,
      state.placeholdersList,
      state.setPlaceholdersList,
    ]);

  const [documents, uploadFiles, setPurpose] = useEmbeddingStore((state) => [
    state.documents,
    state.uploadFiles,
    state.setPurpose,
  ]);

  const [indexDocuments, setDocuments, indexName, setIndexName] = useIndexStore(
    (state) => [
      state.getNotRemovedDocuments,
      state.setDocuments,
      state.indexName,
      state.setIndexName,
    ]
  );

  const { textChunkSize, chunkOverlap, delimiter, findTextJson } = useSettingsStore();

  const setFiles = useFilesStore((state) => state.setFiles);

  const [createIntegration, getNewIntegrations] = useIntegrationsStore(
    (state) => [state.createIntegration, state.getNewIntegrations]
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setPrompt(promptInitialState);
    setFiles([]);
    setDocuments([]);
    setPurpose("");
    setIndexName("");
  }, [setPrompt]);

  const handleCreatePrompt = async (prompt: PromptUpdateAndCreate) => {
    setIsLoading(true);

    const placeholders = placeholderList.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {});

    const newIntegrations = getNewIntegrations();

    try {
      if (indexDocuments().length > 0)
        await uploadFiles({ indexName, files: indexDocuments() }, indexName,
        { textChunkSize, chunkOverlap, delimiter, findTextJson }
    );

      if (newIntegrations.length > 0) {
        const promises = newIntegrations.map((integration) => {
          return createIntegration({ ...integration });
        });
        await Promise.all(promises);
      }
      await createPrompt({
        ...prompt,
        indexName,
        placeholders,
      });
      SnackbarManager.success("Prompt created successfully");
      setPrompt(promptInitialState);
      setPlaceholderList([]);
      setDocuments([]);
      setPurpose("");
      setFiles([]);
      setIndexName("");
      if (promptCreated) promptCreated();
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SnackbarUtilitiesConfigurator />
      <CreateOrUpdateForm
        onSubmit={(prompt: PromptUpdateAndCreate) => handleCreatePrompt(prompt)}
      >
        <Button
          type='submit'
          color='primary'
          className='mt-5'
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <i className='fa fa-spinner fa-spin mr-2'></i>
              Creating...
            </>
          ) : (
            <>Create Prompt</>
          )}
        </Button>
      </CreateOrUpdateForm>
    </>
  );
};
