import {
  Button,
  Chip,
  Collapse,
  Divider,
  Drawer,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import { Integration, initialIntegrationState } from "models";
import { useEffect } from "react";
import { useIntegrationsStore } from "store/integrations/integrations.store";
import { v4 as uuid } from "uuid";
import { IntegrationAccordion } from "./IntegrationAccordion";
import "./JsonViewer.css";
import { useDrawerStore } from "store/integrations/integration-drawer.store";
import { usePromptsStore } from "store/prompts/prompts.store";

interface Props {
  promptIntegrations: Integration[];
  isOpen: boolean;
  openModal?: () => void;
  closeModal: () => void;
}

const AddIntegrationsModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const [setExpanded, expanded] = useDrawerStore((state) => [
    state.setExpanded,
    state.expanded,
  ]);
  const [getAllIntegrations, integrations, addNew, setIntegration] =
    useIntegrationsStore((state) => [
      state.getAllIntegrations,
      state.integrations,
      state.addNewIntegration,
      state.setIntegrations,
    ]);

  const prompt = usePromptsStore((state) => state.prompt);

  const addNewIntegration = () => {
    const integrationId = uuid();
    addNew({
      ...initialIntegrationState,
      integrationId,
      isNew: true,
      route: "/some-route",
      isOnPrompt: false,
    });
    setExpanded(`panel-${integrationId}`);
  };

  const hasNewIntegrations = (): boolean => {
    return integrations.filter((i) => i.isNew).length > 0;
  };

  const sortedByIsAddedIntegrations = () => {
    return integrations
      .map((integration) => {
        return {
          ...integration,
          isOnPrompt: prompt.integrations.some(
            (pi) => pi.integrationId === integration.integrationId
          ),
        };
      })
      .sort((a, b) => (a.isOnPrompt ? -1 : 1));
  };

  useEffect(() => {
    getAllIntegrations();
  }, []);

  return (
    <>
      <Drawer
        anchor='right'
        sx={{
          "& .MuiPaper-root": {
            width: isMobile ? "100vw !important" : "auto",
          },
        }}
        open={isOpen}
        onClose={(event, reason: "escapeKeyDown") => {
          if (reason === "escapeKeyDown") {
            closeModal();
          }
        }}
      >
        <div className='p-2'>
          <div className='d-flex justify-content-between flex-wrap align-items-center mb-4 mr-2 mt-2'>
            <Button
              variant='contained'
              color='primary'
              style={{ fontSize: "12px" }}
              onClick={() => addNewIntegration()}
            >
              Create new integration
            </Button>

            <i
              onClick={closeModal}
              title='Close sidebar'
              className='fa fa-close'
              style={{ cursor: "pointer" }}
            ></i>
          </div>

          {hasNewIntegrations() && (
            <>
              <Divider variant='middle' className='my-2 text-xs'>
                <Chip
                  variant='filled'
                  size='small'
                  className='text-xs'
                  label='New integrations'
                ></Chip>
              </Divider>
            </>
          )}
          <TransitionGroup>
            {integrations
              .filter((i) => i.isNew)
              .map((integration, index) => {
                return (
                  <Collapse
                    key={integration.integrationId}
                    mountOnEnter
                    unmountOnExit
                  >
                    <IntegrationAccordion
                      integration={integration}
                      index={index}
                    />
                  </Collapse>
                );
              })}
          </TransitionGroup>
          <Divider variant='middle' className='my-2 cursor-pointer'>
            <Tooltip
              title='You can add/update pre-defined integrations here.'
              placement='top'
            >
              <Chip
                variant='filled'
                size='small'
                className='text-xs'
                label='Pre-defined integrations'
              ></Chip>
            </Tooltip>
          </Divider>
          {sortedByIsAddedIntegrations()
            .filter((i) => !i.isNew)
            .map((integration, index) => {
              return (
                <div key={integration.integrationId}>
                  <IntegrationAccordion
                    integration={integration}
                    index={index}
                  />
                </div>
              );
            })}
        </div>
      </Drawer>
    </>
  );
};

export default AddIntegrationsModal;
