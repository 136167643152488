import { createTheme } from "@mui/material";
import { Colors } from "consts/Colors.const";

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: "#fff",
    },
    secondary: {
      main: Colors.secondary,
      contrastText: "#fff",
    },
  },
});
