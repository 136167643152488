import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Stack,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const cardData = [
  {
    title: "Manage Your AI Agents",
    description: "Create, customize, and select your AI agents for chat.",
  },
  {
    title: "Upload Files to Chat",
    description:
      "Add documents, images, videos, or audios to enhance your chat.",
  },
  {
    title: "Create Custom AI Agents",
    description: "Build your own AI chatbot or personalized prompts.",
  },
];

const cardStyle = {
  width: "100%",
  gap: "10px",
};

export const ChatCards: React.FC = () => {
  return (
    <div
      style={cardStyle}
      className='d-flex justify-content-center align-items-center flex-wrap'
    >
      {cardData.map((card, index) => (
        <Card
          elevation={0}
          sx={{
            bgcolor: "transparent",
            border: "1px solid #f0f0f0",
            borderRadius: 4,
            maxWidth: "300px",
            height: "170px",
          }}
        >
          <CardContent>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Avatar color='primary' sx={{ bgcolor: "#0473bb", mb: 2 }}>
                <FormatListBulletedIcon />
              </Avatar>
              <Typography
                variant='subtitle1'
                textAlign='center'
                component='div'
                gutterBottom
              >
                {card.title}
              </Typography>
            </Stack>

            <Typography
              sx={{ p: 2 }}
              variant='subtitle2'
              color='text.secondary'
              textAlign='center'
            >
              {card.description}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};
