import React, { useState } from "react";
import { Drawer } from "@mui/material";
import { ChatBoxModelParams } from "views/chats/chat-v2-components/chat-box/ChatBoxModelParams";
import { ConsumerOption } from "views/chats/chat-v2-components/chat-box/ConsumerOption";

import { useLocation, Outlet } from "react-router-dom";

import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "./sideBarList";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import {
  Stack, 
  Typography
} from "@mui/material";
import { Colors } from "consts/Colors.const";
import { useChatStore } from "store/chat/chat.store";

function Admin() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const location = useLocation();
  const [pathNames, setPathNames] = React.useState([]);
  const isOnChatV2 = location.pathname.includes("chats/chat-v2");
  const mainContentRef = React.useRef(null);

  React.useEffect(() => {
    setPathNames(
      location.pathname
        .split("/")
        .filter((p) => p !== "")
        .map((p) => p.charAt(0).toUpperCase() + p.slice(1))
    );
  }, [location]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  }
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  const selectedConsumer = useChatStore((state) => state.consumerSelected);

  const handleConsumerChange = (value) => {
    const setConsumerSelected = useChatStore.getState().setConsumerSelected;
    setConsumerSelected(JSON.parse(value));
  };

  return (
    <>
      <AdminNavbar toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} toggleSettings={toggleSettings}/>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/admin/dashboard",
          imgSrc: require("assets/img/brand/blue.png"),
          imgAlt: "...",
        }}
      />
      <div className='main-content' ref={mainContentRef}>
        <Outlet />
        {isOnChatV2 ? null : <AdminFooter />}
      </div>
      <Drawer
        anchor="right"
        open={settingsOpen}
        onClose={toggleSettings}
        sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "300px",
            overflowY: 'auto', 
        }}
      >
            <Stack
              sx={{
                minWidth: "100%",
                p: 1,
                bgcolor: `${Colors.primary}`,
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
              }}
              direction='row'
              alignItems='center'
            >
              <Typography color='white' variant='subtitle1' align='left'>
                Chat with:
              </Typography>
            </Stack>
            <div className='p-2' style={{width: "100%"}}>
              <ConsumerOption
                consumer={selectedConsumer}
                onChange={handleConsumerChange}
              />
            </div>
          <div className='mt-1' style={{width: "100%"}}>
            <ChatBoxModelParams/>
          </div>
      </Drawer>
      {sidenavOpen ? (
        <div className='backdrop d-xl-none' onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default Admin;