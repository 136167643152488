import CreateConsumer from "views/consumers/create/CreateConsumer";
import ConsumerViewAll from "views/consumers/view-all/ConsumersViewAll";
import EditConsumer from "views/consumers/edit/EditConsumer";

import { RouteObject } from "react-router-dom";

const routes: RouteObject[] = [
  { path: "agents/create", element: <CreateConsumer /> },
  { path: "agents/view-all", element: <ConsumerViewAll /> },
  { path: "agents/edit/:id", element: <EditConsumer /> },
];

export default routes;
