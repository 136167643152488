import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import { Integration } from "models";
import { useState } from "react";
import { useIntegrationsStore } from "store/integrations/integrations.store";
import { usePromptsStore } from "store/prompts/prompts.store";
import { useIntegration } from "./integrations.hooks";
import { SnackbarManager } from "utils";
import { Col, Container, Fade, Row } from "reactstrap";
import AxmosInput from "components/Form/AxmosInput";
import { JsonEditor } from "json-edit-react";
import { MethodEnum } from "enums";
import { useDrawerStore } from "store/integrations/integration-drawer.store";

const DownIcon: React.FC = () => <i className='fa fa-chevron-down'></i>;

export const IntegrationAccordion: React.FC<{
  integration: Integration;
  index: number;
}> = ({ integration, index }) => {
  const [showAdvancedOptions, setShowAdvancedOptions] =
    useState<boolean>(false);

  const { ensureAddIntegration } = useIntegration();
  const [setExpanded, expanded] = useDrawerStore((state) => [
    state.setExpanded,
    state.expanded,
  ]);

  const [prompt, setPrompt] = usePromptsStore((state) => [
    state.prompt,
    state.setPrompt,
  ]);

  const [integrations, setIntegration, deleteIntegration] =
    useIntegrationsStore((state) => [
      state.integrations,
      state.setIntegrations,
      state.deleteIntegration,
    ]);

  const isIntegrationAdded = (integration: Integration): boolean => {
    const promptHasIntegration = prompt.integrations
      ? prompt.integrations.some(
          (pi) => pi.integrationId === integration.integrationId
        )
      : false;

    return promptHasIntegration;
  };

  const handleSave = (integration: Integration) => {
    if (ensureAddIntegration(integration)) return;
    setPrompt({
      ...prompt,
      integrations: [
        ...prompt.integrations.filter(
          (pi) => pi.integrationId !== integration.integrationId
        ),
        integration,
      ],
    });
    SnackbarManager.success("Integration added to prompt successfully.");
  };

  const handleAdd = (index: number, value: any) => {
    setIntegration(index, value);
  };

  const removePromptIntegration = (integrationId: string) => {
    setPrompt({
      ...prompt,
      integrations: [
        ...prompt.integrations.filter(
          (pi) => pi.integrationId !== integrationId
        ),
      ],
    });
  };

  const handleRemovePromptIntegration = (integration: Integration) => {
    removePromptIntegration(integration.integrationId);
    SnackbarManager.info("Integration has been removed from the prompt.");
  };

  const handleRemoveIntegration = (index: number) => {
    deleteIntegration(index);
    //setHistory([...history, integrations[index]]);
    removePromptIntegration(integrations[index].integrationId);
    SnackbarManager.info("Integration deleted.");
  };

  const handleUpdateIntegration = (integration: Integration) => {
    setPrompt({
      ...prompt,
      integrations: [
        ...prompt.integrations.filter(
          (pi) => pi.integrationId !== integration.integrationId
        ),
        integration,
      ],
    });

    SnackbarManager.success(
      `Integration "${integration.route}" updated successfully.`
    );
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      setShowAdvancedOptions(false);
    };

  return (
    <>
      <Accordion
        slotProps={{ transition: { unmountOnExit: true } }}
        expanded={expanded === `panel-${integration.integrationId}`}
        onChange={handleChange(`panel-${integration.integrationId}`)}
        className='mb-1'
        style={{
          minWidth: "100%",
        }}
        variant='outlined'
        key={integration.integrationId}
      >
        <AccordionSummary
          expandIcon={<DownIcon></DownIcon>}
          aria-controls='panel1-content'
          id={`panel-${integration.integrationId}`}
          className='text-sm font-weight-bold text-uppercase bg-secondary'
        >
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div>
              <Tooltip
                title={
                  isIntegrationAdded(integration)
                    ? "Integration added"
                    : "Not added yet"
                }
                placement='top'
              >
                <small className='mr-2 text-success font-weight-normal'>
                  {isIntegrationAdded(integration) ? (
                    <i
                      className='fa fa-check-circle text-success'
                      style={{ fontSize: "14px" }}
                    ></i>
                  ) : (
                    <small className='mr-2 w-100 text-gray font-weight-normal'>
                      <i>Not added yet</i>
                    </small>
                  )}
                </small>
              </Tooltip>
              <span className='text-xs font-weight-bold'>
                {integration.route || ""}
              </span>
            </div>

            <div
              className='d-flex mx-2'
              style={{ gap: "3px" }}
              onClick={(event) => event.stopPropagation()}
            >
              {isIntegrationAdded(integration) ? (
                <>
                  <Button
                    title='Remove integration from the prompt'
                    variant='text'
                    color='error'
                    size='small'
                    style={{ fontSize: "11px" }}
                    onClick={() => handleRemovePromptIntegration(integration)}
                  >
                    Remove
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant='text'
                    size='small'
                    color='warning'
                    style={{ fontSize: "11px" }}
                    onClick={() => handleSave(integration)}
                  >
                    Add to prompt
                  </Button>
                </>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <section>
            <Row>
              <Col>
                <AxmosInput
                  tooltipMessage='The API base URL: (https://example...)'
                  id='baseUrl'
                  required={true}
                  label='Base URL'
                  type='text'
                  name='baseUrl'
                  placeholder='Base URL'
                  value={integration.baseUrl || ""}
                  onInput={(value) =>
                    handleAdd(index, {
                      ...integration,
                      baseUrl: value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <AxmosInput
                  tooltipMessage='The API path'
                  required={true}
                  id='route'
                  label='Route'
                  type='text'
                  name='route'
                  placeholder='Route'
                  value={integration.route || ""}
                  onInput={(value) =>
                    handleAdd(index, { ...integration, route: value })
                  }
                />
              </Col>

              <Col>
                <AxmosInput
                  tooltipMessage='The API method'
                  id='method'
                  required={true}
                  label='Method'
                  type='select'
                  style={{ cursor: "pointer" }}
                  name='method'
                  value={integration.method || ""}
                  onInput={(value) =>
                    handleAdd(index, {
                      ...integration,
                      method: value,
                    })
                  }
                >
                  <option value='GET'>GET</option>
                  <option value='POST'>POST</option>
                </AxmosInput>
              </Col>

              <Col>
                <AxmosInput
                  tooltipMessage='Attribute name to extract from the API response'
                  id='result'
                  label='Result'
                  type='text'
                  name='result'
                  placeholder='Type attribute name'
                  value={integration.result || ""}
                  onInput={(value) =>
                    handleAdd(index, {
                      ...integration,
                      result: value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <AxmosInput
                  tooltipMessage='The integration purpose'
                  id='purpose'
                  label='Purpose'
                  type='textarea'
                  rows='3'
                  name='purpose'
                  placeholder='Type integration purpose.'
                  value={integration.purpose || ""}
                  onInput={(value) =>
                    handleAdd(index, { ...integration, purpose: value })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <div
                  className='d-flex align-items-center mb-4'
                  style={{ gap: "3px" }}
                >
                  <span className='text-sm'>Cacheable</span>
                  <Switch
                    value={integration.cacheable || false}
                    onChange={(evt) =>
                      handleAdd(index, {
                        ...integration,
                        cacheable: evt.target.checked,
                      })
                    }
                  ></Switch>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <JsonEditor
                  collapse={true}
                  rootName='headers'
                  enableClipboard={false}
                  data={integration.headers || {}}
                  onUpdate={(e) => {
                    handleAdd(index, {
                      ...integration,
                      headers: e.newData,
                    });
                  }}
                ></JsonEditor>
              </Col>
            </Row>

            <Row className='my-2'>
              <Col>
                <JsonEditor
                  collapse={true}
                  rootName='params'
                  enableClipboard={false}
                  data={integration.params || {}}
                  onUpdate={(e) => {
                    handleAdd(index, {
                      ...integration,
                      params: e.newData,
                    });
                  }}
                ></JsonEditor>
              </Col>
            </Row>
            {integration.method === MethodEnum.post && (
              <Fade>
                <Row>
                  <Col>
                    <JsonEditor
                      collapse={true}
                      rootName='body'
                      enableClipboard={false}
                      data={integration.json || {}}
                      onUpdate={(e) => {
                        handleAdd(index, {
                          ...integration,
                          json: e.newData,
                        });
                      }}
                    ></JsonEditor>
                  </Col>
                </Row>
              </Fade>
            )}

            <Row>
              <Col>
                <Button
                  onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                  size='small'
                  className='mt-4'
                  variant='text'
                  color='primary'
                >
                  <Stack
                    direction='row'
                    spacing={1}
                    alignItems='center'
                    style={{ fontSize: "11px" }}
                  >
                    <i
                      className={`fa ${
                        showAdvancedOptions
                          ? "fa-chevron-down"
                          : "fa-chevron-right"
                      } text-xs mr-2`}
                      style={{ marginTop: "-1px" }}
                    ></i>
                    {showAdvancedOptions
                      ? "Hide advanced options"
                      : "Show advanced options"}
                  </Stack>
                </Button>
              </Col>
            </Row>
            {showAdvancedOptions && (
              <Fade tag='div'>
                <Container className='border rounded py-2 my-2'>
                  <Row>
                    <Col>
                      <AxmosInput
                        tooltipMessage='The auth type: (Bearer)'
                        id='authType'
                        label='Auth type'
                        type='select'
                        style={{ cursor: "pointer" }}
                        name='authType'
                        placeholder='Auth type'
                        value={integration.authType || ""}
                        onInput={(value) =>
                          handleAdd(index, {
                            ...integration,
                            authType: value,
                          })
                        }
                      >
                        <>
                          <option value=''>None</option>
                          <option value='Bearer'>Bearer</option>
                        </>
                      </AxmosInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AxmosInput
                        tooltipMessage='The API URL to obtain the auth token'
                        name='authUrl'
                        label='Auth URL'
                        type='text'
                        id='authUrl'
                        value={integration.authUrl || ""}
                        placeholder='Type your auth URL here.'
                        onInput={(value) =>
                          handleAdd(index, {
                            ...integration,
                            authUrl: value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AxmosInput
                        tooltipMessage='The location where the auth token is send in the API request'
                        id='tokenLocation'
                        label='Token location'
                        type='select'
                        name='tokenLocation'
                        style={{ cursor: "pointer" }}
                        placeholder='Token location'
                        value={integration.tokenLocation || ""}
                        onInput={(value) =>
                          handleAdd(index, {
                            ...integration,
                            tokenLocation: value,
                          })
                        }
                      >
                        <>
                          <option value=''>None</option>
                          <option value='params'>Params</option>
                          <option value='headers'>Headers</option>
                          <option value='body'>Body</option>
                        </>
                      </AxmosInput>
                    </Col>

                    <Col>
                      <AxmosInput
                        tooltipMessage='The attribute name where the token is present on the Auth API response'
                        id='token'
                        label='Token attribute'
                        type='text'
                        name='token'
                        placeholder='Token'
                        value={integration.token || ""}
                        onInput={(value) =>
                          handleAdd(index, {
                            ...integration,
                            token: value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  {integration.isNew && (
                    <Row>
                      <Col>
                        <JsonEditor
                          collapse={true}
                          rootName='authBody'
                          enableClipboard={false}
                          data={integration.authBody || {}}
                          onUpdate={(e) => {
                            handleAdd(index, {
                              ...integration,
                              authBody: e.newData,
                            });
                          }}
                        ></JsonEditor>
                      </Col>
                    </Row>
                  )}
                </Container>
              </Fade>
            )}
          </section>
        </AccordionDetails>
        <AccordionActions>
          {integration.isNew && (
            <Button
              style={{ fontSize: "11px" }}
              variant='text'
              color='error'
              size='small'
              onClick={() => handleRemoveIntegration(index)}
            >
              Remove
            </Button>
          )}
          {isIntegrationAdded(integration) && (
            <Button
              style={{ fontSize: "11px" }}
              variant='contained'
              color='primary'
              size='small'
              onClick={() => handleUpdateIntegration(integration)}
            >
              Update integration
            </Button>
          )}
        </AccordionActions>
      </Accordion>
    </>
  );
};
