import { PromptUpdateAndCreate } from "models";

export const ensurePrompt = (prompt: PromptUpdateAndCreate) => {
  if (!prompt.name) {
    throw new Error("Name is required");
  }

  if (!prompt.description) {
    throw new Error("Description is required");
  }

  if (!prompt.content.prompt) {
    throw new Error("Prompt is required");
  }
};
