import { Box, Paper, styled } from "@mui/material";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  paddingInline: "2rem",
  borderRadius: "0",
  minWidth: "100%",
  borderRight: "1px solid #ddd",
  minHeight: "100%",
  background: "#fff",
  display: "flex",
  flexDirection: "column",
  height: "auto",
}));

export const ChatArea = styled(Box)({
  flexGrow: 1,
  overflowY: "auto",
  marginBottom: "1rem",
  maxHeight: "70vh",
});

export const InputArea = styled(Box)({
  marginTop: "auto",
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  position: "relative",
  height: "auto",
});
