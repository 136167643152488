import InputBase from "@mui/material/InputBase";
import React, { useEffect, useState, useCallback } from "react";
import AxmosInput from "components/Form/AxmosInput";
import { Response } from "models";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Fade,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  Table,
  Button,
  UncontrolledCollapse,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { PromptModalDetail } from "views/prompts/view-all/PromptModalDetail";
import { useConsumerStore } from "store/consumers/consumer.store";
import "./Chat_v1.css";
import { SnackbarManager, SnackbarUtilitiesConfigurator } from "utils";
import AttachmentCard from "./components/AttachmentCard";
import SimpleHeader from "components/Headers/SimpleHeader";
import { getPromptById } from "services/prompts/get-by-id";

import { AddAttachmentModal } from "./components/attachment-dad-input/AddAttachmentModal";
import { Setting } from "./components/settings/Setting";
import { maxHeight, SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

import { env } from "env";

const baseURl = env.API_BASE_URL;
const apiKey = env.API_KEY;

interface attachment {
  name: string;
  fileExtension: string;
  url?: string | null;
  blob?: any | null;
  mimeType: string;
}

interface IMessage {
  text: string;
  attachment?: Array<attachment> | null;
  sender: string;
}

interface IDebuggerDetails {
  languageId: string;
  consumerId: string;
  promptId: string;
  modelParams: modelParams_;
  question: string;
  session: string;
  indexName: string;
  created: string;
  answer: string;
  docs: Array<{
    text: string;
    source: string;
  }>;
  integrationsData: Array<any>;
  prompt_token_count: number;
  candidates_token_count: number;
  llm_time_taken: number;
  integrations_time_taken: number;
}

interface IDebuggerRows {
  position: number;
  consumerName: string;
  message: string;
  totalTime: number;
  details: IDebuggerDetails;
  modelParams: any;
  prompt: any;
  totalTokens: number;
}

interface modelParams_ {
  model?: string;
  temperature: number;
  maxOutputTokens: number | string;
  topP: number;
  topK: number;
  response_mime_type?: string;
}

interface keyValueData {
  [key: string]: string;
}

let session = sessionStorage.getItem("sessionGet");

let flagConsumer = false;

const Chat_v1: React.FC = () => {
  var files: File[];

  const FileTypes = [".pdf", ".csv", ".json"];

  const imgFileTypes = [".png", ".jpeg"];

  const videoFileTypes = [
    ".x-flv",
    ".mov",
    ".mpeg",
    ".mpegps",
    ".mpg",
    ".mp4",
    ".webm",
    ".wmv",
    ".3gpp",
  ];

  const audioFileTypes = [
    ".aac",
    ".flac",
    ".mp3",
    ".m4a",
    ".mpeg",
    ".mpga",
    ".mp4",
    ".opus",
    ".pcm",
    ".wav",
    ".webm",
  ];

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const [attachmentModal, setAttachmentModal] = useState(false);
  const toggleModal = () => {
    setAttachmentModal(true);
  };

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [debuggerRows, setDebuggerRows] = useState<IDebuggerRows[]>([]);
  const [timestamp, setTimestamp] = useState<Date>(new Date());
  const [inputValue, setInputValue] = useState("");
  const [session, setSession] = useState("");
  const [showDrad, setShowDrag] = useState(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [stepName, setStepName] = useState<string>("");
  const [allowedFiles, setAllowedFiles] = useState<Array<string>>([]);
  const [showRecording, setShowRecording] = useState<boolean>(false);
  const [uploadTitle, setUploadTitle] = useState<string>("Upload files");
  const [consumers, getAllConsumers] = useConsumerStore((state) => [
    state.consumers,
    state.getAllConsumers,
  ]);
  const [consumerSelected, setConsumerSelected] = useState<Response>(
    consumers[0]
  );
  const consumer = consumerSelected || consumers[0];
  const [isOpenIndexDrawer, setIsOpenIndexDrawer] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<any>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [openPromptModal, setOpenPromptModal] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<any>();
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const $cardBody = document.querySelector(".card-body") as HTMLElement;
  const $inputChat = document.querySelector("#input-chat") as HTMLInputElement;
  const newDebugLine = (newLine: any, consumer: any, prompt: any) => {
    const newDebugLine: IDebuggerRows = {
      consumerName: consumer?.botHumanName,
      message: newLine?.question,
      totalTime: Math.round((newLine?.total_time_taken || 0) * 1000) / 1000,
      totalTokens: newLine?.total_token_count,
      position: debuggerRows?.length + 1,
      details: newLine,
      modelParams: newLine?.modelParams,
      prompt,
    };

    const rows = debuggerRows;
    rows.push(newDebugLine);
    setDebuggerRows(rows);
  };

  const [modelParams_, setModelParams_] = useState<modelParams_>({
    model: consumerSelected?.modelParams?.model,
    temperature: consumerSelected?.modelParams?.temperature,
    maxOutputTokens: consumerSelected?.modelParams?.maxOutputTokens,
    topP: consumerSelected?.modelParams?.topP,
    topK: consumerSelected?.modelParams?.topK,
    response_mime_type: consumerSelected?.modelParams?.response_mime_type,
  });

  if (consumer != undefined && flagConsumer == false) {
    setConsumerSelected(consumer);
    setModelParams_(consumer.modelParams);
    setMessages([]);
    const newMessage: IMessage = {
      sender: consumer.botHumanName,
      text: consumer.welcomeMessage,
    };

    setMessages([newMessage]);
    //newSession(consumer.botHumanName);
    //$cardBody.scrollTop = $cardBody.scrollHeight;
    flagConsumer = true;
  }

  const [keyValueData, setKeyValueData] = useState<keyValueData>({});

  let chunks: any = [];

  const stopRecording = () => {
    mediaRecorder.stop();
    setMediaRecorder(null);
    setIsRecording(false);
  };
  const startRecording = () => {
    if (!mediaRecorder) {
      setIsRecording(true);
      let onSuccess = function (stream: any) {
        debugger;
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.onstop = function (e) {
          debugger;
        };
        mediaRecorder.ondataavailable = (e: any) => {
          debugger;
          const mediaRecorder = e.currentTarget;
          chunks.push(e.data);
          try {
            const mediaBlob = new Blob(chunks, {
              type: mediaRecorder.mimeType,
            });
            chunks = [];
            const mediaBlobUrl = window.URL.createObjectURL(mediaBlob);
            mediaRecorder.stream.getAudioTracks()[0].stop();
            const audioContainer = document.getElementById("audio-container");

            debugger;
            const audioElement = document.createElement("audio");
            audioElement.controls = true;
            audioElement.src = mediaBlobUrl;
            audioContainer?.appendChild(audioElement);
          } catch (ex) {}
        };
        setMediaRecorder(mediaRecorder);
      };
      let onError = function (err: any) {
        console.log("The following error occured: " + err);
      };
      debugger;
      if (navigator.mediaDevices.getUserMedia) {
        debugger;
        const constraints = { audio: true };
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(onSuccess, onError);
      } else {
        console.log(
          "MediaDevices.getUserMedia() not supported on your browser!"
        );
      }
    }
  };

  const updateModelParams = (newData: modelParams_) => {
    setModelParams_(newData);
  };

  const updatePlaceholder = (newData_: keyValueData) => {
    setKeyValueData(newData_);
  };

  const sendMessage = async () => {
    const consumer = consumerSelected || consumers[0];
    setTimestamp(new Date());
    if (inputValue.trim() === "" || inputValue.trim() === " ") return;

    var newMessage: IMessage = {
      text: "",
      sender: "",
    };

    if (selectedFiles?.length > 0) {
      const attachments: Array<attachment> = [];
      var messageFile = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        messageFile.push(selectedFiles[i]["name"]);
        attachments.push({
          name: selectedFiles[i]["name"],
          fileExtension: selectedFiles[i]["type"].split("/")[1],
          mimeType: selectedFiles[i]["type"],
          blob: selectedFiles[i],
          url: null,
        });
      }
      newMessage = {
        text: inputValue,
        attachment: attachments,
        sender: "User",
      };
    } else {
      newMessage = {
        text: inputValue,
        attachment: null,
        sender: "User",
      };
    }

    setInputValue("");
    setMessages([...messages, newMessage]);

    setIsTyping(true);
    $cardBody.scrollTop = $cardBody.scrollHeight;

    if (selectedFiles?.length > 0) {
      const formData = new FormData();
      formData.append("folder", "navaja_");
      for (let f of selectedFiles) {
        formData.append("document", f);
      }

      try {
        setStepName("is Uploading documents ...");
        fetch(`${baseURl}/attachments/?key=${apiKey}`, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            if (!response.ok) {
              SnackbarManager.error(
                "An error occurred while processing your request."
              );

              setIsTyping(false);
              return;
            }
            return response.json();
          })
          .then(async (res) => {
            let countFile = 0;
            if (res.data["documents"] != null) {
              countFile = res.data["documents"].length;
              master(newMessage, res.data["documents"], consumer);
            } else {
              setIsTyping(false);
            }
            setSelectedFiles([]);

            $inputChat.focus();
            $cardBody.scrollTop = $cardBody.scrollHeight;
          });
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
    } else {
      master(newMessage, null, consumer);
    }

    setAttachmentModal(false);
  };

  const attachImage = () => {
    setUploadTitle("Upload Images");
    setAllowedFiles(imgFileTypes);
    toggleModal();
    setShowRecording(false);
  };
  const attachAudio = () => {
    setUploadTitle("Upload Audios");
    setAllowedFiles(audioFileTypes);
    toggleModal();
    setShowRecording(true);
  };
  const attachFile = () => {
    setUploadTitle("Upload PDF, CSV & JSON");
    setAllowedFiles(FileTypes);
    toggleModal();
    setShowRecording(false);
  };
  const attachVideo = () => {
    setUploadTitle("Upload Videos");
    setAllowedFiles(videoFileTypes);
    toggleModal();
    setShowRecording(false);
  };

  const master = (
    newMessage: any,
    documents: Array<any> | null,
    consumer: any
  ) => {
    interface newpayload {
      consumerId: string;
      indexName: string;
      modelParams: modelParams_;
      question: string;
      session: string;
      documents?: any | null;
      placeholders?: any | null;
    }

    let newPayload: newpayload = {
      consumerId: consumer?.consumerId,
      indexName: consumer?.indexName,
      modelParams: modelParams_,
      question: inputValue,
      session,
      placeholders: keyValueData,
    };
    if (documents) {
      newPayload.documents = documents;
      let append = "";
      documents.map((d, i) => {
        append = `${append} {document.${i}}`;
      });
      newPayload.question = `${inputValue} ${append}`;
    }
    if (keyValueData) {
      newPayload.placeholders = keyValueData;
    }

    setStepName("is typing...");
    fetch(`${baseURl}/master/?key=${apiKey}`, {
      method: "POST",
      headers: {
        "sec-fetch-site": "none",
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPayload),
    })
      .then((response) => {
        if (!response.ok) {
          SnackbarManager.error(
            "An error occurred while processing your request."
          );

          setIsTyping(false);
          return;
        }
        return response.json();
      })
      .then(async (res) => {
        setMessages([
          ...messages,
          newMessage,
          {
            text: res["message"],
            sender: consumer?.botHumanName as string,
          },
        ]);

        if (res?.data?.languageId) {
          const language = await getLanguage(res?.data?.languageId);
          const prompt = await getPromptById(language?.data?.promptId);

          newDebugLine(language.data, consumer, prompt.data);
        }
        setIsTyping(false);
        $inputChat.focus();
        $cardBody.scrollTop = $cardBody.scrollHeight;
      });
  };

  const newSession = (name: string) => {
    const randomA: number = Math.floor(Math.random() * 100000);
    const randomB: number = Math.floor(Math.random() * 100000);
    setSession(`${name || "new"}-${randomA}-axmos-${randomB}`);
  };
  const showPromptModal = (prompt: any) => () => {
    setPrompt(prompt);
    setOpenPromptModal(true);
  };
  const chatByConsumers = (consumer: Response) => {
    setConsumerSelected(consumer);
    setModelParams_(consumer.modelParams);
    setMessages([]);
    const newMessage: IMessage = {
      sender: consumer.botHumanName,
      text: consumer.welcomeMessage,
    };
    setMessages([newMessage]);
    newSession(consumer.botHumanName);
    $cardBody.scrollTop = $cardBody.scrollHeight;
  };

  const cleanChat = () => {
    const consumer: any = consumerSelected || consumers[0];
    setMessages([]);
    const newMessage: IMessage = {
      sender: consumer.botHumanName,
      text: consumer.welcomeMessage,
    };
    setMessages([newMessage]);
    newSession(consumer.botHumanName);
    $cardBody.scrollTop = $cardBody.scrollHeight;
  };

  useEffect(() => {
    newSession("new");
    getAllConsumers();
  }, []);

  const handleFileInputClick = () => {
    const $fileInput = document.getElementById(
      "file-input"
    ) as HTMLInputElement;

    new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    $fileInput.click();
  };

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      files = Array.from(event.dataTransfer.files || []);
      const allowed = allowedFiles.some((type) =>
        files.some((file) => file.name.includes(type))
      );

      if (!allowed)
        return SnackbarManager.warning(
          `Only ${allowedFiles.join(",")} files are allowed`
        );

      setSelectedFiles([...selectedFiles.concat(files)]);
    },
    [allowedFiles]
  );

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    },
    []
  );

  const handleRemove = (fileName: string) => {
    const $fileInput = document.getElementById(
      "file-input"
    ) as HTMLInputElement;
    setSelectedFiles(selectedFiles.filter((file) => file.name !== fileName));
    $fileInput.value = "";
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    files = Array.from(event.target.files || []);
    const allowed = allowedFiles.some((type) =>
      files.some((file) => file.name.includes(type))
    );

    if (!allowed)
      return SnackbarManager.warning(
        `Only ${allowedFiles.join(",")} files are allowed`
      );

    setSelectedFiles([...selectedFiles.concat(files)]);
  };
  const starDrag = (evt: any) => {
    if (evt.target) setShowDrag(true);
    //handleOpen()
    attachFile();
  };
  const endDrag = (evt: any) => {
    setShowDrag(false);
  };

  const handleClose = () => {
    setSelectedFiles([]);
    setAttachmentModal(false);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "33%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflowY: "auto",
  };

  const contentStyle: SxProps<Theme> = {
    maxHeight: "60vh",
    overflowY: "auto",
    mb: 2,
  };

  // const [openModal, setOpenModal] = useState(false);
  // const handleOpen = () => setOpenModal(true);
  // const handleClose = () => setOpenModal(false);

  // const [returnedValue, setReturnedValue] = useState<any | null>(null);

  // const handleConfirm = async (data: string, file_attach: File[]) => {
  //   console.log('venta confirmada.');
  //   setReturnedValue(data);
  //   console.log('data: ',data, ' - ', file_attach)
  //   setInputValue(data)
  //   setSelectedFiles(file_attach)
  //   sendMessage()
  //   setOpenModal(false)
  // }

  // const [data_, setData] = useState({ input1: 'Initial data 1' })
  // const handlDataChange = (newData: { input1: string }) => setData(newData)

  return (
    <>
      <PromptModalDetail
        isOpen={openPromptModal}
        setIsOpen={setOpenPromptModal}
        prompt={prompt}
      ></PromptModalDetail>
      {/* <AddAttachmentModal
        open={openModal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        //data_={data_}
        //onDataChange={handlDataChange}
        //toggle={handleClose}
      ></AddAttachmentModal> */}
      <Modal
        //className="modal-dialog-centered"
        open={attachmentModal}
        //toggle={toggleModal}
      >
        <Box sx={{ ...style, width: 1000, height: 850 }}>
          <div className='modal-header'>
            <h5 className='modal-title' id='exampleModalLabel'>
              {uploadTitle}
            </h5>
            <button
              aria-label='Close'
              className='close'
              data-dismiss='modal'
              type='button'
              onClick={handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className='modal-body'>
            <SnackbarUtilitiesConfigurator />
            <div className='p-2 w-100'>
              <input
                onChange={(e) => handleFileChange(e)}
                id='file-input'
                type='file'
                multiple
                className='d-none'
                accept={allowedFiles.join(",")}
              />
              <div id='audio-container'></div>
              <Box sx={contentStyle}>
                <div
                  style={{ backgroundColor: "#f5f5f5" }}
                  className='border rounded p-2 mt-2 form-contol text-xs'
                >
                  <div
                    onClick={() => handleFileInputClick()}
                    className={`${
                      selectedFiles.length > 0 && "mb-2"
                    } rounded input-file d-flex align-items-center justify-content-between  p-2`}
                  >
                    <div
                      className='file-upload'
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      style={{ height: 300, width: 1000 }}
                    >
                      <Row>
                        <Col>Drag & Drop your Files or Click to Add (+)</Col>
                      </Row>
                      <Row>
                        <Col>Allowes files {allowedFiles.join(",")}</Col>
                      </Row>
                    </div>
                  </div>
                  <ListGroup>
                    {Array.from(selectedFiles || []).map((file, index) => (
                      <ListGroupItem
                        key={index}
                        className='d-flex justify-content-between align-items-center listItem'
                      >
                        <span className='text-danger' title={file.name}>
                          <i className='fa fa-file mr-2'></i>{" "}
                          <h5 className='text-primary'>{file.name}</h5>
                        </span>
                        <Button
                          color='error'
                          variant='text'
                          onClick={() => handleRemove(file.name)}
                        >
                          <i className='fa fa-trash'></i>
                        </Button>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </div>
              </Box>
              <br></br>
              <div>
                <Row>
                  <Col xs='9'>
                    <InputBase
                      id='input-chat'
                      disabled={isTyping}
                      className='w-100 border'
                      placeholder='Type something'
                      onInput={(e: any) => setInputValue(e.target.value)}
                      value={inputValue}
                      onKeyDown={(e: any) => {
                        if (e.key === "Enter") {
                          setInputValue(e.target.value);
                          sendMessage();
                          setAttachmentModal(false);
                        }
                      }}
                    />
                  </Col>
                  <Col xs='1'>
                    <Button
                      //disabled={isTyping}
                      onClick={isRecording ? stopRecording : startRecording}
                      variant='outlined'
                      className='send-btn flex-1'
                    >
                      {isRecording ? (
                        <i className='fa fa-stop ml-1'></i>
                      ) : (
                        <i className=' fa fa-microphone ml-1'></i>
                      )}
                    </Button>
                  </Col>
                  <Col xs='2'>
                    <Button
                      //disabled={isTyping}
                      onClick={() => sendMessage()}
                      variant='outlined'
                      className='send-btn flex-1'
                    >
                      Send <i className=' fa fa-message ml-1'></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <SimpleHeader name='Test Bots' parentName='Bots' />
      <section className='p-3'>
        <Row>
          <Col
            className='chat-panel'
            xl='6'
            md='12'
            onDragOver={starDrag}
            onDragLeave={endDrag}
          >
            <div
              className='dragZone'
              style={!showDrad ? { display: "none" } : {}}
            >
              <div className='internalDragZone'>
                {/* <div className="internalDragZone" onDrop={handleDropChat} onDragOver={handleDragOver}> */}
                Drag & Drop your file
              </div>
            </div>
            <Card style={{ height: "83vh" }}>
              <CardHeader className='bg-default'>
                <Row className='align-items-center'>
                  <Col xs='9'>
                    <h6 className='text-light text-uppercase ls-1 mb-1'>
                      Chat with
                    </h6>
                    <AxmosInput
                      style={{
                        background: "transparent",
                        cursor: "pointer",
                        fontSize: "17px",
                        fontWeight: "bold",
                        color: "#fff",
                        border: "0",
                        boxShadow: "0px 0px",
                      }}
                      name='consumers'
                      type='select'
                      id='consumers'
                      onChange={(value) => {
                        chatByConsumers(JSON.parse(value));
                      }}
                    >
                      {consumers.map((consumer) => {
                        return (
                          <option
                            value={JSON.stringify(consumer)}
                            key={consumer.consumerId}
                          >
                            {consumer.botHumanName}
                          </option>
                        );
                      })}
                    </AxmosInput>
                  </Col>
                  <Col xs='3'>
                    <Button
                      className='btn btn-primary btn-sm'
                      id='newSession'
                      onClick={cleanChat}
                    >
                      {" "}
                      New Session
                    </Button>
                    <Button
                      onClick={() => {
                        setIsOpenIndexDrawer(true);
                      }}
                      variant='outlined'
                      className='flex-1 btn-sm'
                    >
                      <i className='fa fa-cog ml-1'></i>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className='chat-container'>
                  {messages.map((message, index) => (
                    <div>
                      <img
                        alt='...'
                        className='avatar avatar-lg media-comment-avatar rounded-circle'
                        src={
                          message.sender == "User"
                            ? require("assets/img/user-avatar.png")
                            : require("assets/img/bot-avatar-2.png")
                        }
                      ></img>

                      <div key={index} className='message'>
                        <div
                          className='rounded-lg px-1 text-sm text-truncate'
                          style={{ width: "auto", maxWidth: "100px" }}
                        >
                          <strong title={message.sender}>
                            {message.sender}:
                          </strong>
                        </div>{" "}
                        {/* <ReactMarkdown className='ml-3 text-sm' remarkPlugins={[remarkGfm]}>                  */}
                        <ReactMarkdown
                          className='ml-3 text-sm'
                          remarkPlugins={[remarkGfm]}
                        >
                          {message.text}
                          {/* <Comparison markdown={markContent} /> */}
                        </ReactMarkdown>
                      </div>
                      {message.attachment?.map((a, index) =>
                        message.attachment ? (
                          <AttachmentCard
                            fileExtension={a.fileExtension}
                            name={a.name}
                            blob={a.blob}
                          ></AttachmentCard>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </CardBody>

              <CardFooter>
                <Fade>
                  <small>
                    {isTyping
                      ? `${consumerSelected?.botHumanName} ${stepName}`
                      : ""}
                  </small>
                </Fade>
                <div className='p-3 d-flex align-items-center justify-content-between  '>
                  <Setting
                    isOpen={isOpenIndexDrawer}
                    setIsOpen={(value: boolean) => setIsOpenIndexDrawer(value)}
                    consumerSetting={modelParams_}
                    placeholderSetting={keyValueData}
                    onModelParams={updateModelParams}
                    onPlaceholder={updatePlaceholder}
                  />

                  <InputBase
                    id='input-chat'
                    disabled={isTyping}
                    className='w-75'
                    placeholder='Type something'
                    onInput={(e: any) => setInputValue(e.target.value)}
                    value={inputValue}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        setInputValue(e.target.value);
                        sendMessage();
                      }
                    }}
                  />
                  <Button
                    disabled={isTyping}
                    onClick={() => sendMessage()}
                    variant='outlined'
                    className='flex-1 send-btn'
                  >
                    Send <i className='fa fa-message ml-1'></i>
                  </Button>
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle color='secondary'>
                      <i className='fas fa-plus-circle'></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={attachImage}>
                        <i className='fas fa-image'></i> {/* Image icon */}
                      </DropdownItem>
                      <DropdownItem onClick={attachFile}>
                        <i className='fas fa-file'></i> {/* File icon */}
                      </DropdownItem>
                      <DropdownItem onClick={attachAudio}>
                        <i className='fas fa-microphone'></i> {/* Audio icon */}
                      </DropdownItem>
                      <DropdownItem onClick={attachVideo}>
                        <i className='fas fa-video'></i> {/* Audio icon */}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col className='debugger-panel' xl='6' md='12'>
            <Card>
              <CardHeader className='bg-primary'>
                <Row className='align-items-center'>
                  <div className='col'>
                    <h6 className='text-light text-uppercase ls-1 mb-1'>
                      Trace
                    </h6>
                    <h5 className='h3 text-white mb-0'>Debugger</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xl='12'>
                    <Card>
                      <CardHeader className='border-0'>
                        <Row className='align-items-center'>
                          <div className='col'>
                            <h3 className='mb-0'>Message Flow</h3>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <div className='table'>
                          <Row className='tableHeader  text-uppercase ls-1 mb-1'>
                            <Col className='question' xs='3'>
                              <h5>Consumer</h5>
                            </Col>
                            <Col className='response' xs='5'>
                              <h5>Message</h5>
                            </Col>
                            <Col className='totalTokens' xs='2'>
                              <h5>Tokens</h5>
                            </Col>
                            <Col className='requestTime' xs='2'>
                              <h5>Time (s)</h5>
                            </Col>
                          </Row>
                          {debuggerRows?.map((row, index) => (
                            <div>
                              <Row
                                className='tableRow text-uppercase ls-1 mb-1'
                                id={"row-" + row.position.toString()}
                              >
                                <Col className='question' xs='3'>
                                  <h5>{row.consumerName}</h5>
                                </Col>
                                <Col className='response' xs='5'>
                                  <h5>{row.message}</h5>
                                </Col>
                                <Col className='totalTokens' xs='2'>
                                  <h5>{row.totalTokens || "..."}</h5>
                                </Col>
                                <Col className='requestTime' xs='2'>
                                  <h5>{row.totalTime || "..."}</h5>
                                </Col>
                              </Row>
                              <UncontrolledCollapse
                                toggler={"row-" + row.position.toString()}
                              >
                                <Card>
                                  <CardBody>
                                    <div>
                                      <b>Prompt</b>: {row.prompt.name}{" "}
                                      <Button
                                        onClick={showPromptModal(row.prompt)}
                                        size='sm'
                                        title='View prompt details'
                                      >
                                        <i className='fa fa-eye'></i>
                                      </Button>
                                    </div>
                                    <div>
                                      <b>Index</b>: {row.details.indexName}
                                    </div>
                                    <div>
                                      <b>Session</b>: {row.details.session}
                                    </div>
                                    <div>
                                      <b>Model Params</b>:{" "}
                                    </div>
                                    <Row>
                                      <Col xs='1'></Col>
                                      <Col>
                                        <b>Model</b>: {row.modelParams.model}
                                      </Col>
                                      <Col>
                                        <b>Temperature</b>:{" "}
                                        {row.modelParams.temperature}
                                      </Col>
                                      <Col>
                                        <b>Max Tokens</b>:{" "}
                                        {row.modelParams.maxOutputTokens}
                                      </Col>
                                    </Row>
                                    <div>
                                      <b>Model time (s)</b>:{" "}
                                      {Math.round(
                                        (row.details.llm_time_taken || 0) * 1000
                                      ) / 1000}
                                    </div>
                                    <div>
                                      <b>Input tokens</b>:{" "}
                                      {row.details.prompt_token_count}
                                    </div>
                                    <div>
                                      <b>Output tokens</b>:{" "}
                                      {row.details.candidates_token_count}
                                    </div>
                                    <div>
                                      <b>Integrations</b>:{" "}
                                      {JSON.stringify(
                                        row.details.integrationsData
                                      )}
                                    </div>
                                    <div>
                                      <b>Integrations time (s)</b>:{" "}
                                      {Math.round(
                                        (row.details.integrations_time_taken ||
                                          0) * 1000
                                      ) / 1000}
                                    </div>

                                    <div id={"docs-" + row.position.toString()}>
                                      <b>Docs</b>: {row.details?.docs?.length}{" "}
                                      <a href=''>+</a>{" "}
                                    </div>
                                    <UncontrolledCollapse
                                      toggler={
                                        "docs-" + row.position.toString()
                                      }
                                    >
                                      {row.details?.docs?.map((doc, index) => (
                                        <div>
                                          <div>
                                            <b>source</b>: {doc.source}
                                          </div>
                                          <div>
                                            <b>text</b>: {doc.text}
                                          </div>
                                        </div>
                                      ))}
                                    </UncontrolledCollapse>
                                  </CardBody>
                                </Card>
                              </UncontrolledCollapse>
                            </div>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Chat_v1;

const getLanguage = async (languageId: string): Promise<any> => {
  const response = await fetch(
    `${baseURl}/language/${languageId}?key=${apiKey}`,
    {
      headers: {
        "content-type": "application/json",
      },
      method: "GET",
    }
  );

  return response.json();
};
