import { v4 } from "uuid";
export const formatDuration = (duration: number) => {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export const newSession = () => v4();

export const formatPlaceholders = (
  placeholders: Array<{ key: string; value: string }>
) => {
  return placeholders.reduce((acc, placeholder) => {
    acc[placeholder.key] = placeholder.value;
    return acc;
  }, {} as Record<string, string>);
};

/**
 * Convierte bytes a kilobytes (KB) o megabytes (MB).
 * @param bytes - El tamaño en bytes.
 * @param unit - La unidad a la que se desea convertir ('KB' o 'MB').
 * @returns El tamaño convertido a la unidad especificada.
 */

export const convertBytes = (bytes: number, unit: "KB" | "MB"): number => {
  if (unit === "KB") {
    return bytes / 1024;
  } else if (unit === "MB") {
    return bytes / (1024 * 1024);
  } else {
    throw new Error("Unidad no soportada. Usa 'KB' o 'MB'.");
  }
};
