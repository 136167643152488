import { AddBoxRounded, Delete } from "@mui/icons-material";
import {
  Badge,
  BadgeProps,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import AxmosInput from "components/Form/AxmosInput";
import React, { useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { usePromptsStore } from "store/prompts/prompts.store";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    top: "-5px",
    right: "-5px",
  },
}));

const PopoverComponent: React.FC<any> = ({ children, placeholderList }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        size='small'
        aria-describedby={id}
        variant='contained'
        color='primary'
        onClick={handleClick}
      >
        <StyledBadge badgeContent={placeholderList.length} color='secondary'>
          Placeholders
        </StyledBadge>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            p: "1rem",
            maxHeight: "400px",
          }}
        >
          {children}
        </Box>
      </Popover>
    </>
  );
};

export const PlaceholdersSettings: React.FC<{
  onChange?: (placeholders: any) => void;
}> = ({ onChange }) => {
  const [setPlaceholderList, placeholderList] = usePromptsStore((state) => [
    state.setPlaceholdersList,
    state.placeholdersList,
  ]);

  const addPlaceholder = () => {
    setPlaceholderList(
      placeholderList.concat({
        key: "",
        value: "",
      })
    );
  };

  const setPlaceholderKey = (index: number, value: string, ph: any) => {
    setPlaceholderList([
      ...placeholderList.slice(0, index),
      { ...ph, key: value },
      ...placeholderList.slice(index + 1),
    ]);
  };

  const setPlaceholderValue = (index: number, value: string, ph: any) => {
    setPlaceholderList([
      ...placeholderList.slice(0, index),
      { ...ph, value: value },
      ...placeholderList.slice(index + 1),
    ]);
  };

  const removePlaceholder = (index: number) => {
    setPlaceholderList(placeholderList.filter((_, i) => i !== index));
  };

  return (
    <>
      <PopoverComponent placeholderList={placeholderList}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant='h6'>
            Add your placeholders here: ({placeholderList.length}
            <i className='text-xs text.secondary'>/added</i> )
          </Typography>
          <IconButton onClick={addPlaceholder}>
            <AddBoxRounded color='primary' titleAccess='Add new placeholder' />
          </IconButton>
        </div>
        <TransitionGroup>
          <Divider variant='fullWidth' className='mb-3' />
          {placeholderList.map((ph, index) => {
            return (
              <Collapse>
                <div
                  className='d-flex w-100 align-items-center'
                  style={{ gap: "3px", width: "100%" }}
                  key={index}
                >
                  <AxmosInput
                    label='Key'
                    placeholder='Type your key here.'
                    id={`${index}-placeholderKey`}
                    name='placeholderKey'
                    type='text'
                    value={ph.key}
                    onInput={(value) => setPlaceholderKey(index, value, ph)}
                  />

                  <AxmosInput
                    label='Default value'
                    placeholder='Type your default value here.'
                    id={`${index}-placeholderValue`}
                    name='placeholderValue'
                    type='text'
                    value={ph.value}
                    onInput={(value) => setPlaceholderValue(index, value, ph)}
                  />

                  <IconButton
                    className='m-0'
                    size='small'
                    color='error'
                    onClick={() => removePlaceholder(index)}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </Collapse>
            );
          })}
        </TransitionGroup>
      </PopoverComponent>
    </>
  );
};
