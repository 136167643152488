import { RefreshRounded, SendRounded, UploadFile } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Colors } from "consts/Colors.const";
import {
  audioFileTypes,
  documentsFileTypes,
  imageFileTypes,
  videoFileTypes,
} from "consts/FileTypes.const";
import { ModelParams } from "models/NewModelParams.model";
import React, { useEffect, useRef, useState } from "react";
import { useAttachmentStore } from "store/chat/attachment.store";
import { useChatStore } from "store/chat/chat.store";
import { useConsumerStore } from "store/consumers/consumer.store";
import { usePromptsStore } from "store/prompts/prompts.store";
import {
  SnackbarUtilitiesConfigurator,
  formatPlaceholders,
  newSession,
} from "utils";
import { useFileUploader } from "../hooks/useFileUploader";
import "./ChatBox.css";
import { ChatBoxModelParams } from "./ChatBoxModelParams";
import { ChatCards } from "./ChatCards";
import { ChatFileUploader } from "./ChatFileUploader";
import ChatMessage from "./ChatMessage";
import { ConsumerOption } from "./ConsumerOption";
import FileViewer from "./FileViewer";
import { SaveAudio } from "./SaveAudio";
import { ChatArea, InputArea, StyledPaper } from "./Styled";

const NoChatText: React.FC = () => {
  return (
    <div
      style={{ textAlign: "center", marginTop: "3rem" }}
      className='flex flex-col justify-center items-center'
    >
      <Typography
        variant='h5'
        color='menuText'
        style={{ marginBottom: "1rem" }}
      >
        Start a new conversation
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        To start chatting, select an agent and enter a message below.
      </Typography>
      <div className='d-none d-lg-block mt-5'>
        <ChatCards />
      </div>
    </div>
  );
};

export const ChatBox: React.FC = () => {
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [audioData, setAudioData] = useState<any>(null);
  const [messageCount, setMessageCount] = useState<number | null>(null); 
  const consumers = useConsumerStore((state) => state.consumers);
  const [files, setFiles, uploadAttachment] = useAttachmentStore((state) => [
    state.files,
    state.setFiles,
    state.uploadAttachment,
  ]);
  
  const [
    sendMessageAndLog,
    consumer,
    setConsumer,
    messages,
    setMessages,
    question,
    setQuestion,
    session,
    setSession,
  ] = useChatStore((state) => [
    state.sendMessageAndLog,
    state.consumerSelected,
    state.setConsumerSelected,
    state.mesagges,
    state.setMessages,
    state.question,
    state.setQuestion,
    state.session,
    state.setSession,
  ]);

  const { onDragOver, isDragging, onDragLeave, onDrop, onDragEnter } =
    useFileUploader([
      ...imageFileTypes,
      ...documentsFileTypes,
      ...audioFileTypes,
      ...videoFileTypes,
    ]);

  const [setPlaceholderList, placeholderList] = usePromptsStore((state) => [
    state.setPlaceholdersList,
    state.placeholdersList,
  ]);

  const chatAreaRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
      chatAreaRef.current.style.scrollBehavior = "smooth";
    }
  };

  const sendMessage = async () => {
    const userMessage = { text: question, sender: "user", attachment: files };
    let newDocuments = [];
    let documentsAttached = "";
    setMessages([...messages, userMessage]);
    setQuestion("");
    setIsTyping(true);
    setFiles([]);
    setAudioData(null);
    try {
      if (userMessage.attachment.length > 0) {
        const response = await uploadAttachment(userMessage.attachment);
        newDocuments = response.data.documents;
      }
      if (newDocuments.length > 0) {
        newDocuments.forEach(
          (_: any, index: number) =>
            (documentsAttached += ` {document.${index}}`)
        );
      }
      const botMessage = await sendMessageAndLog({
        consumer,
        masterParams: {
          question: documentsAttached
            ? question + " " + documentsAttached
            : question,
          messageCount,
          session: session,
          consumerId: consumer?.consumerId as string,
          indexName: consumer?.indexName as string,
          modelParams: {
            ...consumer?.modelParams,
            overWrite: true,
          } as ModelParams,
          documents: newDocuments ? newDocuments : undefined,
          placeholders:
            placeholderList.length > 0
              ? formatPlaceholders(placeholderList)
              : {},
        },
      });
      setMessages([
        ...messages,
        userMessage,
        { text: botMessage.message, sender: "bot", attachment: [] },
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsTyping(false);
    }
  };

  const handleStopRecording = (audioBlob: Blob) => {
    const file = new File([audioBlob], "audio.wav", { type: "audio/wav" });
    const fileIndex = files.findIndex((f) => f.name === "audio.wav");
    if (fileIndex !== -1) {
      files.splice(fileIndex, 1);
    }
    setFiles([...files, file]);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setConsumer(consumers[0]);
  }, [consumers]);

  return (
    <Grid container spacing={0}>
      <SnackbarUtilitiesConfigurator />
      <Grid item md={9} xs={12} sm={12} onDragOver={onDragOver}>
        {isDragging && (
          <>
            <div
              className='position-absolute top-0 left-0 d-flex justify-content-center align-items-center'
              onDrop={onDrop}
              onDragLeave={onDragLeave}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: 10,
              }}
            >
              <Typography color='white' variant='h6'>
                Drop files here
              </Typography>
              <UploadFile sx={{ color: "white" }} />
            </div>
          </>
        )}

        <div style={{ height: "100%", width: "100%" }}>
          <StyledPaper>
            <ChatArea ref={chatAreaRef}>
              {messages.length >= 1 ? (
                <ChatMessage
                  botName={consumer?.botHumanName}
                  messages={messages}
                />
              ) : (
                <NoChatText />
              )}
            </ChatArea>
            {isTyping && (
              <Typography color='lightslategray' sx={{ mb: 2, fontSize: 12 }}>
                {consumer?.botHumanName} is typing...
              </Typography>
            )}
            <div
              className='d-flex justify-content-start align-items-center'
              style={{
                minWidth: "100%",
                overflowX: "auto",
                overflowY: "hidden",
                padding: "0.5rem 0",
              }}
            >
              <Stack direction='row' gap={2}>
                {files &&
                  files.map((file, index) => (
                    <FileViewer
                      onDelete={() =>
                        setFiles(files.filter((_, i) => i !== index))
                      }
                      file={file}
                    />
                  ))}
              </Stack>
            </div>
            <InputArea 
              sx={{ 
                width: "100%", 
                gap: "0.5rem",
                display: "flex",
                flexDirection: { xs: "row", md: "row" }
              }}
            >
              <ChatFileUploader />
              <div className='d-flex flex-column' style={{ width: "100%" }}>
                <TextField
                  variant='standard'
                  fullWidth
                  multiline
                  value={question}
                  maxRows={4}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      if (question || audioData) {
                        if (!isTyping) sendMessage();
                      }
                    }
                  }}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder='Type something here...'
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      borderRadius: 5,
                      backgroundColor: "#f6f9fc",
                      padding: "0.5rem 0.75rem",
                      fontSize: "0.9rem"
                    },
                  }}
                />
              </div>
              <IconButton
                sx={{
                  padding: "0.25rem",
                }}
                disabled={!question && !audioData}
                onClick={sendMessage}
                color='primary'
                aria-label='menu'
              >
                <SendRounded fontSize="small"/>
              </IconButton>
              <SaveAudio
                onStop={(e) => {
                  setAudioData(e);
                  handleStopRecording(e.audioBlob);
                }}
              />
              <IconButton
                onClick={() => {
                  if (!isTyping) setMessages([]);
                }}
              >
                <RefreshRounded titleAccess='Clear Messages' />
              </IconButton>
            </InputArea>
            <Stack
              direction='row'
              spacing={1}
              className='mt-1'
              justifyContent='center'
              alignItems='center'
            >
              <Typography color='lightslategray' sx={{ mb: 2, fontSize: 12 }}>
                AI agents can make mistakes, always verify important
                information.
              </Typography>
            </Stack>
          </StyledPaper>
        </div>
      </Grid>

      <Grid item md={3}>
        <div style={{ minWidth: "100%" }} className='d-none d-lg-block p-1'>
          <Paper
            elevation={1}
            sx={{
              minWidth: "100%",
            }}
          >
            <Stack
              sx={{
                minWidth: "100%",
                p: 1,
                bgcolor: `${Colors.primary}`,
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
              }}
              direction='row'
              alignItems='center'
            >
              <Typography color='white' variant='subtitle1' align='left'>
                Chat with:
              </Typography>
            </Stack>
            <div className='p-2'>
              <ConsumerOption
                onChange={(value: string) => {
                  if (!isTyping) {
                    setConsumer(JSON.parse(value));
                    setSession(newSession());
                    setMessages([
                      { text: JSON.parse(value).welcomeMessage, sender: "bot" },
                    ]);
                  }
                }}
              />
            </div>
          </Paper>
          <div className='mt-1'>
            <ChatBoxModelParams
              initialModelParams={consumer?.modelParams as ModelParams}
              onChange={(value: any, historyLimitSize: number | null) => {
                setConsumer({
                  ...consumer,
                  modelParams: value,
                  created: consumer ? consumer.created : undefined,
                  botHumanName: consumer ? consumer.botHumanName : "",
                  welcomeMessage: consumer ? consumer.welcomeMessage : "",
                  indexName: consumer ? consumer.indexName : "",
                  masterPromptId: consumer ? consumer.masterPromptId : "",
                  defaultPromptId: consumer ? consumer.defaultPromptId : "",
                  prompts: consumer ? consumer.prompts : [],
                  consumerId: consumer ? consumer.consumerId : "",
                });
                setMessageCount(historyLimitSize);
              }}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
