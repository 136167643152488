import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useConsumerStore } from "store/consumers/consumer.store";
import { usePromptsStore } from "store/prompts/prompts.store";
import MasterConsumer from "../MasterConsumer";
import { SnackbarManager } from "utils";

const EditConsumer: React.FC = () => {
  const { id } = useParams();

  const getConsumerById = useConsumerStore((state) => state.getConsumerById);
  const [updateConsumer, consumer] = useConsumerStore((state) => [
    state.updateConsumer,
    state.consumer,
  ]);

  const [getAllPrompts] = usePromptsStore((state) => [
    state.getAll,
    state.prompts,
  ]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getProcessingData = async () => {
    await getAllPrompts();
    await getConsumerById(id as string);
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      await updateConsumer(id as string, consumer);
      SnackbarManager.success("Agent updated successfully.");
    } catch (error) {
      console.error(error);
      SnackbarManager.error("Error updating agent.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getProcessingData();
    }
  }, [id]);

  return (
    <>
      <MasterConsumer onSubmit={handleUpdate}>
        <Button
          disabled={isLoading}
          type='submit'
          color='info'
          size='small'
          variant='contained'
          className='mt-5'
        >
          {isLoading ? (
            <>
              <i className='fa fa-spinner fa-spin'></i> Updating...
            </>
          ) : (
            <>
              Update
              <i className='fa fa-edit ml-2'></i>
            </>
          )}
        </Button>
      </MasterConsumer>
    </>
  );
};

export default EditConsumer;
