import { RouteObject } from "react-router-dom";
import PromptsViewAll from "views/prompts/view-all/PromptsViewAll";
import UpdatePrompt from "views/prompts/edit/UpdatePrompt";
import { CreatePrompt } from "views/prompts/create/CreatePrompt";

const routes: RouteObject[] = [
  { path: "prompts/view-all", element: <PromptsViewAll /> },
  { path: "prompts/edit/:id", element: <UpdatePrompt /> },
  { path: "prompts/create", element: <CreatePrompt /> },
];

export default routes;
