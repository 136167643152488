import SimpleHeader from "components/Headers/SimpleHeader";
import { PromptUpdateAndCreate } from "models";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePromptsStore } from "store/prompts/prompts.store";
import { SnackbarUtilitiesConfigurator } from "utils";
import "./prompt.css";

import { PromptForm } from "./PromptForm";
import { useIndexStore } from "store/embeddings/index-documents.store";
import { useEmbeddingStore } from "store/embeddings/embedding.store";

const CreateOrUpdateForm: React.FC<{
  children?: React.ReactNode;
  onSubmit: (prompt: PromptUpdateAndCreate) => void;
}> = ({ children, onSubmit }) => {
  const { id } = useParams();

  const [prompt] = usePromptsStore((state) => [state.prompt]);

  const [setIndexName, setIndexDocuments] = useIndexStore((state) => [
    state.setIndexName,
    state.setDocuments,
  ]);

  const [getAllIndexes] = useEmbeddingStore((state) => [state.getAllIndexes]);

  const handleSubmit = () => {
    onSubmit(prompt);
  };

  useEffect(() => {
    return () => {
      setIndexName("");
      setIndexDocuments([]);
    };
  }, []);

  useEffect(() => {
    getAllIndexes();
  }, [getAllIndexes]);

  return (
    <>
      <SnackbarUtilitiesConfigurator />

      <SimpleHeader
        name={id ? "Edit Prompt" : "Create Prompt"}
        parentName='Prompts'
      />

      <section className='p-3'>
        <PromptForm onSubmit={handleSubmit}>{children}</PromptForm>
      </section>
    </>
  );
};

export default CreateOrUpdateForm;
