import { Divider, Slider } from "@mui/material";
import AxmosInput from "components/Form/AxmosInput";
import { SafetySettings } from "components/SafetySettings/SafetySettings";
import React from "react";
import { Col, Fade, Row } from "reactstrap";
import { usePromptsStore } from "store/prompts/prompts.store";

export const AdvancedModelParams: React.FC = () => {
  const prompt = usePromptsStore((state) => state.prompt);
  const setPrompt = usePromptsStore((state) => state.setPrompt);

  return (
    <>
      <Fade>
        <Divider variant='fullWidth' className='mb-3' />
        <div className='text-xs' id='modelParams'>
          <Row>
            <Col>
              <div>
                <AxmosInput
                  name='temperature'
                  id='temperature'
                  label='Temperature'
                  type='range'
                  value={prompt.modelParams.temperature}
                  step={0.1}
                  min={0}
                  max={1}
                  onChange={(evt: any) =>
                    setPrompt({
                      ...prompt,
                      modelParams: {
                        ...prompt.modelParams,
                        temperature: +evt,
                      },
                    })
                  }
                />
              </div>
            </Col>

            <Col>
              <AxmosInput
                name='topP'
                id='topP'
                label='Top P'
                type='range'
                value={prompt.modelParams.topP}
                step={0.1}
                min={0}
                max={1}
                onChange={(evt: any) =>
                  setPrompt({
                    ...prompt,
                    modelParams: {
                      ...prompt.modelParams,
                      topP: +evt,
                    },
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <AxmosInput
                value={prompt.modelParams.topK}
                id='topK'
                label='Top K'
                placeholder='Type your top K value'
                onChange={(evt) =>
                  setPrompt({
                    ...prompt,
                    modelParams: {
                      ...prompt.modelParams,
                      topK: +evt,
                    },
                  })
                }
                min={0}
                name='topK'
                type='number'
                required={true}
              />
            </Col>

            <Col>
              <AxmosInput
                value={prompt.modelParams.maxOutputTokens}
                id='maxoutputtokens'
                label='Max Output Tokens'
                placeholder='Type your max output tokens'
                onChange={(evt) =>
                  setPrompt({
                    ...prompt,
                    modelParams: {
                      ...prompt.modelParams,
                      maxOutputTokens: +evt,
                    },
                  })
                }
                name='maxoutputtokens'
                type='number'
                required={true}
              />
            </Col>
            <Col>
              <AxmosInput
                value={prompt.modelParams.response_mime_type}
                id='response_mime_type'
                label='Response mime type'
                onChange={(evt) =>
                  setPrompt({
                    ...prompt,
                    modelParams: {
                      ...prompt.modelParams,
                      response_mime_type: evt,
                    },
                  })
                }
                tooltipMessage="Response MIME Type specifies the format of the server's response: <text> for plain text, <json> for structured JSON data."
                name='response_mime_type'
                type='select'
                required={true}
                className='cursor-pointer'
              >
                <option value='text'>Text</option>
                <option value='application/json'>JSON</option>
              </AxmosInput>
            </Col>
          </Row>
          <SafetySettings
            onChange={(evt) =>
              setPrompt({
                ...prompt,
                modelParams: { ...prompt.modelParams, safetySettings: evt },
              })
            }
            defaultValues={prompt.modelParams?.safetySettings}
          ></SafetySettings>
        </div>
      </Fade>
    </>
  );
};
