import PageNotFound from "components/404/PageNotFound";
import { createBrowserRouter, Navigate } from "react-router-dom";
import IndexView from "views/Index";
import AdminRoutes from "./admin/admin.routes";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to='/admin/home' />,
  },
  { path: "*", element: <PageNotFound /> },
  ...AdminRoutes,
]);

export default routes;
