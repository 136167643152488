import { Integration } from "models";
import { useState } from "react";
import { usePromptsStore } from "store/prompts/prompts.store";
import { SnackbarManager } from "utils";

export const useIntegration = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const prompt = usePromptsStore((state) => state.prompt);

  const ensureAddIntegration = (integration: Integration) => {
    if (!integration.baseUrl) {
      SnackbarManager.warning("Base URL is required!");
      return true;
    }
    if (!integration.route) {
      SnackbarManager.warning("Route is required!");
      return true;
    }
    if (!integration.method) {
      SnackbarManager.warning("Method is required!");
      return true;
    }
  };

  const isIntegrationAdded = (integration: Integration): boolean => {
    const promptHasIntegration = prompt.integrations
      ? prompt.integrations.some(
          (pi) => pi.integrationId === integration.integrationId
        )
      : false;

    return promptHasIntegration;
  };
  return {
    ensureAddIntegration,
    expanded,
    setExpanded,
    isIntegrationAdded,
  };
};
