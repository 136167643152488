import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Collapse,
  Container,
  Form,
  Navbar
} from "reactstrap";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { useLocation } from "react-router-dom";

function AdminNavbar({ theme, toggleSidenav, toggleSettings, sidenavOpen }) {

  const location = useLocation();
  const isOnChatV2 = location.pathname.includes("chats/chat-v2");

  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };

  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand rounded my-2 mx-1 border-bottom shadow d-lg-none",
          { "navbar-dark bg-gradient-white": theme === "dark" },
          { "navbar-light": theme === "light" }
        )}
        expand="md"
      >
        <Container fluid>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleSidenav} //Button for see or hide sidebar in movile
            >
              <MenuIcon />
          </IconButton>
          {isOnChatV2 && (
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="settings"
              className="d-lg-none"
              onClick={toggleSettings} //Button for see or hide Settings in Chat v2
            >
              <SettingsIcon />
            </IconButton>
          )}
          <Collapse navbar isOpen={true}>
            <Form
              className={classnames(
                "navbar-search form-inline mr-sm-3",
                { "navbar-search-light": theme === "dark" },
                { "navbar-search-dark": theme === "light" }
              )}
            >

              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </Form>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
  toggleSettings: () => {},
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func.isRequired,
  sidenavOpen: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(["dark", "light"]),
  toggleSettings: PropTypes.func,
};

export default AdminNavbar;
