import { createAttachment } from "services/attachments/create-attachment";
import { create } from "zustand";

interface State {
  files: File[];
  setFiles: (files: File[]) => void;
  uploadAttachment: (files: File[]) => Promise<any>;
}

export const useAttachmentStore = create<State>((set, get) => ({
  files: [],
  setFiles: (files: File[]) => set({ files }),
  uploadAttachment: async (files: File[]) => {
    const formData = new FormData();
    formData.append("folder", "navaja_");
    for (let f of files) {
      formData.append("document", f);
    }

    try {
      const response = (await createAttachment(formData)) as any;
      return response;
    } catch (error) {
      console.log(error);
    }
  },
}));
