import { Col, Container, Card, CardHeader, CardBody } from "reactstrap";

const DefaultContainer: React.FC<{
  children: React.ReactNode;
  title?: string;
}> = ({ children, title }) => {
  return (
    <>
      <Card className='mt-1'>
        <CardHeader className='font-weight-bold'>{title}</CardHeader>
        <CardBody>{children}</CardBody>
      </Card>
    </>
  );
};

export default DefaultContainer;
