import {
  Drawer,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  FormGroup,
  Slider,
} from "@mui/material";
import AxmosInput from "components/Form/AxmosInput";
import "./Setting.css";
import { useEffect, useState } from "react";
import { getAllModels } from "services/models";
import { SnackbarUtilitiesConfigurator } from "utils";
import ASlider from "components/ASlider";
import { Form, Input, Label, ListGroup, ListGroupItem } from "reactstrap";

interface modelParams_ {
  model?: string;
  temperature: number;
  maxOutputTokens: any;
  topP: number;
  topK: number;
  response_mime_type?: string;
}

interface keyValueData {
  [key: string]: string;
}

interface Props {
  consumerSetting: modelParams_;
  placeholderSetting: keyValueData;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onModelParams: (newData: modelParams_) => void;
  onPlaceholder: (newData_: keyValueData) => void;
}

const DownIcon: React.FC = () => <i className='fa fa-chevron-down'></i>;

export const Setting: React.FC<Props> = ({
  consumerSetting,
  placeholderSetting,
  isOpen,
  setIsOpen,
  onModelParams,
  onPlaceholder,
}) => {
  const models = getAllModels();
  const [settings, setSettings] = useState<modelParams_>(consumerSetting);
  const [placeholder, setPlaceholder] =
    useState<keyValueData>(placeholderSetting);

  const marks_temperature = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
  ];

  const marks_maxOutPutTokens = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 8192,
      label: "8192",
    },
  ];

  const marks_topK = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 40,
      label: "40",
    },
  ];

  const handleAdd = (value: any) => {
    setSettings(value);
  };

  const handleSave = () => {
    setSettings(settings);
    setPlaceholder(data);
    onModelParams(settings);
    onPlaceholder(data);
    setIsOpen(false);
  };

  const setTemperature = (value: any) => {
    setSettings({ ...settings, temperature: parseFloat(value) });
  };

  const handleChangeT = (event: Event, newValue: any) => {
    setTemperature(newValue);
    setSettings({ ...settings, temperature: newValue });
  };

  const setMaxOutputTokens = (value: any) => {
    setSettings({ ...settings, maxOutputTokens: parseFloat(value) });
  };

  const handleChangeM = (event: Event, newValue: any) => {
    setMaxOutputTokens(newValue);
    setSettings({ ...settings, maxOutputTokens: newValue });
  };

  const setTopP = (value: number) => {
    setSettings({ ...settings, topP: value });
  };

  const handleChangeP = (event: Event, newValue: any) => {
    setTopP(newValue);
    setSettings({ ...settings, topP: newValue });
  };

  const setTopK = (value: number) => {
    setSettings({ ...settings, topK: value });
  };

  const handleChangeK = (event: Event, newValue: any) => {
    setTopK(newValue);
    setSettings({ ...settings, topK: newValue });
  };

  useEffect(() => {
    setSettings(consumerSetting);
  }, [consumerSetting]);

  const [data, setData] = useState<keyValueData>({});
  const [key, setKey] = useState<string>("");
  const [defaultValue, setDefaultValue] = useState<string>("");

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKey(event.target.value);
  };

  const handleDefaultValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDefaultValue(event.target.value);
  };

  const handleSubmit = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (key.trim()) {
      setData((prevData) => ({
        ...prevData,
        [key]: defaultValue,
      }));
      setKey("");
      setDefaultValue("");
    }
  };

  const handleDelete = (keyToDelete: string) => {
    setData((prevData) => {
      const { [keyToDelete]: _, ...newData } = prevData;
      return newData;
    });
  };

  const handleChangeMimeType = (value: string) => {
    setSettings({ ...settings, response_mime_type: value });
  };

  const addPlaceholder = () => {
    // setPlaceholderList(
    //   placeholderList.concat({
    //     key: "",
    //     value: "",
    //   })
    // );
  };

  return (
    <>
      <Drawer
        id='drawer'
        classes={{ paper: "drawer-paper", root: "drawer-root" }}
        anchor='right'
        open={isOpen}
        onClose={(event, reason: "escapeKeyDown") => {
          if (reason === "escapeKeyDown") {
            setIsOpen(false);
          }
        }}
      >
        <SnackbarUtilitiesConfigurator />
        <div className='p-2 w-100'>
          <Accordion style={{ minWidth: "100%" }} variant='outlined' key='0'>
            <AccordionSummary
              expandIcon={<DownIcon></DownIcon>}
              aria-expanded='true'
              aria-controls='panel1-content'
              id='panel1-header'
              className='text-sm font-weight-bold text-uppercase '
            >
              <div className='d-flex align-items-center justify-content-between w-100'>
                <span>setting consumer</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className='d-flex flex-column col-12'>
                <div className='row'>
                  <div className='d-flex flex-column col-8'>
                    <AxmosInput
                      style={{ fontSize: "11px", height: "2.5rem" }}
                      value={settings.model}
                      id='model'
                      label='Model'
                      name='model'
                      type='select'
                      required={true}
                      onChange={(value) =>
                        handleAdd({ ...settings, model: value })
                      }
                    >
                      {models.map((model) => (
                        <option key={model.name} value={model.value}>
                          {model.name}
                        </option>
                      ))}
                    </AxmosInput>
                  </div>
                </div>
                <div className='row card'>
                  <div className='d-flex flex-column m-2'>
                    <AxmosInput
                      style={{
                        fontSize: "12px",
                        height: "2rem",
                        width: "5rem",
                      }}
                      id='temperature'
                      label='Temperatura'
                      type='text'
                      name='temperature'
                      placeholder='Temperatura'
                      value={settings.temperature}
                      onInput={(value) =>
                        handleAdd({ ...settings, temperature: value })
                      }
                    />
                  </div>
                  <div className='d-flex flex-column align-center col-10'>
                    <Slider
                      aria-label='Temperature'
                      value={settings.temperature}
                      step={0.1}
                      marks={marks_temperature}
                      min={0}
                      max={1}
                      valueLabelDisplay='off'
                      onChange={handleChangeT}
                    />
                  </div>
                </div>
                <div className='row card'>
                  <div className='d-flex flex-column m-2'>
                    <AxmosInput
                      style={{
                        fontSize: "12px",
                        height: "2rem",
                        width: "5rem",
                      }}
                      id='maxOutputTokens'
                      label='Output token limit'
                      type='text'
                      name='maxOutputTokens'
                      placeholder='Output token limit'
                      value={settings.maxOutputTokens}
                      onInput={(value) =>
                        handleAdd({ ...settings, maxOutputTokens: value })
                      }
                    />
                  </div>
                  <div className='d-flex flex-column col-10'>
                    <Slider
                      aria-label='Máximo Salida Tokens'
                      value={settings.maxOutputTokens}
                      step={1}
                      marks={marks_maxOutPutTokens}
                      min={1}
                      max={8192}
                      valueLabelDisplay='off'
                      onChange={handleChangeM}
                    />
                  </div>
                </div>
                <div className='row card'>
                  <div className='d-flex flex-column m-2'>
                    <AxmosInput
                      style={{
                        fontSize: "12px",
                        height: "2rem",
                        width: "5rem",
                      }}
                      id='topP'
                      label='Top P'
                      type='text'
                      name='topP'
                      placeholder='Top P'
                      value={settings.topP}
                      onInput={(value) =>
                        handleAdd({ ...settings, topP: value })
                      }
                    />
                  </div>
                  <div className='d-flex flex-column col-10'>
                    <Slider
                      aria-label='Top P'
                      value={settings.topP}
                      step={0.01}
                      marks={marks_temperature}
                      min={0}
                      max={1}
                      valueLabelDisplay='off'
                      onChange={handleChangeP}
                    />
                  </div>
                </div>
                <div className='row card'>
                  <div className='d-flex flex-column m-2'>
                    <AxmosInput
                      style={{
                        fontSize: "12px",
                        height: "2rem",
                        width: "5rem",
                      }}
                      id='topK'
                      label='Top K'
                      type='text'
                      name='topK'
                      placeholder='Top K'
                      value={settings.topK}
                      onInput={(value) =>
                        handleAdd({ ...settings, topK: value })
                      }
                    />
                  </div>
                  <div className='d-flex flex-column col-10'>
                    <Slider
                      aria-label='Top K'
                      value={settings.topK}
                      step={1}
                      marks={marks_topK}
                      min={0}
                      max={40}
                      valueLabelDisplay='off'
                      onChange={handleChangeK}
                    />
                  </div>
                </div>

                <div className='row card p-2'>
                  <AxmosInput
                    value={settings.response_mime_type}
                    id='response_mime_type'
                    label='Response mime type'
                    onChange={(value: string) => handleChangeMimeType(value)}
                    name='response_mime_type'
                    type='select'
                    required={true}
                    className='cursor-pointer'
                    tooltipMessage="Response MIME Type specifies the format of the server's response: |text| for plain text, |json| for structured JSON data."
                  >
                    <option value='text'>Text</option>
                    <option value='application/json'>JSON</option>
                  </AxmosInput>
                </div>
              </div>
              {/* <div className='d-flex flex-column'>
                                <AxmosInput
                                    value={settings.model}
                                    id='model'
                                    label='Modelo'
                                    name='model'
                                    type='select'
                                    required={true}
                                    onChange={(value) =>
                                        handleAdd({ ...settings, model: value })
                                    }
                                >
                                    {models.map((model) => (
                                        <option key={model.name} value={model.value}>
                                            {model.name}
                                        </option>
                                    ))}
                                </AxmosInput>
                                <ASlider
                                    label="Temperature"
                                    name="temperature"
                                    start={settings.temperature }
                                    onChange={(value: number) => setTemperature(value)}
                                    step={0.1}
                                    min={0}
                                    max={2}
                                />
                                <AxmosInput
                                    id='maxOutputTokens'
                                    label='Máximo Salida Tokens'
                                    type='text'
                                    name='maxOutputTokens'
                                    placeholder='Máximo Salida Tokens'
                                    value={settings.maxOutputTokens}
                                    onInput={(value) =>
                                        handleAdd({ ...settings, maxOutputTokens: value })
                                    }
                                />
                                <ASlider
                                    label="Top P"
                                    name="Top-P"
                                    start={settings.topP}
                                    onChange={(value: number) => setTopP(value)}
                                    step={0.05}
                                    min={0}
                                    max={1}
                                    value={settings.topP}
                                />
                                <ASlider
                                    label="Top K"
                                    name="Top-k"
                                    start={settings.topK}
                                    onChange={(value: number) => setTopK(value)}
                                    step={10}
                                    min={0}
                                    max={100}
                                    value={settings.topK}
                                />                          
                            </div>                             */}
            </AccordionDetails>
            <AccordionActions>
              <div
                className='btn btn-primary'
                id='newSession'
                onClick={handleSave}
              >
                {" "}
                Save
              </div>
            </AccordionActions>
          </Accordion>

          <Accordion
            defaultExpanded
            style={{ minWidth: "100%" }}
            variant='outlined'
            key='1'
          >
            <AccordionSummary
              expandIcon={<DownIcon></DownIcon>}
              aria-expanded='true'
              aria-controls='panel1-content'
              id='panel1-header'
              className='text-sm font-weight-bold text-uppercase '
            >
              <div className='d-flex align-items-center justify-content-between w-100'>
                <span>setting PlaceHolder</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Form className='mb-4'>
                <div
                  style={{ marginTop: "20px" }}
                  className='d-flex align-items-center justify-content-between'
                >
                  <Label className='text-sm mr-1'>Placeholders</Label>
                  {/* <span
                                        onSubmit={handleSubmit}
                                        className='text-xs text-primary  cursor-pointer'
                                    >
                                        <i className='fa fa-plus text-xs'></i> Add placeholders
                                        here.
                                    </span> */}
                </div>

                <div className='d-flex flex-column col-12'>
                  <div className='row'>
                    <div className='d-flex flex-column col-4'>
                      <FormGroup>
                        <Label for='key'>Key</Label>
                        <Input
                          id='key'
                          label='Key'
                          type='text'
                          name='key'
                          placeholder='Enter Key'
                          value={key}
                          onChange={handleKeyChange}
                        />
                      </FormGroup>
                    </div>
                    <div className='d-flex flex-column col-4'>
                      <FormGroup>
                        <Label for='defaultValue'>Value</Label>
                        <Input
                          id='defaultValue'
                          label='Value'
                          type='text'
                          name='defaultValue'
                          placeholder='Enter Value'
                          value={defaultValue}
                          onChange={handleDefaultValueChange}
                        />
                      </FormGroup>
                    </div>
                    <div className='d-flex flex-column col-4'>
                      <br></br>
                      <span className='text-xs text-primary  cursor-pointer'>
                        <i className='fa fa-plus text-xs'></i>{" "}
                        <a onClick={handleSubmit}>Add placeholders here.</a>
                      </span>
                    </div>
                  </div>
                </div>
              </Form>
              <div className='d-flex flex-column align-items-center col-12'>
                <div className='row'>
                  <ListGroup>
                    {Object.entries(data).map(([k, v]) => (
                      <ListGroupItem
                        key={k}
                        className='d-flex justify-content-between align-items-center'
                      >
                        <div className='d-flex flex-column col-4'>
                          <Label for='key'>Key</Label>
                          <Input
                            id='key'
                            label='Key'
                            type='text'
                            name='key'
                            //placeholder='Enter Key'
                            value={k}
                            //onChange={handleKeyChange}
                          />
                        </div>
                        <div className='d-flex flex-column col-4'>
                          <Label for='key'>Value</Label>
                          <Input
                            id='value'
                            label='Value'
                            type='text'
                            name='value'
                            //placeholder='Enter Key'
                            value={v}
                            //onChange={handleKeyChange}
                          />
                        </div>

                        {/* {k}: {v} */}
                        <div className='d-flex flex-column col-4'>
                          <Button
                            color='error'
                            variant='text'
                            onClick={() => handleDelete(k)}
                          >
                            <i className='fa fa-trash'></i>
                          </Button>
                        </div>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </AccordionDetails>
            <AccordionActions>
              <div
                className='btn btn-primary'
                id='newSession'
                onClick={handleSave}
              >
                {" "}
                Save
              </div>
            </AccordionActions>
          </Accordion>
        </div>
      </Drawer>
    </>
  );
};
