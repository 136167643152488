export const imageFileTypes = [".png", ".jpeg", ".jpg"];
export const documentsFileTypes = [".pdf"];
export const audioFileTypes = [
  ".aac",
  ".flac",
  ".mp3",
  ".m4a",
  ".mpeg",
  ".mpga",
  ".mp4",
  ".opus",
  ".pcm",
  ".wav",
  ".webm",
];
export const videoFileTypes = [
  ".x-flv",
  ".mov",
  ".mpeg",
  ".mpegps",
  ".mpg",
  ".mp4",
  ".webm",
  ".wmv",
  ".3gpp",
];
