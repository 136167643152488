import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useNavigate } from "react-router-dom";
import { useChatStore } from "store/chat/chat.store";
import { SnackbarManager } from "utils";

export const ChatToolBar: React.FC = () => {
  const [clearChat, consumerSelected, session] = useChatStore((state) => [
    state.clearChat,
    state.consumerSelected,
    state.session,
  ]);
  const navigate = useNavigate();

  const handleNewSession = () => {
    clearChat();
    SnackbarManager.success("New session created.");
  };
  return (
    <AppBar
      position='static'
      color='primary'
      elevation={1}
      sx={{ minWidth: "100%" }}
    >
      <Toolbar>
        <IconButton
          onClick={() => navigate(-1)}
          edge='start'
          color='inherit'
          aria-label='back'
        >
          <ArrowBackIcon />
        </IconButton>
        <Stack
          sx={{ flexGrow: 1 }}
          direction='column'
          spacing={0}
          alignItems='start'
        >
          <Typography color='white' variant='subtitle1'>
            Playground with you agents here
          </Typography>
          <Typography color='white' className='text-xs'>
            Current agent: [ {consumerSelected?.botHumanName} ]
          </Typography>
          <Typography color='white' className='text-xs'>
            Current session: [ {session} ]
          </Typography>
        </Stack>

        <Button
          variant='contained'
          onClick={handleNewSession}
          size='small'
          color='info'
        >
          Create New session
        </Button>
      </Toolbar>
    </AppBar>
  );
};
