import { create } from "zustand";

interface State {
  expanded: boolean | string;
  setExpanded: (value: any) => void;
}

export const useDrawerStore = create<State>((set, get) => ({
  expanded: false,
  setExpanded: (value: any) => {
    set({ expanded: value });
  },
}));
