import { type ProviderContext, useSnackbar, type BaseVariant } from "notistack";

let snackbarRef: ProviderContext;

export const SnackbarUtilitiesConfigurator: React.FC = () => {
  snackbarRef = useSnackbar();
  return null;
};

export const SnackbarManager = {
  toast(msg: string, variant: BaseVariant = "default") {
    snackbarRef.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  },
  close() {
    snackbarRef.closeSnackbar();
  },
  success(msg: string) {
    this.toast(msg, "success");
  },
  error(msg: string) {
    this.toast(msg, "error");
  },
  info(msg: string) {
    this.toast(msg, "info");
  },
  warning(msg: string) {
    this.toast(msg, "warning");
  },
};
