import { create } from "zustand";
import { DocumentStatusEnum } from "./index-documents.store";

interface State {
  files: any[];
  setFiles: (files: any[]) => void;
  getNotRemovedFiles: () => any[];
  changeFileStatus: (name: string, status: DocumentStatusEnum) => void;
}
export const useFilesStore = create<State>((set, get) => ({
  files: [],
  setFiles: (files: any[]) => set({ files }),
  getNotRemovedFiles: () =>
    get().files.filter((file) => file.status === DocumentStatusEnum.ok),
  changeFileStatus: (name: string, status: DocumentStatusEnum) => {
    const documents = get().files;
    const index = documents.findIndex(
      (doc) => doc.name === name && doc.status === DocumentStatusEnum.ok
    );
    if (index === -1) {
      return; 
    }
    documents[index].status = status;
    set({ files: documents });
  },
}));
