import DefaultContainer from "components/DefaultContainer";
import AxmosInput from "components/Form/AxmosInput";
import React, { FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import { useConsumerStore } from "store/consumers/consumer.store";
import { useEmbeddingStore } from "store/embeddings/embedding.store";
import { SnackbarUtilitiesConfigurator } from "utils";
import Prompts from "./components/Prompts";
import CreatePromptModal from "./components/modal/CreatePromptModal";
import ModelParams from "./create/ModelParams";
import SimpleHeader from "components/Headers/SimpleHeader";

interface Props {
  onSubmit?: () => void;
  children: React.ReactNode;
}

const MasterConsumer: React.FC<Props> = ({ onSubmit, children }) => {
  const { id } = useParams();

  const [consumer, setConsumer] = useConsumerStore((state) => [
    state.consumer,
    state.setConsumer,
  ]);

  const [indexes, getAllIndexes] = useEmbeddingStore((state) => [
    state.indexes,
    state.getAllIndexes,
  ]);

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    if (onSubmit) onSubmit();
  };

  useEffect(() => {
    getAllIndexes();
  }, [getAllIndexes]);

  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <div className=''>
      <SnackbarUtilitiesConfigurator />
      <CreatePromptModal openModal={openModal} setOpenModal={setOpenModal} />
      <SimpleHeader
        name={id ? "Edit Agent" : "Create Agent"}
        parentName='Agents'
      />
      <div className='p-3'>
        <DefaultContainer title={id ? "Update your agent" : "Create Agent"}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <AxmosInput
                  required={true}
                  value={consumer.botHumanName}
                  id='botName'
                  label='Bot Name'
                  placeholder='Type your bot name here'
                  name='Bot Name'
                  type='text'
                  onInput={(value) =>
                    setConsumer({
                      ...consumer,
                      botHumanName: value,
                    })
                  }
                />
              </Col>
              <Col>
                <AxmosInput
                  value={consumer.indexName}
                  style={{ cursor: "pointer" }}
                  id='indexName'
                  label='Documents store'
                  placeholder='Type your index name here'
                  name='indexName'
                  type='select'
                  onInput={(value) =>
                    setConsumer({
                      ...consumer,
                      indexName: value,
                    })
                  }
                >
                  {indexes.map((i, index) => (
                    <option key={index} value={i.indexName}>
                      {i.indexName}
                    </option>
                  ))}
                </AxmosInput>
              </Col>
            </Row>
            <Row>
              <Col>
                <AxmosInput
                  required={true}
                  value={consumer.welcomeMessage}
                  id='welcomeMessage'
                  name='welcomeMessage'
                  type='textarea'
                  rows='5'
                  label='Welcome Message'
                  placeholder='Type your welcome message here'
                  onInput={(value) => {
                    setConsumer({
                      ...consumer,
                      welcomeMessage: value,
                    });
                  }}
                />
              </Col>
            </Row>
            <ModelParams />
            <Row>
              <Prompts openPromptModal={() => setOpenModal(true)} />
            </Row>
            <Row>
              <Col className='my-2'>{children}</Col>
            </Row>
          </Form>
        </DefaultContainer>
      </div>
    </div>
  );
};

export default MasterConsumer;
