import React, { PropsWithChildren, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Backdrop,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Button,
} from "@mui/material";
import { Close, Description, Error } from "@mui/icons-material";
import { Prompt } from "models";

interface LogTableProps {
  logs: any[];
}

const StyledTableCell: React.FC<PropsWithChildren> = ({ children }) => (
  <TableCell
    sx={{
      color: "#212121",
      fontWeight: "bold",
      fontSize: "12px",
      backgroundColor: "#f6f9fc",
    }}
  >
    {children}
  </TableCell>
);

const LogsTable: React.FC<LogTableProps> = ({ logs }) => {
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [openPromptBackdrop, setOpenPromptBackdrop] = useState<boolean>(false);
  const [selectedDoc, setSelectedDoc] = useState<any | null>(null);
  const [promptSelected, setPromptSelected] = useState<Prompt | null>(null);

  const handleOpenBackdrop = (doc: any) => {
    setSelectedDoc(doc);
    setOpenBackdrop(true);
  };

  const handleOpenPromptBackdrop = (prompt: Prompt) => {
    setPromptSelected(prompt);
    setOpenPromptBackdrop(true);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
    setSelectedDoc(null);
  };

  const handleClosePromptBackdrop = () => {
    setOpenPromptBackdrop(false);
    setPromptSelected(null);
  };

  return (
    <>
      {false && <LinearProgress sx={{ mx: 2 }} color='primary' />}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openPromptBackdrop}
      >
        {promptSelected && (
          <Box
            sx={{
              p: 3,
              backgroundColor: "white",
              borderRadius: 2,
              boxShadow: 24,
              minWidth: 300,
              maxWidth: 500,
              overflow: "hidden",
              color: "black",
              position: "relative",
            }}
          >
            <IconButton
              onClick={handleClosePromptBackdrop}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "grey.600",
              }}
            >
              <Close />
            </IconButton>

            <Typography variant='h6' component='h2' gutterBottom>
              {promptSelected.name}
            </Typography>
            <Box sx={{ maxHeight: 500, overflowY: "auto" }}>
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{
                  textWrap: "wrap",
                  whiteSpace: "pre-wrap",
                }}
                component='p'
                gutterBottom
              >
                {promptSelected.content.prompt}
              </Typography>
            </Box>

            <Button
              variant='contained'
              color='primary'
              onClick={handleClosePromptBackdrop}
              fullWidth
              sx={{ mt: 2 }}
            >
              Cerrar
            </Button>
          </Box>
        )}
      </Backdrop>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        {selectedDoc && (
          <Box
            sx={{
              p: 3,
              backgroundColor: "white",
              borderRadius: 2,
              boxShadow: 24,
              minWidth: 300,
              maxWidth: 500,
              color: "black",
              position: "relative",
            }}
          >
            <IconButton
              onClick={handleCloseBackdrop}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "grey.600",
              }}
            >
              <Close />
            </IconButton>

            <Typography variant='h6' component='h2' gutterBottom>
              {selectedDoc.source}
            </Typography>
            <Typography
              variant='body2'
              color='text.secondary'
              component='p'
              gutterBottom
            >
              {selectedDoc.text}
            </Typography>

            <Button
              variant='contained'
              color='primary'
              onClick={handleCloseBackdrop}
              fullWidth
              sx={{ mt: 2 }}
            >
              Cerrar
            </Button>
          </Box>
        )}
      </Backdrop>

      <TableContainer
        id='logs-table'
        elevation={0}
        component={Paper}
        sx={{
          minWidth: "100%",
          borderRadius: 0,
          overflowY: "auto",
          marginBottom: 10,
        }}
      >
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Session</StyledTableCell>
              <StyledTableCell>Consumer</StyledTableCell>
              <StyledTableCell>Message</StyledTableCell>
              <StyledTableCell>Prompt</StyledTableCell>
              <StyledTableCell>Input Tokens</StyledTableCell>
              <StyledTableCell>Output Tokens</StyledTableCell>
              <StyledTableCell>Model Params</StyledTableCell>
              <StyledTableCell>Model Time (seconds)</StyledTableCell>
              <StyledTableCell>Integrations</StyledTableCell>
              <StyledTableCell>Integrations Time (seconds)</StyledTableCell>
              <StyledTableCell>Docs</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log) => (
              <TableRow hover className='cursor-pointer' key={log.id}>
                <TableCell
                  className='text-xs'
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <i className='text-primary'>{log.date}</i>
                </TableCell>
                <TableCell
                  className='text-xs'
                  sx={{ color: "#212121", fontFamily: "Poppins, sans-serif" }}
                >
                  {log.details.session}
                </TableCell>
                <TableCell
                  className='text-xs'
                  sx={{ color: "#212121", fontFamily: "Poppins, sans-serif" }}
                >
                  {log.consumerName}
                </TableCell>
                <TableCell
                  title={log.message}
                  className='text-xs text-truncate'
                  sx={{
                    color: "#212121",
                    fontFamily: "Poppins, sans-serif",
                    maxWidth: "25ch",
                  }}
                >
                  {log.message}
                </TableCell>
                <TableCell
                  className='text-xs text-truncate'
                  sx={{ color: "#212121", fontFamily: "Poppins, sans-serif" }}
                >
                  {log.prompt.name}
                  <i
                    onClick={() => handleOpenPromptBackdrop(log.prompt)}
                    className='fa fa-eye text-xs text-primary ml-1'
                  ></i>
                </TableCell>

                <TableCell
                  className='text-xs'
                  sx={{ color: "#212121", fontFamily: "Poppins, sans-serif" }}
                >
                  {log.details.prompt_token_count}
                </TableCell>
                <TableCell
                  className='text-xs'
                  sx={{ color: "#212121", fontFamily: "Poppins, sans-serif" }}
                >
                  {log.details.candidates_token_count}
                </TableCell>
                <TableCell
                  className='text-xs'
                  sx={{ color: "#212121", fontFamily: "Poppins, sans-serif" }}
                >
                  <ul
                    style={{
                      listStyleType: "none",
                      paddingLeft: 0,
                      fontSize: 10,
                    }}
                  >
                    <li>Model: {log.modelParams.model}</li>
                    <li>Temperature: {log.modelParams.temperature}</li>
                    <li>Top P: {log.modelParams.topP}</li>
                    <li>Top K: {log.modelParams.topK}</li>
                  </ul>
                </TableCell>
                <TableCell
                  className='text-xs'
                  sx={{ color: "#212121", fontFamily: "Poppins, sans-serif" }}
                >
                  {log.totalTime}
                </TableCell>
                <TableCell
                  className='text-xs'
                  sx={{ color: "#212121", fontFamily: "Poppins, sans-serif" }}
                >
                  {log.details.integrationData
                    ? JSON.stringify(log.details.integrationsData)
                    : ""}
                </TableCell>
                <TableCell
                  className='text-xs'
                  sx={{ color: "#212121", fontFamily: "Poppins, sans-serif" }}
                >
                  {Math.round(
                    (log.details.integrations_time_taken || 0) * 1000
                  ) / 1000}
                </TableCell>
                <TableCell>
                  {log.details?.docs?.map((doc: any, index: number) => (
                    <Tooltip
                      key={index}
                      title={
                        doc.text ? "Show details" : "Document not available"
                      }
                    >
                      <IconButton
                        onClick={(e) => handleOpenBackdrop(doc)}
                        disabled={!doc.text}
                        color={doc.text ? "primary" : "error"}
                      >
                        {doc.text ? <Description /> : <Error />}
                      </IconButton>
                    </Tooltip>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LogsTable;
