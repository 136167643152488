import { Prompt, PromptUpdateAndCreate } from "models";
import { getAllPrompts } from "services/prompts/get-all";
import { deletePrompt } from "services/prompts/delete";
import { createPrompt } from "services/prompts/create";
import { getPromptById } from "services/prompts/get-by-id";

import { create } from "zustand";
import { SnackbarManager, formatPlaceholders } from "utils";
import { updatePrompt } from "services/prompts/update";
import moment from "moment";
import { SafetySettingsEnum, defaultModelParams } from "models/prompts";
import { convertMarkdownToHtml, htmlToMarkdown } from "components/Parser";

export const promptInitialState: PromptUpdateAndCreate = {
  description: "",
  content: {
    type: "",
    actAs: "",
    defaultFormat: undefined,
    defaultLanguage: undefined,
    modelType: "text",
    prompt: "",
  },
  created: new Date().toISOString(),
  integrations: [],
  modelParams: { model: "", ...defaultModelParams },
  promptId: undefined,
  indexName: "",
  name: "",
  placeholders: {},
};

interface State {
  prompts: Prompt[];
  filteredPrompts: Prompt[];
  prompt: PromptUpdateAndCreate;
  placeholdersList: Array<any>;
  setPlaceholdersList: (placeholdersList: Array<any>) => void;
  getAll: () => Promise<void>;
  setPrompt: (prompt: PromptUpdateAndCreate) => void;
  deletePrompt: (promptId: string) => Promise<any>;
  getPromptById: (promptId: string) => Promise<any>;
  updatePrompt: (prompt: PromptUpdateAndCreate) => Promise<any>;
  createPrompt: (prompt: PromptUpdateAndCreate) => Promise<any>;
  getPromptByName: (name?: string) => void;
  resetPrompts: () => void;
  setFilters(
    filters: Partial<{ type: string; model: string; name: string }>
  ): void;
}

export const usePromptsStore = create<State>((set, get) => {
  return {
    prompts: [],
    filteredPrompts: [],
    prompt: promptInitialState,
    placeholdersList: [],
    setPrompt: (prompt: PromptUpdateAndCreate) => set({ prompt }),
    setPlaceholdersList: (placeholdersList: Array<any>) =>
      set({ placeholdersList }),
    getAll: async () => {
      const { data } = await getAllPrompts();
      const sorted = data
        .sort(
          (a: Prompt, b: Prompt) =>
            new Date(b.created!).getTime() - new Date(a.created!).getTime()
        )
        .map((prompt: Prompt) => ({
          ...prompt,
          updated: prompt.updated
            ? moment(prompt.updated).format("DD/MM/YYYY")
            : null,
          created: prompt.created
            ? moment(prompt.created).format("DD/MM/YYYY")
            : null,
        }));

      set({ prompts: sorted, filteredPrompts: sorted });
    },
    getPromptById: async (promptId: string) => {
      try {
        const { data } = await getPromptById(promptId);
        const newIntegrations = Object.hasOwn(data, "integrations")
          ? data.integrations
          : [];
        const newPlaceholders = Object.hasOwn(data, "placeholders")
          ? Object.entries(data.placeholders).map(([key, value]) => ({
              key,
              value,
            }))
          : [];
        const modelParams = Object.hasOwn(data, "modelParams")
          ? data.modelParams
          : {
              model: "",
              temperature: 0,
              maxOutputTokens: 0,
              topP: 0,
              topK: 0,
              response_mime_type: "text",
              safetySettings: {
                hateSpeech: SafetySettingsEnum.BlockSome,
                dangerousContent: SafetySettingsEnum.BlockSome,
                sexuallyExplicitContent: SafetySettingsEnum.BlockSome,
                harassmentContent: SafetySettingsEnum.BlockSome,
              },
            };
        const _prompt = await convertMarkdownToHtml(data.content.prompt);
        set({
          placeholdersList: newPlaceholders,
          prompt: {
            ...data,
            integrations: newIntegrations,
            content: {
              ...data.content,
              prompt: _prompt,
              type: data.content.type || "",
            },
            modelParams,
          },
        });

        return data as Prompt;
      } catch (error) {
        SnackbarManager.error("Failed to get prompt");
        return error;
      }
    },
    updatePrompt: async (prompt: PromptUpdateAndCreate) => {
      const { promptId } = prompt;
      try {
        return await updatePrompt(promptId as string, {
          ...prompt,
          placeholders: formatPlaceholders(get().placeholdersList),
          content: {
            ...prompt.content,
            prompt: htmlToMarkdown(prompt.content.prompt),
          },
        });
      } catch (error) {
        SnackbarManager.error("Prompt updated error.");
        console.log(error);
      }
    },
    createPrompt: async (prompt: PromptUpdateAndCreate) => {
      const parsePrompt = {
        ...prompt,
        placeholders: formatPlaceholders(get().placeholdersList),
        content: {
          ...prompt.content,
          prompt: htmlToMarkdown(prompt.content.prompt),
        },
      };
      return await createPrompt(parsePrompt);
    },
    deletePrompt: async (promptId: string) => {
      try {
        const response = await deletePrompt(promptId);

        const { prompts } = get();

        const newPrompts = prompts.filter(
          (prompt) => prompt.promptId !== promptId
        );

        set({ prompts: newPrompts });

        return response;
      } catch (error) {
        SnackbarManager.error("Failed to delete prompt");
        console.log(error);
        return error;
      }
    },
    getPromptByName: (name?: string) => {
      const { prompts } = get();

      if (!name) return prompts;

      const filteredPrompts = prompts.filter((prompt) =>
        prompt.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
      );
      set({ filteredPrompts });
    },

    resetPrompts: () => {
      const { prompts } = get();
      set({ filteredPrompts: prompts });
    },

    setFilters: (filter: string) => {
      const { prompts } = get();
      if (!filter) {
        set({ filteredPrompts: prompts });
        return;
      }
      const lowercasedTerm = filter.toLowerCase();
      const filtered = prompts.filter((prompt) => {
        return (
          prompt.name.toLowerCase().includes(lowercasedTerm) ||
          prompt.content.type?.toLowerCase().includes(lowercasedTerm) ||
          (prompt.modelParams?.model?.toLowerCase() ?? "").includes(
            lowercasedTerm
          )
        );
      });

      set({ filteredPrompts: filtered });
    },
  };
});
