import React, { useState, useRef, useEffect } from "react";
import {
  Paper,
  Box,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import { MinimizeRounded, MaximizeRounded } from "@mui/icons-material";

interface ResizablePanelProps {
  children: React.ReactNode;
  initialHeight?: number;
  logsCount: number;
}

const ResizablePanel: React.FC<ResizablePanelProps> = ({
  children,
  initialHeight = 40,
  logsCount,
}) => {
  const [height, setHeight] = useState(initialHeight);
  const panelRef = useRef<HTMLDivElement | null>(null);
  const paperContainer = useRef<HTMLDivElement | null>(null);
  const isResizing = useRef(false);

  const handleMouseDown = (e: React.MouseEvent) => {
    isResizing.current = true;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizing.current || !panelRef.current) return;

    const newHeight = window.innerHeight - e.clientY;

    if (newHeight > 40 && newHeight < window.innerHeight - 100) {
      setHeight(newHeight);
    }
  };

  const handleMouseUp = () => {
    isResizing.current = false;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <Box
      ref={panelRef}
      sx={{
        width: "100%",
        height: `${height}px`,
        position: "sticky",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        borderTop: "1px solid #ddd",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          width: "50px",
          height: "5px",
          margin: "0 -5px",
          cursor: "ns-resize",
          backgroundColor: "#ddd",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          borderRadius: "5px",
          marginTop: 0.5,
          top: 0,
          zIndex: 10,
        }}
        onMouseDown={handleMouseDown}
      />

      <AppBar
        position='static'
        color='primary'
        elevation={5}
        sx={{ minWidth: "100%" }}
      >
        <Toolbar variant='dense'>
          <Typography color='white' variant='subtitle1' sx={{ flexGrow: 1 }}>
            Debugger ({logsCount})
          </Typography>
          <IconButton
            color='inherit'
            onClick={() => setHeight(initialHeight)}
            aria-label='menu'
          >
            <MinimizeRounded />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Paper
        sx={{
          minWidth: "100%",
          height: `calc(100% - 1px)`,
          overflow: "auto",
          borderRadius: "0",
        }}
        ref={paperContainer}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default ResizablePanel;
