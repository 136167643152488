import { Index } from "models";
import { createIndex } from "services/embeddings/create-index";
import { getAllIndex } from "services/embeddings/get-all-index";
import { getIndexDocuments } from "services/embeddings/get-index-documents";
import { create } from "zustand";
import useSettingsStore from 'store/embeddings/settings.store';

export type Documents = {
  indexName: string;
  files: { name: string; file?: File }[];
};
interface State {
  documents: Documents;
  setDocuments: (documents: Documents) => void;
  indexes: Index[];
  indexDocuments: Index["documents"];
  getAllIndexes: () => Promise<void>;
  uploadFiles: (
    documents?: {
      indexName: string;
      files: { name: string; file?: File }[];
    },
    newIndexName?: string,
    settings?: { 
      textChunkSize: number, 
      chunkOverlap: number, 
      delimiter: string, 
      findTextJson: string | null } 
  ) => Promise<any>;
  getIndexDocuments: (indexName: string) => Promise<any>;
  purpose: string;
  setPurpose: (purpose: string) => void;
}

export const useEmbeddingStore = create<State>((set, get) => {
  return {
    indexDocuments: [],
    documents: { indexName: "", files: [] },
    indexes: [],
    purpose: "",
    getAllIndexes: async () => {
      try {
        const { data } = await getAllIndex();
        set({
          indexes: [{ indexName: "", documents: [] }, ...data].sort((a, b) =>
            a.indexName.localeCompare(b.indexName)
          ),
        });
      } catch (error) {
        set({ indexes: [] });
        console.log(error);
      }
    },
    setPurpose: (purpose: string) => set({ purpose }),
    setDocuments: (documents: Documents) => set({ documents }),
    uploadFiles: async (
      _documents?: {
        indexName: string;
        files?: { name: string; file?: File }[];
      },
      newIndexName?: string,
      settings?: { 
        textChunkSize: number, 
        chunkOverlap: number, 
        delimiter: string, 
        findTextJson: string | null } //Parameters extracted of settings.store.ts
    ) => {
      try {
        const documents = _documents ? _documents : get().documents;

        const iName = _documents?.indexName
          ? _documents.indexName
          : (newIndexName as string);

        const formData = new FormData();

        formData.append("indexName", iName);
        documents.files?.forEach(({ file }) => {
          formData.append("document", file as File);
        });
        formData.append("bucket", "");
        if (settings) {
          formData.append("textChunkSize", settings.textChunkSize.toString());
          formData.append("chunkOverlap", settings.chunkOverlap.toString());
          formData.append("delimiter", settings.delimiter);
          if (settings.findTextJson) {
            formData.append("findTextJson", settings.findTextJson);
          }
        }
        formData.append("purpose", get().purpose);

        return await createIndex(formData);
      } catch (error) {
        console.log("An error ocurred during file upload:",error);
        throw new Error("Error uploading files. Please try again later.");
      }
    },

    getIndexDocuments: async (indexName: string) => {
      try {
        const { data }: { data: Index } = await getIndexDocuments(indexName);
        set({
          indexDocuments: data.documents,
        });
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  };
});
