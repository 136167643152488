import { ConsumerResponse, Response } from "models";
import { useEffect, useState } from "react";
import DataTable, {
  TableColumn,
  TableStyles,
} from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Button, Fade } from "reactstrap";
import { deleteConsumer, getAllConsumers } from "services/consumers";
import Swal from "sweetalert2";
import { SnackbarUtilitiesConfigurator } from "utils";
import { ConsumerAdapter } from "../adapters/consumer.adapter";
import DefaultContainer from "components/DefaultContainer";
import SimpleHeader from "components/Headers/SimpleHeader";
import AxmosInput from "components/Form/AxmosInput";

const ConsumerIndex = () => {
  const [consumers, setConsumers] = useState([] as any);
  const [filteredConsumers, setFilteredConsumers] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();

  const getConsumers = async () => {
    setLoading(true);
    try {
      const { data } = await getAllConsumers();
      setConsumers(ConsumerAdapter(data));
      setFilteredConsumers(ConsumerAdapter(data));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setFilters = (filter: string) => {
    if (!filter) {
      setFilteredConsumers(consumers);
      return;
    }
    const lowercasedTerm = filter.toLowerCase();
    const filtered = consumers.filter((consumer: Response) => {
      return (
        consumer.botHumanName.toLowerCase().includes(lowercasedTerm) ||
        consumer.modelParams?.model?.toLowerCase().includes(lowercasedTerm) ||
        consumer.indexName.toLowerCase().includes(lowercasedTerm)
      );
    });

    setFilteredConsumers(filtered);
  };

  const handleDelete = async (consumerId: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = (await deleteConsumer(consumerId)) as {
          data: { result: boolean };
          message: string;
        };
        if (data.result) {
          getConsumers();
          Swal.fire("Deleted!", "Your agent has been deleted.", "success");
        }
      }
    });
  };

  const customStyles: TableStyles = {
    headCells: {
      style: {
        backgroundColor: "#f6f9fc",
        color: "#8898aa",
        fontWeight: "semibold",
        textTransform: "uppercase",
        fontSize: "11px",
      },
    },
  };

  const columns: TableColumn<Response>[] = [
    {
      name: "Bot Human name",
      sortable: true,
      selector: (row) => row.botHumanName,
    },
    {
      name: "Model",
      sortable: true,
      selector: (row) => row.modelParams.model as string,
    },
    {
      name: "Document store name",
      sortable: true,
      selector: (row) => row.indexName || "-",
      maxWidth: "250px",
    },

    {
      name: "Total prompts",
      sortable: true,
      selector: (row) =>
        `${row.prompts?.length || 0} ${
          row.prompts?.length === 1 ? "prompt" : "prompts"
        } attached`,
    },
    {
      name: "Created at",
      sortable: true,
      selector: (row) => (row.created as string) || "-",
    },
    {
      name: "Updated at",
      sortable: true,
      selector: (row) => (row.updated as string) ?? "-",
    },
    {
      name: "Actions",
      maxWidth: "250px",
      cell: (row) => (
        <div>
          <Button
            title='Edit'
            onClick={() => navigation(`/admin/agents/edit/${row.consumerId}`)}
            color='primary'
            size='sm'
          >
            <i className='fa fa-edit'></i>
          </Button>
          <Button
            title='Delete'
            onClick={() => handleDelete(row.consumerId)}
            color='danger'
            size='sm'
          >
            <i className='fa fa-trash'></i>
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getConsumers();
  }, []);

  return (
    <>
      <SimpleHeader name='List Agents' parentName='Agents' />
      <section className='p-3 d-flex flex-column'>
        <Fade>
          <DefaultContainer title='Agents'>
            <SnackbarUtilitiesConfigurator />
            <div className='d-flex justify-content-start'>
              <AxmosInput
                label='Search'
                tooltipMessage='Search by bot human name, document store name or model'
                style={{ width: "300px" }}
                placeholder='Type anything to search'
                name='filter'
                onInput={(value) => setFilters(value)}
                type='text'
              ></AxmosInput>
            </div>
            <DataTable
              pointerOnHover
              striped
              onRowClicked={(row) =>
                navigation(`/admin/agents/edit/${row.consumerId}`)
              }
              highlightOnHover
              columns={columns}
              pagination
              progressPending={loading}
              data={filteredConsumers}
              responsive
              customStyles={customStyles}
            ></DataTable>
          </DefaultContainer>
        </Fade>
      </section>
    </>
  );
};

export default ConsumerIndex;
