import { Prompt } from "models";
import React from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import ReactMarkdown from "react-markdown";

interface Props {
  prompt: Prompt | null;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ParagrapComponent = ({
  title,
  content,
}: {
  title: string;
  content: string | React.ReactNode;
}) => {
  return (
    <div className=' my-2'>
      <span className='text-sm'>
        <b>{title}: </b>
      </span>
      <span className='text-sm' style={{ whiteSpace: "pre-wrap" }}>
        {content}
      </span>
    </div>
  );
};

export const PromptModalDetail: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  prompt,
}) => {
  return (
    <>
      {prompt && (
        <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
          <ModalHeader>Prompt Details</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <ParagrapComponent
                  title='Description'
                  content={prompt.description ?? ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ParagrapComponent
                  title='Act as'
                  content={prompt.content.actAs ?? ""}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <ParagrapComponent
                  title='Prompt'
                  content={
                    <ReactMarkdown className='text-sm'>
                      {prompt.content.prompt || ""}
                    </ReactMarkdown>
                  }
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color='danger' onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
