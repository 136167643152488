import React from "react";
import { CloseRounded } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import {
  audioFileTypes,
  imageFileTypes,
  videoFileTypes,
} from "consts/FileTypes.const";
import { convertBytes } from "utils";
import { TypeWithKey } from "models";

const MediaPreview: React.FC<{ file: File; fileType?: string }> = ({
  file,
}) => {
  const fileExtension = (file.name.split(".").pop() || "").toLowerCase();
  if (imageFileTypes.includes("." + fileExtension)) {
    return (
      <>
        <img
          src={URL.createObjectURL(file)}
          alt='preview'
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </>
    );
  } else if (audioFileTypes.includes("." + fileExtension)) {
    return (
      <audio
        controls
        style={{
          position: "absolute",
          width: "300px",
          backgroundColor: "#f9f9f9",
          padding: "5px 0",
        }}
      >
        <source src={URL.createObjectURL(file)} type={file.type} />
        Your browser does not support the audio element.
      </audio>
    );
  } else if (videoFileTypes.includes("." + fileExtension)) {
    return (
      <video controls style={{ width: "100%", maxHeight: "40px" }}>
        <source src={URL.createObjectURL(file)} type={file.type} />
        Your browser does not support the video tag.
      </video>
    );
  }

  return <FileIcon fileExtension={fileExtension} />;
};

const FileIcon: React.FC<{ fileExtension: string }> = ({ fileExtension }) => {
  const iconClasses: TypeWithKey<string> = {
    pdf: "fas fa-file-pdf",
    csv: "fas fa-file-csv",
    json: "fas fa-file-code",
    default: "fas fa-file",
  };

  const iconClass = iconClasses[fileExtension] || iconClasses.default;

  return (
    <div className='text-center'>
      <i className={`${iconClass}`} />
    </div>
  );
};

const FileViewer: React.FC<{
  file: File;
  onDelete: () => void;
  showClose?: boolean;
}> = ({ file, onDelete, showClose = true }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f9f9f9",
        borderRadius: "12px",
        padding: "8px",
        width: `${file.type.includes("wav") ? "420px" : "250px"}`,
        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        position: "relative",
      }}
    >
      <Avatar
        variant='rounded'
        alt='File thumbnail'
        sx={{
          width: `${file.type.includes("wav") ? "300px" : "70px"}`,
          height: 50,
          marginRight: "10px",
          position: "relative",
        }}
      >
        <MediaPreview file={file} />
      </Avatar>

      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Typography
          variant='body2'
          noWrap
          sx={{ fontWeight: "bold", maxWidth: "180px" }}
        >
          {file.name}
        </Typography>
        <Typography variant='caption' sx={{ color: "#9e9e9e" }}>
          {convertBytes(file.size, "MB").toFixed(2)} MB
        </Typography>
      </Box>

      {showClose && (
        <IconButton
          size='small'
          onClick={onDelete}
          sx={{
            position: "absolute",
            top: "4px",
            right: "4px",
          }}
        >
          <CloseRounded fontSize='small' />
        </IconButton>
      )}
    </Box>
  );
};

export default FileViewer;
