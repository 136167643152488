import { Prompt } from "models";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Input,
  Row,
  ModalFooter,
} from "reactstrap";
import { useConsumerStore } from "store/consumers/consumer.store";
import { usePromptsStore } from "store/prompts/prompts.store";
import "./Prompts.css";
import ReactMarkdown from "react-markdown";

interface Props {
  openPromptModal: () => void;
}

const Prompts: React.FC<Props> = ({ openPromptModal }) => {
  const [
    prompts,
    getAllPrompts,
    getPromptByName,
    filteredPrompts,
    resetPrompts,
  ] = usePromptsStore((state) => [
    state.prompts,
    state.getAll,
    state.getPromptByName,
    state.filteredPrompts,
    state.resetPrompts,
  ]);

  useEffect(() => {
    getAllPrompts();
  }, [getAllPrompts]);

  const [consumer, setConsumer] = useConsumerStore((state) => [
    state.consumer,
    state.setConsumer,
  ]);

  const [selectedPrompts, setSelectedPrompts] = useState<Prompt[]>([]);
  const [defaultPrompt, setDefaultPrompt] = useState<string>("");

  useEffect(() => {
    const selected = consumer.prompts
      .map((p) => prompts.find((prompt) => prompt.promptId === p))
      .filter((p): p is Prompt => p !== undefined);

    setSelectedPrompts(selected);
    setDefaultPrompt(consumer.defaultPromptId);
  }, [consumer, prompts]);

  const findPrompt = (prompt: Prompt) =>
    selectedPrompts.find((p) => p.promptId === prompt.promptId);

  const handleSelectPrompt = (prompt: Prompt) => {
    const exists = findPrompt(prompt);
    if (exists) {
      removePrompt(prompt);
      return;
    }
    const updatedPrompts = [...selectedPrompts, prompt];
    setSelectedPrompts(updatedPrompts);
    setConsumer({
      ...consumer,
      prompts: updatedPrompts.map((p) => p.promptId),
    });
  };

  const isDefaultPrompt = (prompt: Prompt) => prompt.promptId === defaultPrompt;

  const removePrompt = (prompt: Prompt) => {
    const isDefault = isDefaultPrompt(prompt);
    if (isDefault) {
      setDefaultPrompt("");
      setConsumer({ ...consumer, defaultPromptId: "" });
    }
    const updatedPrompts = selectedPrompts.filter(
      (p) => p.promptId !== prompt.promptId
    );
    setSelectedPrompts(updatedPrompts);
    setConsumer({
      ...consumer,
      prompts: updatedPrompts.map((p) => p.promptId),
    });
  };

  const setActiveClass = (prompt: Prompt) =>
    findPrompt(prompt) ? "bg-primary rounded text-white font-weight-bold" : "";

  const setDefaultPromptClass = (prompt: Prompt) =>
    defaultPrompt === prompt.promptId ? "border border-success" : "";

  const removeDefaultPrompt = () => {
    setDefaultPrompt("");
    setConsumer({ ...consumer, defaultPromptId: "" });
  };

  const handleSetDefaultPrompt = (promptId: string) => {
    setDefaultPrompt(promptId);
    setConsumer({ ...consumer, defaultPromptId: promptId });
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value) {
      getPromptByName(value);
    } else {
      resetPrompts();
    }
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt>();

  const handleOpenModal = (prompt: Prompt) => {
    setSelectedPrompt(prompt);
    setOpenModal(true);
  };

  return (
    <>
      <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
        <ModalHeader>
          <span>Prompt Details</span>
        </ModalHeader>
        <ModalBody className='text-sm font-weight-bold'>
          <Row>
            <Col>
              <span>Name:</span>
              <p className='text-sm'>{selectedPrompt?.name}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Description:</span>
              <p className='text-sm'>{selectedPrompt?.description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Prompt</span>
              <ReactMarkdown className='text-sm md-prompt'>
                {selectedPrompt?.content.prompt}
              </ReactMarkdown>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={() => setOpenModal(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Col md='6'>
        <div className='d-flex justify-content-between align-items-center'>
          <span className='text-sm'>Prompts ({prompts?.length})</span>
          <span
            onClick={() => openPromptModal()}
            style={{ cursor: "pointer" }}
            className='text-xs text-primary'
          >
            Create a prompt
          </span>
        </div>
        <hr className='m-0 pb-2' />
        <Input
          onInput={(event: any) => handleFilterChange(event)}
          placeholder='Search your prompt'
          className='my-2 text-xs'
          style={{ width: "100%" }}
        />
        <div
          className='overflow-auto px-1 border rounded'
          style={{ height: "250px" }}
        >
          {false ? (
            <div className='d-flex justify-content-center align-items-center w-100'>
              <Spinner color='primary' />
            </div>
          ) : (
            filteredPrompts.map((prompt) => (
              <div style={{ gap: "3px" }} className='d-flex align-items-center'>
                <div
                  style={{ cursor: "pointer", padding: "9px", width: "100%" }}
                  className={`${setActiveClass(
                    prompt
                  )} text-uppercase border-bottom my-1 prompt-item text-xs`}
                  key={prompt.promptId}
                  onClick={() => handleSelectPrompt(prompt)}
                >
                  {prompt.name}
                </div>
                <div>
                  <i
                    onClick={() => handleOpenModal(prompt)}
                    style={{ cursor: "pointer" }}
                    title='See details'
                    className='fa fa-eye'
                  ></i>
                </div>
              </div>
            ))
          )}
        </div>
      </Col>
      <Col>
        <span className='text-sm'>
          Selected Prompts ({selectedPrompts.length})
        </span>
        <hr className='m-0 pb-2' />

        <div
          className='overflow-auto px-1 border rounded'
          style={{ height: "250px" }}
        >
          {selectedPrompts.map((prompt) => (
            <div
              style={{ padding: "4px" }}
              className={`fade-in text-xs border rounded cursor-pointer my-1 prompt-item text-uppercase ${setDefaultPromptClass(
                prompt
              )}`}
              key={prompt.promptId}
            >
              <div
                className={`w-100 d-flex justify-content-between align-items-center`}
              >
                {prompt.name}
                <div className='d-flex align-items-center'>
                  <Button
                    onClick={() => {
                      isDefaultPrompt(prompt)
                        ? removeDefaultPrompt()
                        : handleSetDefaultPrompt(prompt.promptId);
                    }}
                    size='sm'
                    type='button'
                    color='primary'
                    outline
                  >
                    {isDefaultPrompt(prompt)
                      ? "Remove Default"
                      : "Set as default"}
                  </Button>
                  <i
                    className='fa fa-times'
                    onClick={() => removePrompt(prompt)}
                    style={{ cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Col>
    </>
  );
};

export default Prompts;
