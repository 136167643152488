import { create } from "zustand";
import { getAllIntegrations } from "services/intergrations/get-all-integrations";
import { Integration } from "models";
import { createIntegration } from "services/intergrations/create-integration";

interface State {
  integrations: Integration[];
  getAllIntegrations: () => Promise<void>;
  setIntegrations: (index: number, integration: Integration) => void;
  addNewIntegration: (integration: Integration) => void;
  deleteIntegration: (index: number) => void;
  createIntegration: (integration: Integration) => Promise<any>;
  getNewIntegrations: () => Integration[];
}

export const useIntegrationsStore = create<State>((set, get) => ({
  integrations: [],
  createIntegration: async (integration: Integration) => {
    try {
      const { isNew, isOnPrompt, ...rest } = integration;
      const response = await createIntegration(rest);
      return response;
    } catch (error) {
      throw new Error("Error creating integration.");
    }
  },
  getAllIntegrations: async () => {
    const { data } = await getAllIntegrations();
    set({ integrations: data });
  },
  setIntegrations: (index: number, integration: Integration) => {
    const newIntegration = [
      ...get().integrations.slice(0, index),
      integration,
      ...get().integrations.slice(index + 1),
    ];
    set({ integrations: newIntegration });
  },
  deleteIntegration: (index: number) => {
    set({
      integrations: [
        ...get().integrations.slice(0, index),
        ...get().integrations.slice(index + 1),
      ],
    });
  },

  addNewIntegration: (integration: Integration) => {
    set({ integrations: [integration, ...get().integrations] });
  },

  getNewIntegrations: () => {
    return get().integrations.filter((integration) => integration.isNew);
  },
}));
