import { ModelParams, SafetySettings } from "./NewModelParams.model";
import { SafetySettingsEnum } from "./prompts";

export type ConsumerResponse = {
  data: Response[];
  message: string;
};

export type Response = {
  consumerId: string;
  botHumanName: string;
  welcomeMessage: string;
  masterPromptId?: string;
  indexName: string;
  modelParams: ModelParams;
  prompts: string[];
  defaultPromptId: string;
  created: any;
  updated?: string;
};

export interface ConsumerResquestParams {
  botHumanName: string;
  welcomeMessage: string;
  indexName?: string;
  modelParams?: ModelParams;
  prompts: Array<string>;
  defaultPromptId: string;
  masterPromptId?: string;
}

const defaultSafetySettings: SafetySettings = {
  hateSpeech: SafetySettingsEnum.BlockSome,
  dangerousContent: SafetySettingsEnum.BlockSome,
  sexuallyExplicitContent: SafetySettingsEnum.BlockSome,

  harrasmentContent: SafetySettingsEnum.BlockSome,
};

export const initalConsumerState: ConsumerResquestParams = {
  botHumanName: "",
  defaultPromptId: "",
  indexName: "",
  masterPromptId: undefined,
  modelParams: {
    model: "",
    maxOutputTokens: 1024,
    temperature: 0.4,
    topP: 0.2,
    topK: 40,
    response_mime_type: "text",
    safetySettings: defaultSafetySettings,
  },
  prompts: [],
  welcomeMessage: "",
};
