import type { Axios, InternalAxiosRequestConfig } from "axios";
import { getValidationError } from "./get-validation-error";
import { SnackbarManager } from "utils";
import { env } from "../env";

export const AxiosInterceptor = (axios: Axios) => {
  const updateHeaders = (request: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem("token") || "123";

    const _headers = {
      Authorization: token,
    };

    request.params = {
      ...request.params,
      key: env.API_KEY,
    };

    return request;
  };

  axios.interceptors.request.use(
    (request) => {
      return updateHeaders(request);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      SnackbarManager.error(error.response.data.message);
      return Promise.reject(error);
    }
  );
};
