import { Box, ThemeProvider, colors, createTheme } from "@mui/material";
import { ChatBox } from "./chat-v2-components/chat-box/ChatBox";
import LogViewer from "./chat-v2-components/logs/LogsViewer";
import { ChatToolBar } from "./chat-v2-components/chat-box/ChatToolBar";
import { Colors } from "consts/Colors.const";

const mainBoxStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  backgroundColor: "#f0f0f0",
  overflow: "hidden",
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#0473bb",
    },
    secondary: {
      main: Colors.secondary,
    },
    background: {
      default: "#f1f3f4",
    },
  },
  typography: {
    fontFamily: '"Google Sans", "Roboto", "Arial", sans-serif',
  },
});

export const ChatV2: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={mainBoxStyle}>
        <ChatToolBar />
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            display: "flex",
          }}
        >
          <ChatBox />
        </Box>
        <Box>
          <LogViewer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};
