import { initalConsumerState } from "models";
import { useConsumerStore } from "store/consumers/consumer.store";
import MasterConsumer from "../MasterConsumer";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { SnackbarManager } from "utils";

interface Props {
  onCreate?: () => void;
}

const CreateConsumer: React.FC<Props> = () => {
  const setConsumer = useConsumerStore((state) => state.setConsumer);
  const consumer = useConsumerStore((state) => state.consumer);
  const createConsumer = useConsumerStore((state) => state.createConsumer);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createNewConsumer = async () => {
    setIsLoading(true);
    try {
      const response = await createConsumer(consumer);
      if (response.message === "OK") {
        setConsumer(initalConsumerState);
        SnackbarManager.success("Consumer created successfully.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setConsumer(initalConsumerState);
  }, []);
  return (
    <>
      <MasterConsumer onSubmit={createNewConsumer}>
        <Button
          type='submit'
          className='mt-3'
          disabled={isLoading}
          color='primary'
        >
          {isLoading ? (
            <>
              <i className='fa fa-spinner fa-spin mr-2'></i>
              Creating...
            </>
          ) : (
            <>Create Agent</>
          )}
        </Button>
      </MasterConsumer>
    </>
  );
};

export default CreateConsumer;
