import { apiService } from "api";
import AxmosInput from "components/Form/AxmosInput";
import { markdownToHtml } from "components/Parser";
import { env } from "env";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "reactstrap";
import { SnackbarManager } from "utils";

interface Props {
  openWizard: boolean;
  setOpenWizardModal: (value: boolean) => void;
  onChange?: (value: string) => void;
}

export const PromptWizardModal: React.FC<Props> = (props) => {
  const [promptIdea, setPromptIdea] = useState<string>("");
  const [isWizardLoading, setIsWizardLoading] = useState<boolean>(false);
  const [promptResult, setPromptResult] = useState<string>("");

  const promptWizard = async (idea: string): Promise<any> => {
    setIsWizardLoading(true);
    const wizardPromptId = "promptwizard";

    const langResponse = (await apiService.post("/language", {
      consumerId: "a50d5eda-afc4-11ee-a0ba-2e3d6a5bc87e",
      modelParams: {
        model: "gemini-1.5-pro",
        temperature: 0.5,
        maxOutputTokens: 3000,
        topP: 0.8,
        topK: 40,
      },
      language: "es",
      session: Math.floor(Math.random() * 100000).toString(),
      indexName: "",
      promptId: wizardPromptId,
      format: {
        type: "json",
      },
      question: idea,
    })) as any;

    const json = langResponse.data.answer
      .replaceAll("```", "")
      .replace("json\n{", "{");

    try {
      const newPrompt = JSON.parse(json);
      setPromptResult(
        newPrompt.prompt.replaceAll("{", "{{").replaceAll("}", "}}")
      );
      SnackbarManager.success("Prompt generated successfully.");
    } catch (ex) {
      setPromptResult(json);
    } finally {
      setIsWizardLoading(false);
      props.setOpenWizardModal(false);
    }
  };

  useEffect(() => {
    if (props.onChange) props.onChange(markdownToHtml(promptResult));
  }, [promptResult]);

  return (
    <>
      <Modal
        className='modal-dialog-centered'
        isOpen={props.openWizard}
        toggle={() => props.setOpenWizardModal(true)}
      >
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            Prompt Wizard
          </h5>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={() => props.setOpenWizardModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className='modal-body'>
          <Row>
            <Col>
              <AxmosInput
                required={true}
                id='promptIdea'
                label='Prompt Idea'
                placeholder='Write a prompt idea'
                name='promptIdea'
                rows='3'
                type='textarea'
                onInput={(val) => setPromptIdea(val)}
              />
            </Col>
          </Row>
        </div>
        <div className='modal-footer'>
          <Button
            color='secondary'
            data-dismiss='modal'
            type='button'
            disabled={isWizardLoading}
            onClick={() => props.setOpenWizardModal(false)}
          >
            Close
          </Button>
          <Button
            disabled={isWizardLoading}
            color='primary'
            type='button'
            onClick={() => promptWizard(promptIdea)}
          >
            {isWizardLoading ? (
              <>
                <i className='fa fa-spinner fa-spin mr-2'></i>
                Generating Prompt
              </>
            ) : (
              "Generate Prompt"
            )}
          </Button>
        </div>
      </Modal>
    </>
  );
};
