import Dashboard from "Example/Dashboards/Dashboard";
import Admin from "layouts/Admin";
import { Navigate, RouteObject } from "react-router-dom";

import ConsumerRoutes from "./consumer.routes";
import PromptsRoutes from "./prompts.routes";
import ChatRoutes from "./chat.routes";

const AdminRoutes: RouteObject[] = [
  {
    path: "/admin",
    element: <Admin />,
    children: [
      { index: true, element: <Navigate to='/admin/home' /> },
      { path: "home", element: <></> },
      ...ConsumerRoutes,
      ...PromptsRoutes,
      ...ChatRoutes,
    ],
  },
];

export default AdminRoutes;
