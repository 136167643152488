import { apiService } from "api";
import { Response } from "models";
import { ModelParams } from "models/NewModelParams.model";
import { MasterParams, postToMaster } from "services/chat/master.service";
import { getPromptById } from "services/prompts/get-by-id";
import { newSession } from "utils";
import { create } from "zustand";
import { v4 } from "uuid";

interface Log {
  consumer: any;
  masterParams: MasterParams;
}

interface IDebuggerRows {
  date: Date | string;
  position: number;
  consumerName: string;
  message: string;
  totalTime: number;
  details: IDebuggerDetails;
  modelParams: any;
  prompt: any;
  totalTokens: number;
}

interface IDebuggerDetails {
  languageId: string;
  consumerId: string;
  promptId: string;
  modelParams: ModelParams;
  question: string;
  session: string;
  indexName: string;
  created: string;
  answer: string;
  docs: Array<{
    text: string;
    source: string;
  }>;
  integrationsData: Array<any>;
  prompt_token_count: number;
  candidates_token_count: number;
  llm_time_taken: number;
  integrations_time_taken: number;
}

interface State {
  logs: any[];
  sendMessage: (params: MasterParams) => Promise<any>;
  getLanguage: (languageId: string) => Promise<any>;
  _getPromptById: (promptId: string) => Promise<any>;
  setLog: (newLine: any, consumer: any, prompt: any) => void;
  sendMessageAndLog: (log: Log) => Promise<any>;
  consumerSelected: Response | null;
  setConsumerSelected: (consumerSelected: Response) => void;
  question: string;
  setQuestion: (question: string) => void;
  mesagges: any[];
  setMessages: (messages: any[]) => void;
  session: string;
  setSession: (session: string) => void;
  clearChat: () => void;
}

export const useChatStore = create<State>((set, get) => ({
  logs: [],
  consumerSelected: null,
  question: "",
  mesagges: [],
  session: v4(),
  setSession: (session: string) => set({ session }),
  setMessages: (messages: any[]) => set({ mesagges: messages }),
  setQuestion: (question: string) => set({ question }),
  setConsumerSelected: (consumerSelected: Response) =>
    set({ consumerSelected }),

  sendMessage: async (params: MasterParams): Promise<any> => {
    const response = await postToMaster(params);
    return response;
  },

  getLanguage: async (languageId: string): Promise<any> => {
    const response = await apiService.get<any>(`/language/${languageId}`);
    return response;
  },

  _getPromptById: async (promptId: string) => {
    const response = await getPromptById(promptId);
    return response;
  },

  setLog: (newLine: any, consumer: any, prompt: any) => {
    const { logs } = get();
    const newLog: IDebuggerRows = {
      date: new Date().toLocaleDateString("es-CL"),
      consumerName: consumer?.botHumanName,
      message: newLine?.question,
      totalTime: Math.round((newLine?.total_time_taken || 0) * 1000) / 1000,
      totalTokens: newLine?.total_token_count,
      position: logs?.length + 1,
      details: newLine,
      modelParams: newLine?.modelParams,
      prompt,
    };

    set({ logs: [...logs, newLog] });
  },

  sendMessageAndLog: async (log: Log) => {
    const { sendMessage, getLanguage, _getPromptById, setLog } = get();
    try {
      const { data: masterResponse, message } = await sendMessage(
        log.masterParams
      );
      const { data: languageResponse } = await getLanguage(
        masterResponse.languageId
      );
      const { data: promptResponse } = await _getPromptById(
        languageResponse.promptId
      );

      setLog(languageResponse, log.consumer, promptResponse);
      return { message };
    } catch (e) {
      return { message: "Error sending message, try again..." };
    }
  },
  clearChat: () => {
    const { consumerSelected } = get();
    set({
      mesagges: [],
      question: "",
      session: newSession(),
      logs: [],
    });
  },
}));
