import axios, { type Axios, type AxiosRequestConfig } from "axios";
import { env } from "../env";
import { AxiosInterceptor } from "./axios.interceptor";

const axiosInstance: Axios = axios.create({
  baseURL: env.API_BASE_URL,
});

AxiosInterceptor(axiosInstance);

async function get<T>(url: string): Promise<T> {
  const response = await axiosInstance.get<T>(url);
  return response.data;
}

async function post<T>(
  url: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<T> {
  const options: AxiosRequestConfig = config || {};
  const response = await axiosInstance.post<T>(url, data, { ...options });
  return response.data;
}

async function put<T>(url: string, data: any): Promise<T> {
  const response = await axiosInstance.put<T>(url, data);
  return response.data;
}

async function patch<T>(url: string, data: any): Promise<T> {
  const response = await axiosInstance.patch<T>(url, data);
  return response.data;
}

async function _delete<T>(url: string, data?: any): Promise<T> {
  const response = await axiosInstance.delete<T>(url, data);
  return response.data;
}

export const apiService = {
  get,
  put,
  post,
  patch,
  delete: _delete,
};
