import { useRef, useState } from "react";
import { Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import {
  AttachFile,
  Image,
  MicRounded,
  PictureAsPdf,
  VideoCallRounded,
} from "@mui/icons-material";
import {
  imageFileTypes,
  audioFileTypes,
  videoFileTypes,
  documentsFileTypes,
} from "consts/FileTypes.const";
import { useFileUploader } from "../hooks/useFileUploader";

const FileTypesMenuItem: React.FC<any> = ({
  handleUploadFile,
  fileTypes,
  icon,
  divider = true,
}) => {
  return (
    <>
      <MenuItem
        sx={{
          ".MuiMenuItem-root": {
            borderRadius: 6,
            minWidth: 180,
          },
        }}
        onClick={() => handleUploadFile(fileTypes)}
      >
        {icon}
        <Typography className='ml-2' variant='subtitle2' color='text.secondary'>
          ({fileTypes.join(", ")})
        </Typography>
      </MenuItem>
      {divider && <Divider />}
    </>
  );
};

export const ChatFileUploader: React.FC = () => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { onDrop } = useFileUploader([
    ...imageFileTypes,
    ...audioFileTypes,
    ...videoFileTypes,
    ...documentsFileTypes,
  ]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUploadFile = (fileTypes: string[]) => () => {
    hiddenFileInput.current?.setAttribute("accept", fileTypes.join(","));
    hiddenFileInput.current?.click();
  };

  return (
    <>
      <IconButton
        id='basic-button'
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <AttachFile />
      </IconButton>
      <input
        type='file'
        hidden
        multiple
        onChange={onDrop}
        ref={hiddenFileInput}
      ></input>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <FileTypesMenuItem
          handleUploadFile={handleUploadFile(documentsFileTypes)}
          icon={<PictureAsPdf />}
          fileTypes={documentsFileTypes}
        ></FileTypesMenuItem>
        <FileTypesMenuItem
          handleUploadFile={handleUploadFile(imageFileTypes)}
          icon={<Image />}
          fileTypes={imageFileTypes}
        ></FileTypesMenuItem>
        <FileTypesMenuItem
          handleUploadFile={handleUploadFile(audioFileTypes)}
          icon={<MicRounded />}
          fileTypes={audioFileTypes}
        ></FileTypesMenuItem>
        <FileTypesMenuItem
          handleUploadFile={handleUploadFile(videoFileTypes)}
          icon={<VideoCallRounded />}
          fileTypes={videoFileTypes}
          divider={false}
        ></FileTypesMenuItem>
      </Menu>
    </>
  );
};
