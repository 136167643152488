import { ModelParams } from "models/NewModelParams.model";

export enum SafetySettingsEnum {
  BlockFew = "Block Few",
  BlockSome = "Block Some",
  BlockMost = "Block Most",
}

export const defaultModelParams: ModelParams = {
  maxOutputTokens: 1024,
  temperature: 0.4,
  topP: 0.2,
  topK: 40,
  response_mime_type: "text",
  safetySettings: {
    hateSpeech: SafetySettingsEnum.BlockSome,
    dangerousContent: SafetySettingsEnum.BlockSome,
    sexuallyExplicitContent: SafetySettingsEnum.BlockSome,
    harrasmentContent: SafetySettingsEnum.BlockSome,
  },
};

export const deterministicModelParams: ModelParams = {
  maxOutputTokens: 1024,
  temperature: 0,
  topP: 0.2,
  topK: 40,
  response_mime_type: "text",
  safetySettings: {
    hateSpeech: SafetySettingsEnum.BlockSome,
    dangerousContent: SafetySettingsEnum.BlockSome,
    sexuallyExplicitContent: SafetySettingsEnum.BlockSome,
    harrasmentContent: SafetySettingsEnum.BlockSome,
  },
};

export const creativeModelParms: ModelParams = {
  maxOutputTokens: 1024,
  temperature: 0.5,
  topP: 0.2,
  topK: 40,
  response_mime_type: "text",
  safetySettings: {
    hateSpeech: SafetySettingsEnum.BlockSome,
    dangerousContent: SafetySettingsEnum.BlockSome,
    sexuallyExplicitContent: SafetySettingsEnum.BlockSome,
    harrasmentContent: SafetySettingsEnum.BlockSome,
  },
};

export const veryCreativeModelParams: ModelParams = {
  maxOutputTokens: 1024,
  temperature: 1,
  topP: 0.2,
  topK: 40,
  response_mime_type: "text",
  safetySettings: {
    hateSpeech: SafetySettingsEnum.BlockSome,
    dangerousContent: SafetySettingsEnum.BlockSome,
    sexuallyExplicitContent: SafetySettingsEnum.BlockSome,
    harrasmentContent: SafetySettingsEnum.BlockSome,
  },
};

type preDefinedModelParams = {
  label: string;
  value: ModelParams | null;
};

export const preDefinedModelParamsList: Array<preDefinedModelParams> = [
  {
    label: "Choose one...",
    value: null,
  },
  {
    label: "Deterministic 🧐",
    value: deterministicModelParams,
  },
  {
    label: "Creative 💡",
    value: creativeModelParms,
  },
  {
    label: "Crazy 🤪",
    value: veryCreativeModelParams,
  },
];
