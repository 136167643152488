import AxmosInput from "components/Form/AxmosInput";
import { env } from "env";
import { Col, Row } from "reactstrap";
import { getAllModels } from "services/models";
import { useConsumerStore } from "store/consumers/consumer.store";

const ModelParams: React.FC = () => {
  const models = getAllModels();

  const [consumer, setConsumer] = useConsumerStore((state) => [
    state.consumer,
    state.setConsumer,
  ]);

  return (
    <Row>
      <Col>
        <AxmosInput
          value={consumer.modelParams?.model}
          id='model'
          label='Model'
          name='model'
          type='select'
          onChange={(value) =>
            setConsumer({
              ...consumer,
              modelParams: { ...consumer.modelParams!, model: value },
            })
          }
        >
          {models.map((model) => (
            <option key={model.name} value={model.value}>
              {model.name}
            </option>
          ))}
        </AxmosInput>
      </Col>
      {env.SHOW_MODEL_PARAMS && (
        <>
          <Col>
            <AxmosInput
              value={consumer.modelParams?.maxOutputTokens}
              id='maxoutputtokens'
              label='Max Output Tokens'
              placeholder='Type your max output tokens'
              onChange={(evt) =>
                setConsumer({
                  ...consumer,
                  modelParams: {
                    ...consumer.modelParams!,
                    maxOutputTokens: +evt,
                  },
                })
              }
              name='maxoutputtokens'
              type='number'
              required={true}
            />
          </Col>

          <Col>
            <AxmosInput
              value={consumer.modelParams?.topK}
              id='topK'
              label='Top K'
              placeholder='Type your top K value'
              onChange={(evt) =>
                setConsumer({
                  ...consumer,
                  modelParams: { ...consumer.modelParams!, topK: +evt },
                })
              }
              min={0}
              name='topK'
              type='number'
              required={true}
            />
          </Col>

          <Col>
            <AxmosInput
              id='topP'
              label='Top P'
              onChange={(evt) =>
                setConsumer({
                  ...consumer,
                  modelParams: { ...consumer.modelParams!, topP: +evt },
                })
              }
              min={0}
              name='topK'
              type='range'
              required={true}
              value={consumer.modelParams?.topP}
              max={1}
              step={0.1}
            />
          </Col>

          <Col>
            <AxmosInput
              label='Temperature'
              placeholder='Type your temperature value'
              onChange={(evt: any) =>
                setConsumer({
                  ...consumer,
                  modelParams: { ...consumer.modelParams!, temperature: +evt },
                })
              }
              name='temperature'
              id='temperature'
              min={0}
              max={1}
              step={0.1}
              type='range'
              value={consumer.modelParams!.temperature}
              required={true}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default ModelParams;
