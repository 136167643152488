import { Response } from "models";
import moment from "moment";

export function ConsumerAdapter(consumers: Response[]): Response[] {
  return consumers
    .sort(
      (a: Response, b: Response) =>
        new Date(b.created!).getTime() - new Date(a.created!).getTime()
    )
    .map((consumer: Response) => {
      return {
        ...consumer,
        updated: consumer.updated
          ? moment(consumer.updated).format("DD/MM/YYYY")
          : "-",
        created: consumer.created
          ? moment(consumer.created).format("DD/MM/YYYY")
          : "-",
      };
    });
}
