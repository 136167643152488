import React from "react";
//import LogViewer from "./components/LogViewer";
import LogsTable from "./LogsTable";
import ResizablePanel from "./ResizablePanel";
import { useChatStore } from "store/chat/chat.store";

const LogViewer: React.FC = () => {
  const [logs] = useChatStore((state) => [state.logs]);

  return (
    <>
      <ResizablePanel logsCount={logs.length}>
        <LogsTable logs={logs} />
      </ResizablePanel>
    </>
  );
};

export default LogViewer;
