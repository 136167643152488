const PageNotFound = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="jumbotron">
                        <h1 className="display-4">404 - Page Not Found</h1>
                        <p className="lead">The page you are looking for does not exist.</p>
                        <hr className="my-4" />
                        <p>Please check the URL and try again.</p>
                        <a className="btn btn-primary btn-lg" href="/" role="button">Go Home</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageNotFound