import { apiService } from "api";
import { AxiosResponse } from "axios";
import { PromptUpdateAndCreate } from "models";

export const updatePrompt = async (
  promptId: string,
  prompt: PromptUpdateAndCreate
) => {
  return await apiService.put<AxiosResponse>(`/prompt/${promptId}`, prompt);
};
