import React, { ComponentProps } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { InputType } from "reactstrap/es/Input";
import { AxmosTooltip } from "./AxmosTooltip";

interface Props extends ComponentProps<"input"> {
  label?: string;
  placeholder?: string;
  onInput?: (value: any) => void;
  onChange?: (value: any) => void;
  required?: boolean;
  type: InputType;
  id?: string;
  name?: string;
  children?: React.ReactNode;
  min?: any;
  max?: any;
  step?: number;
  value?: any;
  rows?: string;
  className?: string;
  style?: React.CSSProperties;
  tooltipMessage?: string;
  disabled?: boolean;
}

const AxmosInput: React.FC<Props> = ({
  label,
  placeholder,
  onInput,
  required,
  id,
  name,
  type,
  children,
  onChange,
  min,
  max,
  step,
  rows,
  value,
  className,
  style,
  tooltipMessage,
  disabled,
}) => {
  return (
    <>
      <FormGroup>
        <div
          className={`${className} d-flex justify-content-between align-items-center`}
        >
          <Label className='text-sm text-truncate' for={id}>
            {label}{" "}
            {tooltipMessage && (
              <AxmosTooltip
                style={{ color: "#ffa300a3" }}
                message={tooltipMessage}
              />
            )}
          </Label>

          {type === "range" && (
            <span className='range-slider-value'>{value}</span>
          )}
        </div>

        <Input
          style={style}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (onInput) {
              onInput(e.target.value);
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          placeholder={placeholder}
          name={name}
          id={id}
          type={type}
          required={required}
          min={min}
          max={max}
          step={step}
          value={value}
          rows={rows}
          className={className}
          disabled={disabled}
        >
          {children}
        </Input>
      </FormGroup>
    </>
  );
};

export default AxmosInput;
