import { Tooltip } from "@mui/material";

interface Props {
  message: string;
  className?: string;
  style?: React.CSSProperties;
}

export const AxmosTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  className,
  style,
}) => {
  return (
    <>
      <Tooltip title={message} placement='top'>
        <i
          className={`${className} fa fa-info-circle cursor-pointer`}
          style={style}
        ></i>
      </Tooltip>
    </>
  );
};
