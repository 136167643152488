import {
  MicRounded,
  PlayArrowOutlined,
  StopRounded,
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useRef, useState } from "react";

interface Props {
  onStop?: (data: any) => void;
}

export const SaveAudio: React.FC<Props> = ({ onStop }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audioDuration, setAudioDuration] = useState<number | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  const handleStartRecording = async () => {
    try {
      setIsProcessing(true);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };
      mediaRecorderRef.current.onstop = () => {
        setIsProcessing(true);

        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const audioUrl = URL.createObjectURL(audioBlob);

        setAudioUrl(audioUrl);
        audioChunksRef.current = [];
        calculateAudioDuration(audioBlob);
        setIsProcessing(false);
        if (onStop) onStop({ audioUrl, audioBlob });
      };
      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };
  const handleStopRecording = async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const calculateAudioDuration = (audioBlob: Blob) => {
    const audio = new Audio();
    audio.src = URL.createObjectURL(audioBlob);
    audio.addEventListener("loadedmetadata", () => {
      if (audio.duration === Infinity) {
        audio.currentTime = 1e101;
        audio.ontimeupdate = function () {
          audio.ontimeupdate = null;
          setAudioDuration(audio.duration);
          audio.currentTime = 0;
        };
      } else {
        setAudioDuration(audio.duration);
      }
    });
  };
  return (
    <>
      {!isRecording ? (
        <IconButton color='primary' onClick={handleStartRecording}>
          <MicRounded />
        </IconButton>
      ) : (
        <IconButton color='error' onClick={handleStopRecording}>
          <StopRounded />
        </IconButton>
      )}
    </>
  );
};
