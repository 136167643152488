import { TypeWithKey } from "models";

export const getAllModels = (): Array<TypeWithKey<string | undefined>> => {
  return [
    { name: "Select your model here.", value: "" },
    { name: "Gemini 1.5 Pro 001", value: "gemini-1.5-pro-001" },
    { name: "Gemini 1.5 Flash 001", value: "gemini-1.5-flash-001" },
    { name: "Gemini 1.5 Pro 002", value: "gemini-1.5-pro-002" },
    { name: "Gemini 1.5 Flash 002", value: "gemini-1.5-flash-002" },
    { name: "Gemini 1.5 Pro latest", value: "gemini-1.5-pro-latest" },
    { name: "Gemini 1.5 Flash latest", value: "gemini-1.5-flash-latest" },
    {
      name: "Gemini 1.5 Flash-8B Experimental",
      value: "gemini-1.5-flash-8b-exp-0924",
    },
    {
      name: "Gemini 1.5 Flash Experimental",
      value: "gemini-1.5-flash-exp-0827",
    },
    { name: "Gemini 1.5 Pro Experimental", value: "gemini-1.5-pro-exp-0827" },
  ];
};
