const sideBarList = [
  /*{
    collapse: false,
    name: "Dashboards",
    icon: "ni ni-chart-pie-35",
    state: "dashboardsCollapse",
    path: "/dashboard",
    layout: "/admin",
    miniName: "Dash",
  },*/
  {
    collapse: true,
    name: "Prompts",
    icon: "fa fa-terminal",
    state: "promptsCollapse",
    path: "/prompts",
    miniName: "P",
    views: [
      {
        path: "/prompts/create",
        name: "Create Prompt",
        layout: "/admin",
        miniName: "EC",
      },
      {
        path: "/prompts/view-all",
        name: "View Prompts",
        layout: "/admin",
        miniName: "VC",
      },
    ],
  },
  {
    collapse: true,
    name: "Agents",
    icon: "fa fa-walkie-talkie",
    state: "viewsCollapse",
    miniName: "C",
    views: [
      {
        path: "/agents/create",
        name: "Create Agents",
        layout: "/admin",
        miniName: "EC",
      },
      {
        path: "/agents/view-all",
        name: "View Agents",
        layout: "/admin",
        miniName: "VC",
      },
    ],
  },
  {
    collapse: true,
    name: "Chat",
    icon: "fa fa-comments",
    state: "chatCollapse",
    miniName: "CH",
    views: [
      {
        path: "/chats/chat-v1",
        name: "Chat v1",
        layout: "/admin",
        miniName: "CH",
      },

      {
        path: "/chats/chat-v2",
        name: "Chat v2",
        layout: "/admin",
        miniName: "CH2",
      },
    ],
  },
];

export default sideBarList;
