import { useEffect, useState } from "react";
import { useAttachmentStore } from "store/chat/attachment.store";
import { SnackbarManager } from "utils";

export const useFileUploader = (supportedFiles?: string[]) => {
  const [files, setFiles] = useAttachmentStore((state) => [
    state.files,
    state.setFiles,
  ]);

  const [isDragging, setIsDragging] = useState(false);

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (
    event: React.DragEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setIsDragging(false);

    const newFiles = Object.hasOwnProperty.call(event, "dataTransfer")
      ? Array.from((event as React.DragEvent).dataTransfer.files || [])
      : Array.from(
          (event as React.ChangeEvent<HTMLInputElement>).target.files || []
        );

    const allowedFiles = newFiles.filter((file) =>
      supportedFiles?.some((fileType) => file.name.endsWith(fileType))
    );

    if (allowedFiles.length !== newFiles.length) {
      SnackbarManager.warning(
        "Some files are not supported. Please upload valid files."
      );
      return;
    }

    setFiles([...files, ...newFiles]);
  };

  return {
    onDrop,
    isDragging,
    onDragLeave,
    onDragOver,
    onDragEnter,
    files,
    setFiles,
  };
};
