import { ConsumerResquestParams } from "models";
import { SnackbarManager } from "utils";

export const ensureUpdateConsumer = (
  consumerId: string,
  consumer: ConsumerResquestParams
) => {
  if (!consumerId) throw SnackbarManager.warning("Consumer id not provided!");
  if (!consumer.botHumanName)
    throw SnackbarManager.warning("Bot name is required!");
  if (!consumer.welcomeMessage)
    throw SnackbarManager.warning("Welcome message not provided!");
  if (consumer.prompts.length === 0)
    throw SnackbarManager.warning("No prompts provided!");
  if (!consumer.defaultPromptId)
    throw SnackbarManager.warning("Select a default prompt id!");
};
