import MDEditor from "components/MDEditor";
import { usePromptsStore } from "store/prompts/prompts.store";
import "./prompt.css";

interface Props {
  value: string;
  setValue: (value: string) => void;
}

export const PromptEditor: React.FC<Props> = (props) => {
  const [prompt, setPrompt] = usePromptsStore((state) => [
    state.prompt,
    state.setPrompt,
  ]);

  const updatePromptValue = (value?: string) => {
    setPrompt({
      ...prompt,
      content: { ...prompt.content, prompt: value ? value : "" },
    });
  };
  return (
    <>
      <MDEditor
        value={props.value}
        unMount={() => {
          updatePromptValue("");
          props.setValue("");
        }}
        placeholder='Type your prompt here'
        onChange={({ html }) => {
          updatePromptValue(html);
          props.setValue(html);
        }}
        className='promptEditor'
      />
    </>
  );
};
