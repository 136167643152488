import { Typography } from "@mui/material";
import AxmosInput from "components/Form/AxmosInput";
import { type SafetySettings as SafetySettingsType } from "models/NewModelParams.model";
import { SafetySettingsEnum } from "models/prompts";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const SAFETY_SETTINGS_OPTIONS = [
  SafetySettingsEnum.BlockFew,
  SafetySettingsEnum.BlockSome,
  SafetySettingsEnum.BlockMost,
];

type Props = {
  onChange: (value: SafetySettingsType) => void;
  defaultValues?: SafetySettingsType;
  title?: string;
};

export const SafetySettings: React.FC<Props> = ({
  onChange,
  defaultValues,
  title = "Safety Settings",
}) => {
  const [hateSpeech, setHateSpeech] = useState<string>(
    defaultValues?.hateSpeech || SafetySettingsEnum.BlockSome
  );
  const [dangerousContent, setDangerousContent] = useState<string>(
    defaultValues?.dangerousContent || SafetySettingsEnum.BlockSome
  );
  const [sexuallyExplicit, setSexuallyExplicit] = useState<string>(
    defaultValues?.sexuallyExplicitContent || SafetySettingsEnum.BlockSome
  );
  const [harassmentContent, setHarassmentContent] = useState<string>(
    defaultValues?.harrasmentContent || SafetySettingsEnum.BlockSome
  );

  useEffect(() => {
    onChange({
      hateSpeech,
      dangerousContent,
      sexuallyExplicitContent: sexuallyExplicit,
      harrasmentContent: harassmentContent,
    });
  }, [hateSpeech, dangerousContent, sexuallyExplicit, harassmentContent]);

  return (
    <>
      <Typography className='mb-3'>{title}</Typography>
      <Row>
        <Col md='6'>
          <AxmosInput
            label='Hate speech'
            type='select'
            tooltipMessage='Negative or harmful comments targeting identity and/or protected attributes'
            onChange={(value) => setHateSpeech(value)}
            value={hateSpeech}
            className='cursor-pointer'
          >
            {SAFETY_SETTINGS_OPTIONS.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </AxmosInput>
        </Col>
        <Col md='6'>
          <AxmosInput
            label='Dangerous content'
            type='select'
            tooltipMessage='Promotes or enables access to harmful goods, services, and activities'
            onChange={(value) => setDangerousContent(value)}
            value={dangerousContent}
            className='cursor-pointer'
          >
            {SAFETY_SETTINGS_OPTIONS.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </AxmosInput>
        </Col>
      </Row>

      <Row>
        <Col md='6'>
          <AxmosInput
            label='Sexually explicit content'
            type='select'
            tooltipMessage='Contains references to sexual acts or other lewd content'
            onChange={(value) => setSexuallyExplicit(value)}
            value={sexuallyExplicit}
            className='cursor-pointer'
          >
            {SAFETY_SETTINGS_OPTIONS.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </AxmosInput>
        </Col>

        <Col md='6'>
          <AxmosInput
            label='Harassment content'
            type='select'
            tooltipMessage='Malicious, intimidating, bullying, or abusive comments targeting another individual'
            onChange={(value) => setHarassmentContent(value)}
            value={harassmentContent}
            className='cursor-pointer'
          >
            {SAFETY_SETTINGS_OPTIONS.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </AxmosInput>
        </Col>
      </Row>
    </>
  );
};
