import { useEffect, useState } from "react";
import { usePromptsStore } from "store/prompts/prompts.store";
import DefaultContainer from "components/DefaultContainer";
import { SnackbarManager, SnackbarUtilitiesConfigurator } from "utils";
import DataTable, {
  TableColumn,
  TableStyles,
} from "react-data-table-component";
import { Prompt } from "models";
import { Button, Fade } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { PromptModalDetail } from "./PromptModalDetail";
import Swal from "sweetalert2";
import SimpleHeader from "components/Headers/SimpleHeader";
import AxmosInput from "components/Form/AxmosInput";

const PromptsViewAll: React.FC = () => {
  const navigation = useNavigate();

  const [loading, setLoading] = useState(true);

  const [prompt, setPrompt] = useState<Prompt | null>(null);

  const [openModal, setOpenModal] = useState(false);

  const [getAllPrompts, deletePrompt, setFilters, filteredPrompts] =
    usePromptsStore((state) => [
      state.getAll,
      state.deletePrompt,
      state.setFilters,
      state.filteredPrompts,
    ]);

  useEffect(() => {
    setLoading(true);
    try {
      getAllPrompts();
    } catch (error: any) {
      SnackbarManager.error(error);
    } finally {
      setLoading(false);
    }
  }, [getAllPrompts]);

  const customStyles: TableStyles = {
    headCells: {
      style: {
        backgroundColor: "#f6f9fc",
        color: "#8898aa",
        fontWeight: "semibold",
        textTransform: "uppercase",
        fontSize: "11px",
      },
    },
  };

  const showModal = (prompt: Prompt) => () => {
    setPrompt(prompt);
    setOpenModal(true);
  };

  const handleDeletePrompt = (promptId: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = (await deletePrompt(promptId)) as any;
        if (data.result) {
          Swal.fire("Deleted!", "Your prompt has been deleted.", "success");
        }
      }
    });
  };

  const columns: TableColumn<Prompt>[] = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Model",
      selector: (row) => row.modelParams?.model ?? "-",
      sortable: true,
    },

    {
      name: "Act as",
      cell: (row) => (
        <span title={row.content.actAs} className='text-truncate text-break'>
          {row.content.actAs || "-"}
        </span>
      ),
      maxWidth: "220px",
    },
    {
      name: "Type",
      cell: (row) => row.content.type || "-",
    },
    {
      name: "Created at",
      cell: (row) => (
        <span className='text-uppercase'>{row.created || "-"}</span>
      ),
    },
    {
      name: "Updated at",
      cell: (row) => (
        <span className='text-uppercase'>{row.updated || "-"}</span>
      ),
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className='d-flex align-items-center'>
          <Button
            onClick={showModal(row)}
            color='info'
            size='sm'
            title='View prompt details'
          >
            <i className='fa fa-eye'></i>
          </Button>
          <Button
            title='Edit prompt'
            onClick={() => navigation(`/admin/prompts/edit/${row.promptId}`)}
            color='primary'
            size='sm'
          >
            <i className='fa fa-edit'></i>
          </Button>
          <Button
            title='Delete prompt'
            onClick={() => handleDeletePrompt(row.promptId)}
            color='danger'
            size='sm'
          >
            <i className='fa fa-trash'></i>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <SimpleHeader name='List Prompts' parentName='Prompts' />
      <section className='p-3'>
        <Fade>
          <DefaultContainer title='Prompts'>
            <PromptModalDetail
              isOpen={openModal}
              setIsOpen={setOpenModal}
              prompt={prompt}
            ></PromptModalDetail>
            <SnackbarUtilitiesConfigurator />

            <div className='d-flex justify-content-start'>
              <AxmosInput
                label='Search'
                tooltipMessage='Search by name, type or model'
                style={{ width: "300px" }}
                placeholder='Type anything to search'
                name='filter'
                onInput={(value) => setFilters(value)}
                type='text'
              ></AxmosInput>
            </div>

            <DataTable
              pointerOnHover
              striped
              onRowClicked={(row) =>
                navigation(`/admin/prompts/edit/${row.promptId}`)
              }
              highlightOnHover
              columns={columns}
              pagination
              data={filteredPrompts}
              progressPending={loading}
              responsive
              customStyles={customStyles}
            ></DataTable>
          </DefaultContainer>
        </Fade>
      </section>
    </>
  );
};

export default PromptsViewAll;
